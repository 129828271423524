import * as React from 'react'
import {computed} from 'mobx'
import {observer} from 'mobx-react'
import {Table} from 'react-bootstrap'

import {RealPlaylist} from 'api/realsources'
import {RealPlaylistStore, RealUserStore} from 'modules/admin/adminstores'
import IndexRow from './IndexRow'
import PlaylistModal from './Modal'
import Refresher from '../Refresher'

interface State {
  selectedPlaylist: RealPlaylist
}

@observer
class PlaylistsIndexTab extends React.Component<{}, State> {
  constructor(props) {
    super(props)

    this.state = {
      selectedPlaylist: null
    }
  }

  onClickPlaylist = (playlist: RealPlaylist) => {
    this.setState({selectedPlaylist: playlist})
  }

  onCloseModal = () => {
    this.setState({selectedPlaylist: null})
  }

  @computed
  get rows() {
    return RealPlaylistStore.values.map(
      (playlist: RealPlaylist, idx: number) => (
        <IndexRow
          playlist={playlist}
          key={playlist.id}
          onClick={clickedPlaylist => this.onClickPlaylist(clickedPlaylist)}
        />
      )
    )
  }

  render() {
    return (
      <div id="playlist-indextab" className="admin-inner-tab">
        <span className="description">
          Update the admin store of playlists.
        </span>
        <Refresher stores={[RealPlaylistStore, RealUserStore]} />
        <div className="tablescroller" data-rows={this.rows.length}>
          <Table bordered condensed hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Orient.</th>
              </tr>
            </thead>
            <tbody>{this.rows}</tbody>
          </Table>
        </div>
        <PlaylistModal
          playlist={this.state.selectedPlaylist}
          onHide={this.onCloseModal}
        />
      </div>
    )
  }
}

export default PlaylistsIndexTab
