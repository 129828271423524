import * as React from 'react'
import {Tab, Tabs} from 'react-bootstrap'

import BaseAdminTab from './BaseAdminTab'
import IndexTab from './users/IndexTab'
import UpdateTab from './users/UpdateTab'
import CreateTab from './users/CreateTab'
import DeleteTab from './users/DeleteTab'

class UsersTab extends BaseAdminTab {
  getContents = () => {
    return (
      <Tabs id="admin-panel-users-tabs">
        <Tab eventKey={1} title="Info">
          <IndexTab />
        </Tab>
        <Tab eventKey={2} title="Update">
          <UpdateTab />
        </Tab>
        <Tab eventKey={3} title="Create">
          <CreateTab />
        </Tab>
        <Tab eventKey={4} title="Delete">
          <DeleteTab />
        </Tab>
      </Tabs>
    )
  }
}

export default UsersTab
