import * as React from 'react'
import {observer} from 'mobx-react'
import {
  Button,
  ButtonGroup,
  Col,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Modal
} from 'react-bootstrap'

import {DOWS_2, seconds_tohhmmss, TimeInput} from 'time'
import Showtime from 'stores/Showtime'

interface Props {
  show: boolean
  closeModal: () => void
  editingShowtime: Showtime
  deleteShowtime: () => void
}

@observer
class ShowtimeEditModal extends React.Component<Props> {
  radioButtonGenerator = () => {
    return [0, 1, 2, 3, 4, 5, 6].map(dow => {
      const strDow = String(dow)
      const id = `editshowtime-daystart-${dow}`
      return (
        <Button
          componentClass="label"
          key={dow}
          htmlFor={id}
          active={
            this.props.editingShowtime &&
            this.props.editingShowtime.day_start === dow
          }
        >
          <input
            type="radio"
            name="editshowtime-daystart"
            id={id}
            value={dow}
            checked={
              (this.props.editingShowtime &&
                this.props.editingShowtime.day_start === dow) ||
              false
            }
            onChange={() => {
              this.props.editingShowtime.day_start = dow
            }}
          />{' '}
          {DOWS_2[dow]}
        </Button>
      )
    })
  }

  onSubmit = event => {
    event.preventDefault()

    this.props.closeModal()
  }

  onTimeInputChange = (seconds: number) => {
    if (seconds !== null) {
      this.props.editingShowtime.time_start = seconds_tohhmmss(seconds)
    }
  }

  onDurationChange = event => {
    let value = event.target.value

    if (!value) {
      return null
    }

    value = Math.floor(Number(value))

    if (0 < value && value <= 168) {
      // TODO: make this be a store function call
      this.props.editingShowtime.duration = value
    }
  }

  render() {
    return (
      <Modal
        className="showtime-edit-modal"
        show={this.props.show}
        onHide={this.props.closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Show Time</Modal.Title>
        </Modal.Header>
        <Form horizontal onSubmit={this.onSubmit}>
          <Modal.Body>
            <FormGroup>
              <Col componentClass={ControlLabel} sm={3}>
                Starting Day
              </Col>
              <Col sm={7}>
                <ButtonGroup data-toggle="buttons">
                  {this.radioButtonGenerator()}
                </ButtonGroup>
              </Col>
            </FormGroup>

            <FormGroup>
              <Col componentClass={ControlLabel} sm={3}>
                Starting Time
              </Col>
              <Col sm={7}>
                <TimeInput
                  className="form-control sem-time-start"
                  defaultValue={
                    this.props.editingShowtime &&
                    this.props.editingShowtime.time_start
                  }
                  onChange={this.onTimeInputChange}
                />
              </Col>
            </FormGroup>

            <FormGroup>
              <Col componentClass={ControlLabel} sm={3}>
                Duration (hours)
              </Col>
              <Col sm={7}>
                <FormControl
                  className="sem-duration"
                  type="number"
                  min={1}
                  max={168}
                  onChange={this.onDurationChange}
                  defaultValue={
                    this.props.editingShowtime &&
                    String(this.props.editingShowtime.duration)
                  }
                />
              </Col>
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.props.deleteShowtime} bsStyle="danger">
              DELETE
            </Button>
            <Button type="submit" bsStyle="primary">
              Done
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    )
  }
}

export default ShowtimeEditModal
