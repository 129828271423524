import * as React from 'react'
import {Button, OverlayTrigger, Popover} from 'react-bootstrap'

import AssetShowtime from 'stores/AssetShowtime'
import AssetScheduler from './AssetScheduler'

const Schedule = require('images/elements/schedule-icon.svg')

interface Props {
  id: string
  showtime: AssetShowtime
}

const ScheduleButton: React.StatelessComponent<Props> = props => {
  const popover = (
    <Popover bsSize="lg" className="asset-scheduler-container" id={props.id}>
      <AssetScheduler showtime={props.showtime} />
    </Popover>
  )

  return (
    <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
      <Button className="schedule-btn">
        <Schedule />Schedule
      </Button>
    </OverlayTrigger>
  )
}

export default ScheduleButton
