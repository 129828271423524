import * as React from 'react'
import {AxiosError, AxiosResponse} from 'axios'
import {inject, observer} from 'mobx-react'
import Async from 'react-select/lib/Async'

import {PermissionLevel} from 'globals'
import {PivotedUser, RealPlaylist, RealScreen, RealUser} from 'api/realsources'
import {AlertStore} from 'stores/alertStore'
import {RealStore, RealUserStore} from './adminstores'

const RESULT_LIMIT = 5

type ScreenOrPlaylist = RealScreen | RealPlaylist

interface Props<T extends ScreenOrPlaylist> {
  id?: string
  disabled?: boolean
  title?: string
  name?: string
  resultLimit?: number
  className?: string
  target: T
  store: RealStore<T>
  permission: 'editor' | 'viewer'
  onChange: (newSelection: PivotedUser[]) => void

  // TODO: remove. Don't make a custom backdoor to a backdoor solution
  // It's only really used inside admin/tabs/screens/form.tsx, used as an input ref
  rref: (ref: RealShareSelect<T>) => void // like inputRef, but for the react component

  alertStore?: AlertStore
}

interface State<T extends ScreenOrPlaylist> {
  actual: PivotedUser[]
  selected: PivotedUser[]
}

@inject('alertStore')
@observer
class RealShareSelect<T extends ScreenOrPlaylist> extends React.Component<
  Props<T>,
  State<T>
> {
  static defaultProps = {
    disabled: false,
    title: null,
    name: null,
    resultLimit: RESULT_LIMIT
  }

  otherProps: {
    id?: string
    disabled?: boolean
    title?: string
    name?: string
  }

  constructor(props: Props<T>) {
    super(props)

    const {resultLimit, target, store, permission, className, ...other} = props

    this.otherProps = other

    this.state = {
      actual: [],
      selected: []
    }

    if (this.props.rref) {
      this.props.rref(this)
    }

    this.updateSelected(props.target, props.permission)
  }

  componentWillReceiveProps(props: Props<T>) {
    const {
      resultLimit,
      target,
      store,
      permission,
      className,
      rref,
      ...other
    } = props
    this.otherProps = other

    if (
      target &&
      target.id &&
      (!this.props.target ||
        !this.props.target.id ||
        this.props.target.id !== target.id)
    ) {
      this.updateSelected(target, permission)
    }

    if (rref) {
      rref(this)
    }
  }

  getAllUsers = (target: T = this.props.target) => {
    if (!target || isNaN(target.id)) {
      return new Promise((res, rej) => res([]))
    }

    return this.props.store
      .showRelated(target, 'users')
      .catch((err: AxiosError) => {
        this.props.alertStore.addAlert(
          err,
          'danger',
          'RealShareSelect.getUser() failed:'
        )
        return null
      })
  }

  filterUsersByPermission = (users: PivotedUser[], perm: PermissionLevel) => {
    if (!users || !users.length) {
      return []
    }

    return users.filter(
      (user: PivotedUser) => user.pivot && user.pivot.permission === perm
    )
  }

  getPermUsers = (
    target: T = this.props.target,
    perm: PermissionLevel = this.props.permission
  ) => {
    return this.getAllUsers(target).then((users: PivotedUser[]) =>
      this.filterUsersByPermission(users, perm)
    )
  }

  /* updateSelected(): get the current selection from the backend. */
  updateSelected = (target: T, perm: PermissionLevel) => {
    if (target && !isNaN(target.id)) {
      this.getPermUsers(target, perm).then((users: PivotedUser[]) => {
        this.setState(
          {
            actual: users,
            selected: users
          },
          () => this.onChange(users)
        )
      })
    } else if (target) {
      this.setState(
        {
          actual: []
        },
        () => this.onChange([])
      )
    }
  }

  loadOptions = (input: string) => {
    if (!input) {
      return new Promise((res, rej) => res(null))
    }

    return RealUserStore.search(input)
      .then((response: AxiosResponse) =>
        response.data.slice(0, this.props.resultLimit)
      )
      .then((resourceArray: RealUser[]) => resourceArray)
  }

  onChange = (newValue: PivotedUser[]) => {
    this.setState({selected: newValue})

    const clone: any = newValue.slice()
    clone.valid = this.sameUsrIDs(newValue, this.state.actual)
      ? null
      : 'success'

    this.props.onChange(clone)
  }

  sameUsrIDs = (actual: PivotedUser[], selected: PivotedUser[]) => {
    if (actual.length !== selected.length) {
      return false
    }

    const sel = new Set(selected.map(u => u.id))

    return actual.every(usr => sel.has(usr.id))
  }

  reset = (fromBackend = false) => {
    if (fromBackend) {
      this.updateSelected(this.props.target, this.props.permission)
    } else if (this.state.actual !== this.state.selected) {
      this.setState({selected: this.state.actual}, () =>
        this.onChange(this.state.actual)
      )
    }
  }

  getDiff = () => {
    const removed = this.state.actual.map(usr => usr.id)
    const added = []

    this.state.selected.forEach(usr => {
      const idx = removed.indexOf(usr.id)
      if (idx === -1) added.push(usr.id)
      else removed.splice(idx, 1)
    })

    return {added, removed}
  }

  render() {
    return (
      <Async
        {...this.otherProps}
        className={`RealShareSelect ${this.props.className || ''}`}
        value={this.state.selected}
        getOptionLabel={opt => opt.name}
        getOptionValue={opt => opt.id}
        isMulti={true}
        loadOptions={input => this.loadOptions(input)}
        onChange={this.onChange}
        defaultOptions={false}
        cacheOptions={true}
      />
    )
  }
}

export default RealShareSelect
