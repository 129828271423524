import * as React from 'react'
import {Alert} from 'react-bootstrap'

interface Props {
  paramName: string
  title: string
  location: any
  type?: 'success' | 'warning' | 'danger' | 'info' | 'default'
}

interface State {
  alertVisible: boolean
  paramName: string
  type: 'success' | 'warning' | 'danger' | 'info' | 'default'
  message: string
}

/* HashAlert class: Bootstrap Alert wrapper that intelligently displays
      a message if and when it appears in the URL hash. */
class HashAlert extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      alertVisible: false,
      paramName: props.paramName,
      type: props.type || 'default',
      message: ''
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    this.updateMessage(nextProps.location)
  }

  updateMessage = (location: Location) => {
    let vis = false
    let message = ''
    if (location.hash.includes(this.state.paramName)) {
      vis = true
      message = location.hash.substring(
        location.hash.indexOf(this.state.paramName) +
          this.state.paramName.length
      )
      if (message && message[0] === '=') {
        message = message.substring(1)
        if (message.includes('&'))
          message = message.substring(0, message.indexOf('&'))
        message = decodeURIComponent(message)
      } else {
        message = ''
      }
    }

    this.setState({
      alertVisible: vis,
      message
    })
  }

  /* removeMessage(): removes state.paramName and its argument from the
        url location hash and pushes the state to history */
  removeMessage = () => {
    let oldhash = this.props.location.hash
    const idxOf = oldhash.indexOf(this.state.paramName)
    if (idxOf !== -1) {
      let end = idxOf + this.state.paramName.length
      if (oldhash.length !== end) {
        const amp = oldhash.indexOf('&', end)
        if (amp > 0) {
          end = amp + 1
        } else {
          end = oldhash.length
        }
      }
      oldhash = oldhash.substring(0, idxOf) + oldhash.substring(end)

      history.pushState(null, null, oldhash)
    }

    this.setState({
      alertVisible: false,
      message: ''
    })
  }

  render() {
    return (
      this.state.alertVisible && (
        <Alert bsStyle={this.state.type} onDismiss={this.removeMessage}>
          <strong>
            {this.props.title}
            {this.state.message && ': '}
          </strong>
          {this.state.message}
        </Alert>
      )
    )
  }
}

export default HashAlert
