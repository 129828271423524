import * as React from 'react'
import {inject, observer} from 'mobx-react'
import {GoogleMap, Marker, withGoogleMap, withScriptjs} from 'react-google-maps'
import DrawingManager from 'react-google-maps/lib/components/drawing/DrawingManager'
import {Button, ControlLabel, FormControl, FormGroup} from 'react-bootstrap'

import {GOOGLE_MAPS_KEY, SandTLATLNG} from 'globals'
import {UserStore} from 'stores/userStore'
import {ScreenStore} from 'stores/screenStore'
import {BroadcastStore} from 'stores/broadcastStore'
import {AlertStore} from 'stores/alertStore'
import {AppStateStore} from 'stores/appStateStore'
import Broadcast from 'stores/Broadcast'

const Close = require('images/elements/close-x.svg')
const Pin = require('images/urls/pin.svg')
const activePin = require('images/urls/active-pin.svg')

const MAP_URL = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_KEY}&libraries=drawing,geometry`

interface Props {
  userStore?: UserStore
  screenStore?: ScreenStore
  broadcastStore?: BroadcastStore
  alertStore?: AlertStore
  appStateStore?: AppStateStore
}

interface State {
  inputValue: string
  disabled: boolean
  drawingControl: boolean
  drawingMode: string
  polygon: any
  deleteVisible: boolean
  selectedMarkers: any
}

@inject(
  'userStore',
  'screenStore',
  'broadcastStore',
  'alertStore',
  'appStateStore'
)
@observer
class SideNavBroadcast extends React.Component<Props, State> {
  BroadcastMap = withScriptjs(
    withGoogleMap(props => (
      <GoogleMap
        zoom={15}
        center={SandTLATLNG}
        options={{
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false
        }}
      >
        {props.markers.map((marker, i) => {
          return (
            <Marker
              {...marker}
              icon={{
                url: this.state.selectedMarkers.includes(marker.id)
                  ? activePin
                  : Pin,
                scaledSize: new (window as any).google.maps.Size(26, 35)
              }}
              key={i}
            />
          )
        })}

        <DrawingManager
          onPolygonComplete={this.onPolygonComplete}
          drawingMode={this.state.drawingMode}
          options={{
            drawingControl: this.state.drawingControl,
            drawingControlOptions: {
              position: (window as any).google.maps.ControlPosition.TOP_LEFT,
              drawingModes: [
                (window as any).google.maps.drawing.OverlayType.POLYGON
              ]
            },
            markerOptions: {
              draggable: true
            },
            polygonOptions: {
              editable: true
            }
          }}
        />
      </GoogleMap>
    ))
  )

  constructor(props) {
    super(props)
    this.state = {
      inputValue: '',
      disabled: false,
      drawingControl: true,
      drawingMode: 'polygon',
      polygon: null,
      deleteVisible: false,
      selectedMarkers: []
    }
  }

  get userAlertPrivelege() {
    return this.props.userStore.currentUser
      ? this.props.userStore.currentUser.alert_priv
      : 0
  }

  onInputChange = event => {
    this.setState({inputValue: event.target.value})
  }

  polyDelete = () => {
    this.state.polygon.setMap(null)
    this.setState({
      drawingControl: true,
      drawingMode: 'polygon',
      polygon: null,
      deleteVisible: false,
      selectedMarkers: []
    })
  }

  updateSelectedMarkers = polygon => {
    const selectedMarkers = []
    const markers = this.getMarkers()

    for (const x in markers) {
      if (
        (window as any).google.maps.geometry.poly.containsLocation(
          new (window as any).google.maps.LatLng({
            lat: markers[x].position.lat,
            lng: markers[x].position.lng
          }),
          polygon
        )
      )
        selectedMarkers.push(markers[x].id)
    }

    this.setState({
      polygon,
      selectedMarkers
    })
  }

  onPolygonComplete = polygon => {
    this.setState({
      drawingControl: false,
      drawingMode: null,
      polygon,
      deleteVisible: true
    })

    this.updateSelectedMarkers(polygon)

    polygon
      .getPath()
      .addListener('set_at', () => this.updateSelectedMarkers(polygon))
    polygon
      .getPath()
      .addListener('insert_at', () => this.updateSelectedMarkers(polygon))
  }

  handleSubmit = event => {
    event.preventDefault()
    // POST some state data to the back end before it's obliterated here
    this.setState({disabled: true})

    if (this.canSubmit) {
      const {inputValue, selectedMarkers} = this.state
      const me = this.props.userStore.currentUser
      const screens = selectedMarkers.map(id =>
        this.props.screenStore.findById(id)
      )
      const bcast = new Broadcast({
        id: '0',
        user_id: me.id,
        override: 0,
        message_text: inputValue,
        screenIDs: []
      })

      this.props.broadcastStore
        .create(bcast)
        .then((broadcast: Broadcast) => {
          screens.forEach(scrn => broadcast.addScreen(scrn))
          this.props.alertStore.addAlert(
            'Successfully created broadcast.',
            'success'
          )
          this.props.appStateStore.closeSideNav()
        })
        .catch(err => this.props.alertStore.addAlert(err.message, 'danger'))

      this.state.polygon.setMap(null)
      this.setState({
        inputValue: '',
        disabled: false,
        drawingControl: true,
        polygon: null,
        deleteVisible: false,
        selectedMarkers: []
      })
    } else {
      this.setState({disabled: false})
    }
  }

  get canSubmit() {
    return this.state.inputValue.trim() && this.state.selectedMarkers.length
  }

  getMarkers = () => {
    return this.props.screenStore.screens.map(screen => ({
      id: screen.id,
      position: {
        lat: Number(screen.lat),
        lng: Number(screen.lon)
      }
    }))
  }

  render() {
    const markers = this.getMarkers()

    const deleteClass = this.state.deleteVisible
      ? 'poly-delete'
      : 'poly-delete hide'

    if (!this.userAlertPrivelege) {
      return <div />
    }

    return (
      <form onSubmit={this.handleSubmit}>
        <FormGroup>
          <ControlLabel>Enter broadcast message</ControlLabel>
          <FormControl
            type="text"
            onChange={this.onInputChange}
            disabled={this.state.disabled}
          />
        </FormGroup>
        <hr />
        <FormGroup>
          <ControlLabel>draw a polygon to select screens</ControlLabel>
          <Button onClick={this.polyDelete} className={deleteClass}>
            <Close className="map-close" />
          </Button>
          <this.BroadcastMap
            googleMapURL={MAP_URL}
            resetBoundsOnResize={true}
            loadingElement={<div className="broadcast-map-loading" />}
            containerElement={<div className="broadcast-map-container" />}
            mapElement={<div className="broadcast-map" />}
            markers={markers}
          />
          <hr />
        </FormGroup>
        <Button type="submit" disabled={!this.canSubmit || this.state.disabled}>
          Broadcast
        </Button>
      </form>
    )
  }
}

export default SideNavBroadcast
