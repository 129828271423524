import * as React from 'react'

import {observer} from 'mobx-react'
import {computed} from 'mobx'
import {Button, Checkbox, ControlLabel, FormGroup, Panel} from 'react-bootstrap'

import API, {DataResponse} from 'api'

interface State {
  cleanFilesExpanded: boolean
  cleanFilesInProgress: boolean
  cleanFilesDryRun: boolean
  cleanFilesReturned: any
  validateAssetExpanded: boolean
  validateAssetInProgress: boolean
}

@observer
class ContentMaintenanceTab extends React.Component<{}, State> {
  constructor(props) {
    super(props)

    this.state = {
      cleanFilesExpanded: false,
      cleanFilesInProgress: false,
      cleanFilesDryRun: false,
      cleanFilesReturned: null,
      validateAssetExpanded: false,
      validateAssetInProgress: false
    }
  }

  onDryRunChange = e => {
    this.setState({
      cleanFilesDryRun: e.target.checked
    })
  }

  onClickClean = () => {
    this.setState({
      cleanFilesInProgress: true
    })
    API.admin.misc.cleanFiles(this.state.cleanFilesDryRun).then(res => {
      console.info('clean response:', res)
      this.setState({
        cleanFilesInProgress: false,
        cleanFilesReturned: res
      })
    })
  }

  toggleCleanPanel = () => {
    this.setState({cleanFilesExpanded: !this.state.cleanFilesExpanded})
  }

  toggleValidatePanel = () => {
    this.setState({validateAssetExpanded: !this.state.validateAssetExpanded})
  }

  @computed
  get cleanFilesOutput() {
    const ret = this.state.cleanFilesReturned
    if (!ret || !ret.status) return 'No Results'
    if (ret.status !== 'success') return `Status: ${ret.status}`
    else
      return (
        <table>
          <tbody>
            <tr>
              <td>Status:</td>
              <td>{ret.status}</td>
            </tr>
            <tr>
              <td>Dry-Run:</td>
              <td>{ret.data['dry-run'] ? 'true' : 'false'}</td>
            </tr>
            <tr>
              <td>Directories:</td>
              <td>
                <pre>{ret.data.directories.join('\n')}</pre>
              </td>
            </tr>
          </tbody>
        </table>
      )
  }

  render() {
    return (
      <div id="content-maintenance" className="admin-inner-tab">
        <span className="description">
          Perform backend maintenance operations for content.
        </span>
        <FormGroup className="panel-label-fg">
          <ControlLabel className="panel-label" onClick={this.toggleCleanPanel}>
            Clean Files&hellip;
          </ControlLabel>
        </FormGroup>
        <Panel collapsible expanded={this.state.cleanFilesExpanded}>
          <FormGroup controlId="content-maintenance-form-clean">
            <ControlLabel>Clean</ControlLabel>
            <Checkbox
              id="content-maintenance-form-dry-run"
              checked={this.state.cleanFilesDryRun}
              onChange={this.onDryRunChange}
            >
              Dry Run?
            </Checkbox>
            <Button
              id="content-maintenance-form-clean"
              bsStyle="success"
              disabled={this.state.cleanFilesInProgress}
              title={this.state.cleanFilesInProgress ? 'In Progress...' : null}
              onClick={this.onClickClean}
            >
              Clean Files
            </Button>
          </FormGroup>
          <FormGroup>
            <ControlLabel>Output</ControlLabel>
            <br />
            {this.cleanFilesOutput}
          </FormGroup>
        </Panel>
        <FormGroup className="panel-label-fg">
          <ControlLabel
            className="panel-label"
            onClick={this.toggleValidatePanel}
          >
            Validate Assets&hellip;
          </ControlLabel>
        </FormGroup>
        <Panel collapsible expanded={this.state.validateAssetExpanded}>
          <FormGroup controlId="content-maintenance-form-validate">
            <ControlLabel>Validate</ControlLabel>
            <Button
              id="content-maintenance-form-validate"
              bsStyle="success"
              disabled={true || this.state.validateAssetInProgress}
              title="Disabled"
            >
              Validate Assets
            </Button>
          </FormGroup>
          <FormGroup>
            <ControlLabel>Output</ControlLabel>
            <br />
            {this.cleanFilesOutput}
          </FormGroup>
        </Panel>
      </div>
    )
  }
}

export default ContentMaintenanceTab
