import * as React from 'react'
import {computed, observable} from 'mobx'
import {inject, observer} from 'mobx-react'
import {
  Button,
  ButtonGroup,
  ControlLabel,
  FormControl,
  FormGroup,
  Glyphicon,
  Panel,
  Radio
} from 'react-bootstrap'

import {ValidationState} from 'globals'
import {RealScreen, RealUser} from 'api/realsources'
import {AlertStore} from 'stores/alertStore'
import OwnerSelect from 'modules/admin/OwnerSelect'
import RealShareSelect from 'modules/admin/RealShareSelect'
import {RealScreenStore} from 'modules/admin/adminstores'
import {netValidity} from '../formhelpers'

const MACADDR_RE = /^([0-9a-f]{2}[:-]?){5}([0-9a-f]{2})$/i
const MACSEP_RE = /:|-/g
const MAC_SPLIT_RE = /.{2}/g

function cleanMAC(arg: string): string {
  if (!MACADDR_RE.test(arg)) return null
  return arg
    .replace(MACSEP_RE, '')
    .match(MAC_SPLIT_RE)
    .join(':')
}

export interface PermedRealScreen extends RealScreen {
  editors: {
    added: number[]
    removed: number[]
  }
  viewers: {
    added: number[]
    removed: number[]
  }
}

/*
*name
*pi_mac_addr
 key
 hostname         (nullable)
 location         (nullable)
*lat              (nullable)
*lon              (nullable)
*orientation                (default: vertical)
 marquee          (nullable)
 mq_created                 (default: 1000 AD)
 mq_duration                (default: 0)
 gen_info         (nullable)
 mb_version       (nullable)
 mb_service_ver   (nullable)
 hardware_info    (nullable)
 time_start                 (default: 00:00)
 date_start                 (default: 1000 AD)
 time_stop                  (default: 00:00)
 date_stop                  (default: 9999 AD)
 reboot                     (default: 0)
 new_config                 (default: 0)
 send_log                   (default: 0)
 distro                     (default: 0)
 config
 log
 notes
 */
interface Props {
  formType: 'create' | 'update'
  onSubmit: (screen: PermedRealScreen, cb: (success: boolean) => void) => void
  id?: string
  className?: string
  reference?: RealScreen
  disabled?: boolean
  alertStore?: AlertStore
}

interface State {
  disabled: boolean
  orientationIsVertical: boolean
  locPanelOpen: boolean
  notesPanelOpen: boolean
  sharePanelOpen: boolean
}

function _ScreenFormProps_unchanged(props, nextProps) {
  return ['formType', 'id', 'className', 'reference', 'disabled'].every(
    prop => {
      return props[prop] === nextProps[prop]
    }
  )
}

@inject('alertStore')
@observer
class ScreenForm extends React.Component<Props, State> {
  static defaultProps = {
    id: 'ScreenForm',
    className: '',
    reference: null,
    disabled: false
  }

  static _validDefaults() {
    return {
      name: 'warning' as 'warning',
      pi_mac_addr: 'warning' as 'warning',
      latlon: null,
      location: null,
      owner: 'warning' as 'warning',
      editors: null,
      viewers: null,
      orientation: null,
      notes: null
    }
  }

  static _problemsDefaults() {
    return {
      name: 'Name required',
      pi_mac_addr: 'MAC address required',
      lat: null,
      lon: null,
      location: null,
      owner: 'Owner required',
      editors: null,
      viewers: null
    }
  }

  static _stateDefaults(props: Props): State {
    return {
      disabled: props.disabled,
      orientationIsVertical: props.reference
        ? props.reference.orientation === 'vertical'
        : true,
      sharePanelOpen: false,
      locPanelOpen: false,
      notesPanelOpen: false
    }
  }

  inputRefs: {
    name: HTMLInputElement
    pi_mac_addr: HTMLInputElement
    orientationV: HTMLInputElement
    orientationH: HTMLInputElement
    lat: HTMLInputElement
    lon: HTMLInputElement
    location: HTMLInputElement
    notes: HTMLTextAreaElement
    editors: RealShareSelect<RealScreen>
    viewers: RealShareSelect<RealScreen>
  }

  values: {
    name: string
    pi_mac_addr: string
    owner: RealUser
    lat: string
    lon: string
    location: string
    notes: string
    editors: RealUser[]
    viewers: RealUser[]
  }

  valid: {
    name: ValidationState
    pi_mac_addr: ValidationState
    latlon: ValidationState
    location: ValidationState
    owner: ValidationState
    editors: ValidationState
    viewers: ValidationState
    orientation: ValidationState
    notes: ValidationState
  }

  problems: {
    name: string | null
    pi_mac_addr: string | null
    lat: string | null
    lon: string | null
    location: string | null
    owner: string | null
    editors: string | null
    viewers: string | null
  }

  constructor(props) {
    super(props)

    if (!props.disabled && props.formType === 'update' && !props.reference)
      throw new Error(
        "ScreenForm requires prop 'reference' when prop 'formType' == \"update\" and !'disabled'"
      )

    this.inputRefs = observable.object({
      name: null,
      pi_mac_addr: null,
      orientationV: null,
      orientationH: null,
      lat: null,
      lon: null,
      location: null,
      notes: null,
      editors: null,
      viewers: null
    })
    this.valid = observable.object(ScreenForm._validDefaults())
    this.values = observable.object({
      name: this.getDefault('name', '', props),
      pi_mac_addr: this.getDefault('pi_mac_addr', '', props),
      owner: this.getDefault('owner', null, props),
      lat: this.getDefault('lat', '', props),
      lon: this.getDefault('lon', '', props),
      location: this.getDefault('location', '', props),
      notes: this.getDefault('notes', '', props),
      editors: [],
      viewers: []
    })
    this.problems = observable.object(ScreenForm._problemsDefaults())
    this.state = ScreenForm._stateDefaults(props)
  }

  @computed
  get canSubmit(): boolean {
    const valids = [
      this.valid.name,
      this.valid.pi_mac_addr,
      this.valid.latlon,
      this.valid.location,
      this.valid.owner,
      this.valid.editors,
      this.valid.viewers,
      this.valid.orientation,
      this.valid.notes
    ]

    return !this.state.disabled && netValidity(valids) === 'success'
  }

  @computed
  get locationOptionsValid(): ValidationState {
    return netValidity([this.valid.latlon, this.valid.location])
  }

  @computed
  get shareOptionsValid(): ValidationState {
    return netValidity([this.valid.editors, this.valid.viewers])
  }

  componentWillReceiveProps(nextProps: Props) {
    if (_ScreenFormProps_unchanged(this.props, nextProps)) return
    if (
      !nextProps.disabled &&
      nextProps.formType === 'update' &&
      !nextProps.reference
    )
      throw new Error(
        "ScreenForm requires prop 'reference' when prop 'formType' == \"update\" and !'disabled'"
      )

    this.reset(null, nextProps)
  }

  getDefault = (field: string, defaultValue: any = null, props?: Props) => {
    props = props || this.props
    if (props.reference) {
      return props.reference[field]
    }
    return defaultValue
  }

  onNameChange = (e?) => {
    const value = this.inputRefs.name.value

    this.values.name = value

    if (!value) {
      this.valid.name = 'warning'
      this.problems.name = 'Name required'
    } else if (
      this.props.formType === 'update' &&
      this.props.reference.name === value.trim()
    ) {
      this.valid.name = null
      this.problems.name = null
    } else {
      this.valid.name = 'success'
      this.problems.name = null
    }
  }

  onMacaddrChange = (e?) => {
    let value = this.inputRefs.pi_mac_addr.value.trim()
    let valid = null
    let problems = null

    this.values.pi_mac_addr = value

    if (!value) {
      valid = 'warning'
      problems = 'MAC address required'
    } else if (!MACADDR_RE.test(value)) {
      valid = 'error'
      problems = 'Invalid MAC address'
    } else {
      value = cleanMAC(value)
      if (
        this.props.formType === 'update' &&
        value === this.props.reference.pi_mac_addr
      )
        valid = null
      else valid = 'success'
      this.values.pi_mac_addr = value
    }

    this.valid.pi_mac_addr = valid
    this.problems.pi_mac_addr = problems
  }

  onOrientationChange = (e?) => {
    if (this.inputRefs.orientationV) {
      const isVert = this.inputRefs.orientationV.checked
      if (
        this.props.formType === 'update' &&
        (this.props.reference.orientation === 'vertical') === isVert
      )
        this.valid.orientation = null
      else this.valid.orientation = 'success'
      this.setState({
        orientationIsVertical: isVert
      })
    }
  }

  onLatLonChange = (e?) => {
    let valueLat = this.inputRefs.lat.value.trim() as any
    let valueLon = this.inputRefs.lon.value.trim() as any
    let valid = null
    let problemsLat = null
    let problemsLon = null

    this.values.lat = valueLat
    this.values.lon = valueLon

    if (!(!valueLat && !valueLon) && valueLat && valueLon) {
      const badlat = valueLat && isNaN(valueLat)
      const badlon = valueLon && isNaN(valueLon)

      if (badlat || badlon) {
        if (badlat) {
          valid = 'error'
          problemsLat = 'Non-number latitude'
        }
        if (badlon) {
          valid = 'error'
          problemsLon = 'Non-number longitude'
        }
      } else {
        valueLat = Number(valueLat)
        valueLon = Number(valueLon)

        if (valueLat < -90 || valueLat > 90) {
          valid = 'error'
          problemsLat = 'Invalid latitude'
        } else if (valueLon < -180 || valueLon > 180) {
          valid = 'error'
          problemsLon = 'Invalid longitude'
        } else {
          valid = 'success'
          if (
            this.props.formType === 'update' &&
            this.props.reference.lat === valueLat &&
            this.props.reference.lon === valueLon
          )
            valid = null
        }
      }
    } else {
      // one but not both
      valid = 'warning'
      if (valueLat) problemsLon = 'Must provide longitude if providing latitude'
      else problemsLat = 'Must provide latitude if providing longitude'
    }
    this.valid.latlon = valid
    this.problems.lat = problemsLat
    this.problems.lon = problemsLon
  }

  onLocationChange = (e?) => {
    const value = this.inputRefs.location.value

    this.values.location = value

    if (
      this.props.formType === 'update' &&
      this.props.reference.location === value.trim()
    )
      this.valid.location = null
    else this.valid.location = value.length ? 'success' : null
  }

  onOwnerChange = (newValue: RealUser) => {
    let value = null
    let valid = null
    let problems = null

    if (newValue && newValue instanceof Object && !Array.isArray(newValue)) {
      if (
        !(
          this.props.formType === 'update' &&
          this.props.reference.ownerID === newValue.id
        )
      ) {
        valid = 'success'
      }
      value = newValue
    } else {
      valid = newValue ? 'error' : 'warning'
      problems = newValue ? 'Invalid owner' : 'Must select an owner'
    }
    this.values.owner = value
    this.valid.owner = valid
    this.problems.owner = problems
  }

  onNotesChange = (e?) => {
    this.values.notes = this.inputRefs.notes.value
    if (
      (!this.values.notes && !this.props.reference.notes) ||
      this.values.notes === this.props.reference.notes
    )
      this.valid.notes = null
    else this.valid.notes = 'success'
  }

  onEditorsChange = (newSelection: RealUser[]) => {
    if (!Array.isArray(newSelection)) newSelection = []
    this.values.editors = newSelection
    this.valid.editors = (newSelection as any).valid || null
  }

  onViewersChange = (newSelection: RealUser[]) => {
    if (!Array.isArray(newSelection)) newSelection = []
    this.values.viewers = newSelection
    this.valid.viewers = (newSelection as any).valid || null
  }

  /*
    onSubmit(): handler for form submission. Generates a RealScreen
      and calls `this.props.onSubmit()` with it as an argument with the
      following interface:
      {
        id?: number
        name: string
        owner: RealUser
        ownerID: number
        orientation: 'vertical'|'horizontal'
        pi_mac_addr: string
        lat?: number
        lon?: number
        location?: number
        notes: string
        editors?: {added: number[], removed: number[]}
        viewers?: {added: number[], removed: number[]}
      }
  */
  onSubmit = (e?) => {
    console.info('called ScreenForm.onSubmit()')
    if (e) e.preventDefault()

    if (!this.canSubmit) return

    try {
      this.setState({disabled: true})

      const newScreen: PermedRealScreen = {
        name: this.values.name.trim(),
        ownerID: this.values.owner.id,
        owner: this.values.owner,
        orientation: this.state.orientationIsVertical
          ? 'vertical'
          : 'horizontal',
        pi_mac_addr: cleanMAC(this.values.pi_mac_addr),
        notes: this.values.notes.trim() || null,
        editors: this.inputRefs.editors.getDiff(),
        viewers: this.inputRefs.viewers.getDiff()
      }

      if (this.valid.latlon === 'success') {
        newScreen.lat = Number(this.values.lat)
        newScreen.lon = Number(this.values.lon)
      }
      if (this.valid.location === 'success')
        newScreen.location = this.values.location.trim()

      if (this.props.formType === 'update')
        newScreen.id = this.props.reference.id

      this.props.onSubmit(newScreen, this.onSubmitCallback)
    } catch (err) {
      this.setState({disabled: false})
      this.props.alertStore.addAlert(
        err,
        'danger',
        'An error occurred while submitting'
      )
    }
  }

  onSubmitCallback = (success: boolean): void => {
    this.reset(null)
  }

  toggleSharePanel = (e?) => {
    this.setState({sharePanelOpen: !this.state.sharePanelOpen})
  }

  toggleLocPanel = (e?) => {
    this.setState({locPanelOpen: !this.state.locPanelOpen})
  }

  toggleNotesPanel = (e?) => {
    this.setState({notesPanelOpen: !this.state.notesPanelOpen})
  }

  reset = (e, props: Props = null) => {
    if (e) e.preventDefault()
    if (!props) props = this.props
    ;['name', 'pi_mac_addr', 'lat', 'lon', 'location', 'notes'].forEach(
      label => {
        this.values[label] = this.getDefault(label, '', props)
      }
    )

    this.setState(ScreenForm._stateDefaults(props), () => {
      if (props && props.reference) {
        this.onNameChange()
        this.onMacaddrChange()
        this.onLatLonChange()
        this.onLocationChange()
        this.onOwnerChange(props.reference.owner)
        this.onNotesChange()
        this.inputRefs.editors.reset(true)
        this.inputRefs.viewers.reset(true)
      } else {
        Object.assign(this.valid, ScreenForm._validDefaults())
        Object.assign(this.problems, ScreenForm._problemsDefaults())
      }
    })
  }

  render() {
    return (
      <form
        id={this.props.id}
        className={`ScreenForm ${this.props.className}`}
        onSubmit={this.onSubmit}
        noValidate
      >
        {/* NAME */}
        <FormGroup
          className="ScreenForm-name-group"
          controlId={`${this.props.id}-name`}
          validationState={this.valid.name}
        >
          <ControlLabel>Screen Name</ControlLabel>
          <FormControl
            type="text"
            value={this.values.name}
            inputRef={ref => (this.inputRefs.name = ref)}
            onChange={this.onNameChange}
            title={this.problems.name}
            required
            disabled={this.state.disabled}
          />
          <FormControl.Feedback />
        </FormGroup>
        {/* MAC ADDRESS */}
        <FormGroup
          className="ScreenForm-macaddr-group"
          controlId={`${this.props.id}-macaddr`}
          validationState={this.valid.pi_mac_addr}
        >
          <ControlLabel>MAC Address</ControlLabel>
          <FormControl
            type="text"
            value={this.values.pi_mac_addr || ''}
            inputRef={ref => (this.inputRefs.pi_mac_addr = ref)}
            onChange={this.onMacaddrChange}
            title={this.problems.pi_mac_addr}
            required
            disabled={this.state.disabled}
            placeholder="00:00:00:00:00:00"
          />
          <FormControl.Feedback />
        </FormGroup>
        {/* OWNER */}
        <FormGroup
          className="ScreenForm-owner-group"
          controlId={`${this.props.id}-owner`}
          validationState={this.valid.owner}
        >
          <ControlLabel title={this.problems.owner}>Owner</ControlLabel>
          <OwnerSelect
            value={this.values.owner}
            id={`${this.props.id}-owner`}
            onChange={this.onOwnerChange}
            title={this.problems.owner}
            disabled={this.state.disabled}
          />
        </FormGroup>
        {/* SHARE PANEL */}
        <FormGroup
          className="panel-label-fg"
          validationState={this.shareOptionsValid}
        >
          <ControlLabel className="panel-label" onClick={this.toggleSharePanel}>
            Share Options&hellip;
          </ControlLabel>
        </FormGroup>
        <Panel collapsible expanded={this.state.sharePanelOpen}>
          <FormGroup
            className="ScreenForm-editors-group"
            controlId={`${this.props.id}-editors`}
            validationState={this.valid.editors}
          >
            <ControlLabel>
              Editors <Glyphicon glyph="pencil" />
            </ControlLabel>
            <RealShareSelect
              id={`${this.props.id}-editors`}
              disabled={this.state.disabled}
              title={this.problems.editors}
              target={this.props.reference}
              store={RealScreenStore}
              permission="editor"
              onChange={this.onEditorsChange}
              rref={ref => (this.inputRefs.editors = ref)}
            />
          </FormGroup>
          <FormGroup
            className="ScreenForm-viewers-group"
            controlId={`${this.props.id}-viewers`}
            validationState={this.valid.viewers}
          >
            <ControlLabel>
              Viewers <Glyphicon glyph="eye-open" />
            </ControlLabel>
            <RealShareSelect
              id={`${this.props.id}-viewers`}
              disabled={this.state.disabled}
              title={this.problems.viewers}
              target={this.props.reference}
              store={RealScreenStore}
              permission="viewer"
              onChange={this.onViewersChange}
              rref={ref => (this.inputRefs.viewers = ref)}
            />
          </FormGroup>
        </Panel>
        {/* ORIENTATION */}
        <FormGroup
          className="ScreenForm-orientation-group"
          controlId={`${this.props.id}-orientation`}
          validationState={this.valid.orientation}
        >
          <ControlLabel>Orientation</ControlLabel>
          <ButtonGroup>
            <Button
              componentClass="label"
              htmlFor={`${this.props.id}-orientation-v`}
              active={this.state.orientationIsVertical}
            >
              <Radio
                name="orientation"
                id={`${this.props.id}-orientation-v`}
                inputRef={ref => (this.inputRefs.orientationV = ref)}
                disabled={this.state.disabled}
                checked={this.state.orientationIsVertical}
                onChange={this.onOrientationChange}
              />{' '}
              Vertical
            </Button>
            <Button
              componentClass="label"
              htmlFor={`${this.props.id}-orientation-h`}
              active={!this.state.orientationIsVertical}
            >
              <Radio
                name="orientation"
                id={`${this.props.id}-orientation-h`}
                inputRef={ref => (this.inputRefs.orientationH = ref)}
                disabled={this.state.disabled}
                checked={!this.state.orientationIsVertical}
                onChange={this.onOrientationChange}
              />{' '}
              Horizontal
            </Button>
          </ButtonGroup>
        </FormGroup>
        {/* LOCATION PANEL */}
        <FormGroup
          className="panel-label-fg"
          validationState={this.locationOptionsValid}
        >
          <ControlLabel className="panel-label" onClick={this.toggleLocPanel}>
            Location Options&hellip;
          </ControlLabel>
        </FormGroup>
        <Panel collapsible expanded={this.state.locPanelOpen}>
          <FormGroup
            className="ScreenForm-latlon-group"
            validationState={this.valid.latlon}
          >
            <ControlLabel>Latitude/Longitude</ControlLabel>
            <br />
            <FormControl
              id={`${this.props.id}-lat`}
              className="lat-input"
              type="number"
              value={this.values.lat || 37.953749}
              inputRef={ref => (this.inputRefs.lat = ref)}
              onChange={this.onLatLonChange}
              title={this.problems.lat}
              disabled={this.state.disabled}
              min={-90}
              max={90}
            />
            <FormControl
              id={`${this.props.id}-lon`}
              className="lon-input"
              type="number"
              value={this.values.lon || -91.773536}
              inputRef={ref => (this.inputRefs.lon = ref)}
              onChange={this.onLatLonChange}
              title={this.problems.lon}
              disabled={this.state.disabled}
              min={-180}
              max={180}
            />
          </FormGroup>
          <FormGroup
            className="ScreenForm-location-group"
            controlId={`${this.props.id}-location`}
            validationState={this.valid.location}
          >
            <ControlLabel>Location</ControlLabel>
            <FormControl
              type="text"
              value={this.values.location || ''}
              inputRef={ref => (this.inputRefs.location = ref)}
              onChange={this.onLocationChange}
              title={this.problems.location}
              disabled={this.state.disabled}
            />
          </FormGroup>
        </Panel>
        <ControlLabel
          className="panel-label"
          htmlFor={`${this.props.id}-notes`}
          onClick={this.toggleNotesPanel}
        >
          Notes&hellip;
        </ControlLabel>
        <Panel
          collapsible
          expanded={this.state.notesPanelOpen}
          className="ScreenForm-notes-panel"
        >
          <FormControl
            componentClass="textarea"
            value={this.values.notes || ''}
            inputRef={ref =>
              (this.inputRefs.notes = (ref as any) as HTMLTextAreaElement)
            }
            id={`${this.props.id}-notes`}
            onChange={this.onNotesChange}
            disabled={this.state.disabled}
          />
        </Panel>
        <Button
          type="submit"
          disabled={this.state.disabled || !this.canSubmit}
          bsStyle="primary"
        >
          {this.props.formType === 'create' ? 'Create' : 'Update'} Screen
        </Button>
        <Button type="button" onClick={this.reset}>
          Reset
        </Button>
      </form>
    )
  }
}

export default ScreenForm
