import * as React from 'react'
import {inject, observer} from 'mobx-react'
import {computed, reaction} from 'mobx'
import * as moment from 'moment'

import {PlaylistStore} from 'stores/playlistStore'
import {ScreenStore} from 'stores/screenStore'
import {AppStateStore} from 'stores/appStateStore'
import Screen from 'stores/Screen'
import User from 'stores/User'
import Marquee from './Marquee'
import CurrentPlaylistSelect from './CurrentPlaylistSelect'
import {
  LocationControl,
  NicknameControl,
  PowerControl,
  ScheduleControl,
  StopMarqueeControl
} from './ControlComponents'
import SVGButton from 'modules/common/SVGButton'
import ShareModal from 'modules/common/ShareModal'

const VerticalIcon = require('images/elements/vertical-icon.svg')
const HorizontalIcon = require('images/elements/horizontal-icon.svg')
const ScheduleIcon = require('images/elements/schedule-icon.svg')
const ShareIcon = require('images/elements/share-icon.svg')
const NicknameIcon = require('images/elements/nickname-icon.svg')
const PowerIcon = require('images/elements/power-icon.svg')
const LocationIcon = require('images/elements/location-icon.svg')
const StopMarqueeIcon = require('images/elements/stop-marquee-icon.svg')

const ASYNC_DELAY = 500

interface Props {
  screen: Screen
  screenStore?: ScreenStore
  playlistStore?: PlaylistStore
  appStateStore?: AppStateStore
}

interface State {
  expandedDiv: any
  expandedClass: string
  sharedWith: string[]
  isSharing: boolean
}

@inject('screenStore', 'playlistStore', 'appStateStore')
@observer
class SingleScreen extends React.Component<Props, State> {
  removeExpandedReaction = reaction(
    () => this.props.appStateStore.expandedScreen,
    () => {
      if (
        this.props.appStateStore.expandedScreen !== null &&
        this.props.appStateStore.expandedScreen !== this.props.screen.id
      ) {
        this.setState({
          expandedDiv: null,
          expandedClass: null
        })
      }
    }
  )

  constructor(props: Props) {
    super(props)

    this.state = {
      expandedDiv: null,
      expandedClass: null,
      sharedWith: props.screen.sharedIDs,
      isSharing: false
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if ($('.schedule-control').length) {
      if (this.state.expandedDiv) {
        this.resizeScheduleControl()
        $(window).resize(() => this.resizeScheduleControl())
      }
    } else {
      $(window).off('resize')
    }
  }

  componentWillUnmount() {
    this.removeExpandedReaction()
  }

  resizeScheduleControl = () => {
    const ssContainer = $('.schedule-control').parent()
    const width = ssContainer.width()
    const left = ssContainer.position().left

    let marginLeft = 0
    // if left side of container isn't on the side of the page, change margin.
    if (left > 2) {
      marginLeft = left
      // if left side of container is more than 2 screen-widths from the side,
      //  limit left margin to exactly two screen widths.
      if (left > width * 2 + 34) {
        marginLeft = width * 2 + 32
      }
    }

    $('.schedule-control').css({
      'margin-left': `-${marginLeft}px`,
      'max-width': `${3 * width + 34}px`
    })
  }

  @computed
  get permission() {
    return this.props.screen.userPermissions.myPermission
  }

  @computed
  get isOwner() {
    return this.permission === 'owner'
  }

  @computed
  get canEdit() {
    return this.permission === 'owner' || this.permission === 'editor'
  }

  @computed
  get previewThumbnails() {
    const thumbs = this.props.screen.thumbnails
    if (!thumbs) {
      return []
    }

    const L = thumbs.length
    const thumbnails: JSX.Element[] = []

    if (L === 1) {
      thumbnails.push(
        <div
          key={0}
          className="image-full"
          style={{backgroundImage: `url('${thumbs[0]}')`}}
        />
      )
    } else if (L > 1) {
      for (let i = 0; i < 4; i++) {
        thumbnails.push(
          i < L ? (
            <div
              key={i + 1}
              className={`image-${i + 1}`}
              style={{backgroundImage: `url('${thumbs[i]}')`}}
            />
          ) : (
            <div key={i + 1} className={`image-${i + 1}`} />
          )
        )
      }
    }

    return thumbnails
  }

  @computed
  get statusClass() {
    const last_req = this.props.screen.last_req

    if (last_req === null) {
      return 'never'
    }

    const diffMins = moment().diff(last_req) / (1000 * 60)
    if (diffMins < -30) {
      return 'future'
    } else if (diffMins < 30) {
      return 'active'
    } else if (diffMins < 1440) {
      return 'late'
    }
    return 'very-late'
  }

  createOrientationElement = (orientation: string) => {
    let orientationSymbol
    let orientationText

    if (orientation === 'vertical') {
      orientationSymbol = <VerticalIcon className="vertical" />
      orientationText = 'Vertical'
    } else if (orientation === 'horizontal') {
      orientationSymbol = <HorizontalIcon className="horizontal" />
      orientationText = 'Horizontal'
    } else {
      return null
    }

    return (
      <div className="orientation-div">
        {orientationSymbol}
        <p className="orientation-text">{orientationText}</p>
      </div>
    )
  }

  handleExpansionConflict = () => {
    if (this.props.appStateStore.expandedScreen !== this.props.screen.id) {
      this.props.appStateStore.expandedScreen = this.props.screen.id
    }
  }

  clickedSetter = (expandedClass: string, expandedDiv: JSX.Element) => {
    if (this.state.expandedClass === expandedClass) {
      expandedClass = null
    }

    this.setState({
      expandedDiv,
      expandedClass
    })
  }

  scheduleClicked = () => {
    this.handleExpansionConflict()
    let scheduleElement = null

    if (
      !this.state.expandedDiv ||
      this.state.expandedDiv.type !== ScheduleControl
    ) {
      scheduleElement = (
        <ScheduleControl screen={this.props.screen} disabled={!this.canEdit} />
      )
    }

    this.clickedSetter('schedule', scheduleElement)
  }

  shareClicked = () => this.setState({isSharing: !this.state.isSharing})

  nicknameClicked = () => {
    if (!this.canEdit) {
      return console.log('lacks permissions to change nickname')
    }

    this.handleExpansionConflict()

    let nicknameElement = null
    if (
      !this.state.expandedDiv ||
      this.state.expandedDiv.type !== NicknameControl
    ) {
      nicknameElement = <NicknameControl screen={this.props.screen} />
    }

    this.clickedSetter('nickname', nicknameElement)
  }

  powerClicked = () => {
    if (!this.isOwner) {
      // console.log('lacks permission to power cycle')
      return
    }

    this.handleExpansionConflict()

    let powerElement = null
    if (
      !this.state.expandedDiv ||
      this.state.expandedDiv.type !== PowerControl
    ) {
      powerElement = (
        <PowerControl screen={this.props.screen} onHide={this.powerClicked} />
      )
    }

    this.clickedSetter('power', powerElement)
  }

  locationClicked = () => {
    this.handleExpansionConflict()
    let locationElement = null

    if (
      !this.state.expandedDiv ||
      this.state.expandedDiv.type !== LocationControl
    ) {
      locationElement = (
        <LocationControl screen={this.props.screen} disabled={!this.isOwner} />
      )
    }

    this.clickedSetter('location', locationElement)
  }

  stopMarqueeClicked = () => {
    this.handleExpansionConflict()

    let stopMarqElement = null

    if (
      !this.state.expandedDiv ||
      this.state.expandedDiv.type !== StopMarqueeControl
    ) {
      stopMarqElement = (
        <StopMarqueeControl
          screen={this.props.screen}
          onHide={this.stopMarqueeClicked}
        />
      )
    }

    this.clickedSetter('stop-marquee', stopMarqElement)
  }

  onShareChange = (results: User[], added: string[], removed: string[]) => {
    // ASSUMPTION: Users that are added are set to "viewer"
    const {screen} = this.props
    const newIDs = results.map(usr => usr.id)
    const promises = []
    const sIDusrIDregex = /.*(\d+)\/users\/(\d+)\/?$/

    added.forEach(usrid => promises.push(screen.shareTo(usrid, 'viewer')))

    removed.forEach(usrid => promises.push(screen.unshareTo(usrid)))

    Promise.all(promises)
      .then(responses => this.props.screen.sharedIDs.replace(newIDs))
      .catch(err => {
        console.error(`(Partial?) failure to update playlist share: "${err}"`)
      })
  }

  render() {
    let dynamicContainerClassName = 'single-screen-container'
    let dynamicScreenClassName = 'single-screen'
    let shadowCoverDiv = null

    if (this.state.expandedDiv) {
      dynamicContainerClassName += ` ${this.state.expandedClass}`
      dynamicScreenClassName += ' expanded'
      shadowCoverDiv = <div className="screen-shadow-cover" />
    }

    if (this.isOwner) {
      dynamicContainerClassName += ' is-owner'
    }

    if (this.canEdit) {
      dynamicContainerClassName += ' is-editor'
    }

    return (
      <div className={dynamicContainerClassName}>
        <div className={dynamicScreenClassName}>
          <div className={`screen-image ${this.statusClass}`}>
            {this.previewThumbnails}
          </div>

          {shadowCoverDiv}

          <Marquee
            className="screen-marquee"
            title={`Marquee expires  ${this.props.screen.marquee_expires}`}
          >
            {this.props.screen.marquee || null}
          </Marquee>

          <div className="right-half">
            <h4 className="screen-title">{this.props.screen.title}</h4>
            <p className="playlist-label">Current Playlist</p>
            <CurrentPlaylistSelect
              activePlaylistID={this.props.screen.activePlaylistID}
              options={this.props.playlistStore.myAndSharedPlaylists}
              disabled={!this.canEdit}
              screen={this.props.screen}
            />
            {this.createOrientationElement(this.props.screen.orientation)}
            <p className="location hidden-xs">{this.props.screen.location}</p>
            <p className="institution hidden-xs">
              {this.props.screen.institution}
            </p>
            <p className="department hidden-xs">
              {this.props.screen.department}
            </p>
          </div>

          <SVGButton
            tooltip="Share"
            onClick={this.shareClicked}
            svgIcon={<ShareIcon className="share-icon" />}
            active={this.state.expandedClass === 'share'}
            trigger={['hover']}
          />

          <SVGButton
            tooltip="Schedule"
            onClick={this.scheduleClicked}
            svgIcon={<ScheduleIcon className="calendar-icon" />}
            active={this.state.expandedClass === 'schedule'}
          />

          <SVGButton
            tooltip="Nickname"
            onClick={this.nicknameClicked}
            svgIcon={<NicknameIcon className="nickname-icon" />}
            active={this.state.expandedClass === 'nickname'}
            disabled={!this.canEdit}
          />

          <SVGButton
            tooltip="Power"
            onClick={this.powerClicked}
            svgIcon={<PowerIcon className="power-icon" />}
            active={this.state.expandedClass === 'power'}
            disabled={!this.isOwner}
          />

          <SVGButton
            tooltip="Map"
            onClick={this.locationClicked}
            svgIcon={<LocationIcon className="map-icon" />}
            active={this.state.expandedClass === 'location'}
          />

          <SVGButton
            tooltip="Stop Marquee"
            onClick={this.stopMarqueeClicked}
            svgIcon={<StopMarqueeIcon className="marquee-icon" />}
            active={this.state.expandedClass === 'stop-marquee'}
            disabled={!this.canEdit || !this.props.screen.marquee}
          />
        </div>

        {this.state.expandedDiv}

        <ShareModal
          showModal={this.state.isSharing}
          hideModal={this.shareClicked}
          target={this.props.screen}
          sortedSharedWith={this.props.screen.sharedWithSortedForDisplay}
          canShareOwner={false}
          disabled={!this.isOwner}
        />
      </div>
    )
  }
}

export default SingleScreen
