import * as React from 'react'
import * as ReactDOM from 'react-dom'
import {Provider} from 'mobx-react'
import {browserHistory, Router} from 'react-router'

import './sass/main.scss'

import routes from 'modules/routes'
import appStateStore from 'stores/appStateStore'
import userStore from 'stores/userStore'
import screenStore from 'stores/screenStore'
import contentStore from 'stores/contentStore'
import playlistStore from 'stores/playlistStore'
import showtimeStore from 'stores/showtimeStore'
import assetShowtimeStore from 'stores/assetShowtimeStore'
import notificationStore from 'stores/notificationStore'
import institutionStore from 'stores/institutionStore'
import broadcastStore from 'stores/broadcastStore'
import User from 'stores/User'
import Screen from 'stores/Screen'
import Content from 'stores/Asset'
import Playlist from 'stores/Playlist'
import Showtime from 'stores/Showtime'
import AssetShowtime from 'stores/AssetShowtime'
import alertStore from 'stores/alertStore'

interface Window {
  stores: {[key: string]: object}
  models: {[key: string]: object}
}
declare const window: Window

const stores = {
  alertStore,
  appStateStore,
  assetShowtimeStore,
  broadcastStore,
  contentStore,
  institutionStore,
  notificationStore,
  playlistStore,
  screenStore,
  showtimeStore,
  userStore
}

if (process.env.MB_ENV !== 'production') {
  window.stores = {...stores}

  window.models = {
    AssetShowtime,
    Content,
    Playlist,
    Screen,
    Showtime,
    User
  }
}

ReactDOM.render(
  <Provider {...stores}>
    <Router routes={routes} history={browserHistory} />
  </Provider>,
  document.getElementById('content')
)
