import * as React from 'react'
import {Col, Grid, Row} from 'react-bootstrap'

import HelpImageMap, {HelpContent} from './HelpImageMap'

const screensImage = require('images/urls/help-screens.png')
const marqueeImage = require('images/urls/marquee.png')
const broadcastImage = require('images/urls/broadcast.png')
const shareImage = require('images/urls/share-with.png')
const nicknameImage = require('images/urls/change-nickname.png')
const powerImage = require('images/urls/reboot.png')
const mapsImage = require('images/urls/change-location.png')
const scheduleImage = require('images/urls/schedule.png')
const editShowTimeImage = require('images/urls/edit-show-time.png')
const stopMarqueeImage = require('images/urls/stop-marquee.png')

const marquee: HelpContent = {
  title: 'Marquee - Add Scrolling Text to a Screen',
  body: (
    <div>
      <img className="marquee-image" src={marqueeImage} />
      <ol className="">
        <li>Select which signs to display this Marquee on.</li>
        <li>Input the message to be displayed.</li>
        <li>Enter the duration the marquee should be displayed.</li>
        <li>Confirm creation of Marquee.</li>
      </ol>
    </div>
  ),
  coordinates: {
    left: 2,
    top: 3,
    width: 12,
    height: 38
  }
}

const broadcast: HelpContent = {
  title: 'Broadcast - Display a Message on All Screens in an Area',
  body: (
    <Grid>
      <Row>
        <Col md={6}>
          <img className="broadcast-image" src={broadcastImage} />
        </Col>
        <Col md={6}>
          <ol className="">
            <li>Enter a message to Broadcast.</li>
            <li>
              Click on the map to begin drawing a line. Click again to create
              the first line. Continue drawing lines to create your broadcast
              region. Finish by clicking the first dot created.
            </li>
            <li>Click the X on the top right of the map to start over.</li>
            <li>Confirm creation of the broadcast.</li>
          </ol>
        </Col>
      </Row>
    </Grid>
  ),
  coordinates: {
    left: 1,
    top: 44,
    width: 14,
    height: 37
  }
}

const schedule: HelpContent = {
  title: 'Edit Schedule - Set Weekly Playlist for a Screen',
  body: (
    <div>
      <img className="schedule-image" src={scheduleImage} />
      <ul className="">
        <li>You may use up to 3 playlists.</li>
        <li>Click the timeline to add more periods to the timeline.</li>
        <li>
          Click the corresponding colored bar to edit a playlist's show time.
        </li>
      </ul>
      <br />
      <img className="showtime-image" src={editShowTimeImage} />
      <ul className="">
        <li>
          <strong>Starting Day</strong> - Day of the week that the period
          starts.
        </li>
        <li>
          <strong>Starting Time</strong> - The time of day that the period
          starts.
        </li>
        <li>
          <strong>Duration</strong> - Time in hours the period should last.
        </li>
      </ul>
    </div>
  ),
  coordinates: {
    left: 92.5,
    top: 10,
    width: 5,
    height: 12
  }
}

const share: HelpContent = {
  title: 'Share Screen - Allow Others to Modify Screen Settings',
  body: (
    <div>
      <img className="sharescreen-image" src={shareImage} />
      <ol className="">
        <li>Begin typing a name in the text box to view suggestions.</li>
        <li>Click a name to select the user.</li>
        <li>Click the icon next to the text box to select user permissions.</li>
        <li>Click 'ADD' to share the screen with the users.</li>
        <ul className="sub-list">
          <li>
            Viewers may only view screen information, such as name, current
            playlist, etc.
          </li>
          <li>Editors may edit a screen's schedule and nickname. </li>
          <li>Owners may edit, power off or restart, and share a screen. </li>
        </ul>
      </ol>
      <ul>
        <li>
          Use the dropdown next to a user to change user's permission levels.
        </li>
        <li>Select the X to un-share the screen with a user.</li>
      </ul>
    </div>
  ),
  coordinates: {
    left: 92.5,
    top: 24,
    width: 5,
    height: 12
  }
}

const nickname: HelpContent = {
  title: 'Change Nickname - Change Screen Name',
  body: (
    <div>
      <img className="nickname-image" src={nicknameImage} />
      <br />
      <span>Enter a new name, and confirm the new sign name.</span>
    </div>
  ),
  coordinates: {
    left: 92.5,
    top: 38,
    width: 5,
    height: 12
  }
}

const power: HelpContent = {
  title: 'Reboot Screen - Instruct a Screen to Restart',
  body: (
    <div>
      <img className="power-image" src={powerImage} />
      <br />
      <span>
        Click "Reboot" to restart a screen. Click "Nevermind" to cancel the
        action.
      </span>
    </div>
  ),
  coordinates: {
    left: 92.5,
    top: 52,
    width: 5,
    height: 12
  }
}

const map: HelpContent = {
  title: 'Screen Location - Edit the Physical Location of a Screen',
  body: (
    <div>
      <img className="power-image" src={mapsImage} />
      <br />
      <span>
        Click on the map to set screen location. Enter a friendly custom
        location name to be displayed. Click "Save" to save changes.
      </span>
    </div>
  ),
  coordinates: {
    left: 92.5,
    top: 67,
    width: 5,
    height: 12
  }
}

const stopMarquee: HelpContent = {
  title: 'Stop Marquee - Stop a Marquee',
  body: (
    <div>
      <img className="power-image" src={stopMarqueeImage} />
      <br />
      <span>
        Click the 'Stop Marquee' button to cancel a currently running marquee.
      </span>
    </div>
  ),
  coordinates: {
    left: 92.5,
    top: 81,
    width: 5,
    height: 12
  }
}

const imageMap: HelpContent[] = [
  marquee,
  broadcast,
  schedule,
  share,
  nickname,
  power,
  map,
  stopMarquee
]

const HelpScreens: React.StatelessComponent = () => (
  <div className="help-screens">
    <div className="help-content" id="help-screens-content">
      <Grid className="help-grid">
        <Row>
          <h1>Screens</h1>
          <h4>Click on a button to learn more about a function.</h4>
          <p>
            Screens are the physical hardware that display asets. Each card on
            the screens page represents a different screen.
          </p>
        </Row>
        <Row>
          <HelpImageMap image={screensImage} content={imageMap} />
        </Row>
      </Grid>
    </div>
  </div>
)

export default HelpScreens
