import * as React from 'react'

import Footer from './Footer'

const Screen32 = require('images/elements/screen-32.svg')
const Screen48 = require('images/elements/screen-48.svg')
const Screen55 = require('images/elements/screen-55.svg')

const Get: React.StatelessComponent = () => (
  <div id="get-minerbytes">
    <div id="billboard">
      <div className="center">
        <h1>
          move forward with <b>miner</b>bytes.
        </h1>
      </div>
    </div>
    <div id="faqs">
      <div className="width-wrapper">
        <h1>faqs</h1>
        <p>
          <b>Q:</b>
          <span> Can I run commercial advertisements on my sign? </span>
          <br />
          <b>A:</b> No! You are welcome, however, to use your sign to thank your
          sponsors.
        </p>
        <p>
          <b>Q:</b>
          <span> Do I have to make my own content for my MinerBytes sign?</span>
          <br />
          <b>A:</b> Yes and no. While you are in charge of the what content is
          shared on your screen, if you aren't comfortable creating assets we
          encourage you to check out our new Content Store to find free content
          to share. If you ever have a question regarding your assets feel free
          to reach out to the MinerBytes team.
        </p>
        <p>
          <b>Q:</b>
          <span> I think something is wrong with my MinerBytes sign.</span>
          <br />
          <b>A:</b> Well, that's not really a question, but if you believe there
          is a problem with your sign reach out to the MinerBytes team and we'll
          resolve it. Start by going to help.mst.edu.
        </p>
        <p>
          <b>Q:</b>
          <span> I already have a display. Can I use MinerBytes on it? </span>
          <br />
          <b>A:</b> Possibly. The MinerBytes team have high standards for the
          look of MinerBytes signs. For that reason, MinerBytes runs on
          carefully selected commercial displays. Displays purchased outside of
          the MinerBytes program will have to meet the Display Recertification
          Requirements and receive approval from the MinerBytes team in order to
          be converted to use MinerBytes.
        </p>
        <p>
          <b>Q:</b>
          <span> How much does MinerBytes cost? </span>
          <br />
          <b>A:</b> Less than you might think! MinerBytes leases are on a 3-year
          cycle. For the 3 year cycle, a new 32" sign will cost $1320, a 48" is
          $1625, and a 55" $2005. Additional costs that are not covered by these
          prices are things like having the wall mount attached to the wall,
          bringing power and networking to the location of the sign, and if
          desired, a mobile stand for the sign.
        </p>
        <p>
          <b>Q:</b>
          <span> What happens after my 3 year lease is over? </span>
          <br />
          <b>A:</b> After the 3 year lease, there are a couple of options. The
          MinerBytes agreement can be renewed for another 3 years at full price
          to replace the display, brain box, and a new 3 year lease.
          Alternatively, after your initial 3 year lease has expired, if the
          display is still in good working order and meets the Display
          Recertification criteria, you can renew your MinerBytes lease on a per
          year basis for $200 the first year, $300 for the second, and $400 for
          the third. After a MinerBytes sign has been in service for the maximum
          of 6 years, it will be retired and ineligible for a lease extension.
        </p>
      </div>
    </div>
    <div id="pricing">
      <div className="width-wrapper">
        <h1>pricing</h1>
        <p>
          MinerBytes is designed for a supported, cohesive, and expandable
          experience. MinerBytes and all supporting hardware will be provided
          and installed by ITRSS technicians with ongoing support to ensure your
          system meets your needs. This includes a commercial-grade screen for
          superior image quality and longevity as well as a micro-computer to
          drive it.
        </p>
        <div id="example">
          <div className="row">
            <div className="col-sm-4 text-align">
              <div className="price-wrapper">
                <Screen32 id="screen32" />
                <h1>$1320</h1>
              </div>
            </div>
            <div className="col-sm-4 text-align">
              <div className="price-wrapper">
                <Screen48 id="screen48" />
                <h1>$1625</h1>
              </div>
            </div>
            <div className="col-sm-4 text-align">
              <div className="price-wrapper">
                <Screen55 id="screen55" />
                <h1>$2005</h1>
              </div>
            </div>
          </div>
          <p className="text-center">
            * Displays are priced for a 3 year lease term.
          </p>
        </div>
        <p>
          Prices include the display, brain box, cables, software, and mount and
          are subject to change. Software licensing for MinerBytes is $150
          annually per sign and includes one license per sign purchased.
        </p>
      </div>
    </div>
    <div id="contact-us">
      <div className="row width-wrapper">
        <div className="col-sm-6">
          <h1>ready to take the next step?</h1>
        </div>
        <div className="col-sm-6" id="contact-center">
          <p>IT Research Support Services</p>

          <p>
            300 West 12th Street<br />
            Centennial Hall - Room 102B/A <br />
            Rolla, MO 65409
          </p>

          <p>(573) 341-4758</p>
          <p>
            <a href="mailto:minerbytes@mst.edu">minerbytes@mst.edu</a>
          </p>
        </div>
      </div>
    </div>
    <Footer />
  </div>
)

export default Get
