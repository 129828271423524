import * as React from 'react'

import {RealUserStore} from 'modules/admin/adminstores'
import {UserDeleteTabClass} from '../BaseDeleteTab'

const UserDeleteTab: React.StatelessComponent = () => (
  <UserDeleteTabClass typeName="user" adminStore={RealUserStore} />
)

export default UserDeleteTab
