import * as React from 'react'
import {IObservableArray, ObservableMap} from 'mobx'
import {observer} from 'mobx-react'

import Playlist from 'stores/Playlist'
import Showtime from 'stores/Showtime'
import Screen from 'stores/Screen'
import DrawPlaylist from './DrawPlaylist'

interface Props {
  editShowtime: (showtime) => void
  screen: Screen
  playlists: IObservableArray<Playlist>
  showtimes: ObservableMap<ObservableMap<Showtime>>
  disabled: boolean
}

@observer
class ScheduleDrawContainer extends React.Component<Props> {
  render() {
    return (
      <div className="schedcon-draw-container">
        <DrawPlaylist
          editShowtime={this.props.editShowtime}
          screen={this.props.screen}
          playlists={this.props.playlists}
          showtimes={this.props.showtimes}
          disabled={this.props.disabled}
          selectKey={0}
        />
        <DrawPlaylist
          editShowtime={this.props.editShowtime}
          screen={this.props.screen}
          playlists={this.props.playlists}
          showtimes={this.props.showtimes}
          disabled={this.props.disabled}
          selectKey={1}
        />
        <DrawPlaylist
          editShowtime={this.props.editShowtime}
          screen={this.props.screen}
          playlists={this.props.playlists}
          showtimes={this.props.showtimes}
          disabled={this.props.disabled}
          selectKey={2}
        />
      </div>
    )
  }
}

export default ScheduleDrawContainer
