import * as React from 'react'
import {AxiosResponse} from 'axios'
import {observer} from 'mobx-react'
import Async from 'react-select/lib/Async'

import {RealUser} from 'api/realsources'
import {RealUserStore} from './adminstores'

interface Props {
  value: RealUser
  onChange: (newValue: RealUser) => void
  id?: string // default: OwnerSelect
  disabled?: boolean // default: false
  title?: string // default: null
  name?: string // default: "owner"
  resultLimit?: number // default: 5
}

@observer
class OwnerSelect extends React.Component<Props> {
  static defaultProps = {
    disabled: false,
    title: null,
    name: 'owner',
    resultLimit: 5
  }

  loadOptions = (input: string) => {
    input = input.trim()
    if (!input) {
      return new Promise((res, rej) => res(null))
    }

    return RealUserStore.search(input)
      .then((response: AxiosResponse) =>
        response.data.slice(0, this.props.resultLimit)
      )
      .then(resourceArray => {
        if (this.props.value) {
          resourceArray.push(this.props.value)
        }

        return resourceArray
      })
  }

  render() {
    return (
      <Async
        {...this.props}
        getOptionLabel={opt => opt.name}
        getOptionValue={opt => opt.name}
        isMulti={false}
        blurInputOnSelect={true}
        loadOptions={input => this.loadOptions(input)}
        defaultOptions={[]}
        cacheOptions={true}
      />
    )
  }
}

export default OwnerSelect
