import * as React from 'react'
import {observer} from 'mobx-react'
import {Dropdown, Glyphicon, MenuItem} from 'react-bootstrap'

import {PermissionLevel} from 'globals'
import User from 'stores/User'

const Close = require('images/elements/close-x.svg')
const OwnerIcon = require('images/elements/owner-icon.svg')

interface Props {
  user: User
  permission: PermissionLevel
  permissionLevels: PermissionLevel[]
  onPermissionSelect: (permission: PermissionLevel, user: User) => void
  removeUser: (user: User) => Promise<void>
  disabled: boolean
}

const UserRow = observer((props: Props) => {
  const getPermOption = (permLevel: PermissionLevel) => {
    let icon: JSX.Element = null
    switch (permLevel) {
      case 'viewer':
        icon = (
          <Glyphicon
            className="permission-selector-icon"
            glyph="eye-open"
            title="viewer"
          />
        )
        break
      case 'editor':
        icon = (
          <Glyphicon
            className="permission-selector-icon"
            glyph="pencil"
            title="editor"
          />
        )
        break
      case 'creator':
      case 'owner':
        icon = (
          <OwnerIcon className="permission-selector-icon" title={permLevel} />
        )
    }

    return (
      <div className="permission-selector-option">
        {icon} {permLevel}
      </div>
    )
  }

  const onPermissionSelect = (eventKey: any) =>
    props.onPermissionSelect(props.permissionLevels[eventKey], props.user)

  return (
    <tr className="user-row">
      <td className="name-td">{props.user.name}</td>
      <td className="selector-td">
        <Dropdown
          className="permission-selector"
          id={`${props.user.id}-perm-selector`}
          disabled={props.disabled}
          onSelect={onPermissionSelect}
        >
          <Dropdown.Toggle>{getPermOption(props.permission)}</Dropdown.Toggle>
          <Dropdown.Menu data-container="body">
            {props.permissionLevels.map(
              (permLevel, index) =>
                permLevel !== props.permission && (
                  <MenuItem
                    key={permLevel}
                    eventKey={index}
                    active={props.permission === permLevel}
                    title={permLevel}
                  >
                    {getPermOption(permLevel)}
                  </MenuItem>
                )
            )}
          </Dropdown.Menu>
        </Dropdown>
      </td>
      <td>
        <Close
          className={`unshare-btn${props.disabled ? ' disabled' : ''}`}
          onClick={() =>
            !props.disabled ? props.removeUser(props.user) : null
          }
        />
      </td>
    </tr>
  )
})

export default UserRow
