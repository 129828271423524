import {computed, observable, ObservableMap} from 'mobx'

import API from 'api'
import Institution from './Institution'

class InstitutionStore {
  @observable institutionMap: ObservableMap<Institution> = observable.map()

  fetchInProgress: Set<string> = new Set()

  @computed
  get institutions(): Institution[] {
    return this.institutionMap.values()
  }

  /* Add/update institution to the store */
  add = (inst: Institution): void => {
    this.institutionMap.set(inst.id, inst)
  }

  /* Remove institution from the store */
  remove = (inst: Institution): void => {
    this.institutionMap.delete(inst.id)
  }

  /* Return the corresponding institution from the store, or request from the
      backend and return null */
  findById = (id: string): Institution | null => {
    if (!id) throw new Error(`[InstitutionStore.findById] Invalid id: ${id}`)

    const item = this.institutionMap.get(id)
    if (item) return item
    else {
      if (!this.fetchInProgress.has(id)) {
        this.fetchInProgress.add(id)
        this.fetchByID(id)
      }
      return null
    }
  }

  fetchByID = (id: string | number): Promise<Institution> => {
    if (!id) throw new Error(`[InstitutionStore.fetchByID] Invalid id: ${id}`)

    return API.institutions
      .get(id)
      .then(data => {
        const inst = Institution.fromResource(data)
        this.add(inst)
        this.fetchInProgress.delete(inst.id)
        return inst
      })
      .catch(data => {
        this.fetchInProgress.delete(id.toString())
        console.error(`InstitutionStore.fetchByID( ${id} ) failed:`, data)
        return null
      })
  }
}

export default new InstitutionStore()
