import * as React from 'react'
import {observer} from 'mobx-react'
import {FormControl, FormGroup} from 'react-bootstrap'

import AssetShowtime from 'stores/AssetShowtime'

interface Props {
  showtime: AssetShowtime
}

const DATE_RE = /^[0-9]{4}(-|\/)(0[1-9]|1[0-2])(-|\/)(0[1-9]|[1-2][0-9]|3[0-1])$/
const TIME_RE = /^([0-1][0-9]|2[0-4]):([0-5][0-9]):([0-5][0-9])$/

@observer
class AssetScheduler extends React.Component<Props> {
  toggleDay = day => {
    this.props.showtime.toggleDay(day.day)
  }

  handleTimeStartChange = event => {
    this.props.showtime.timeStart = event.target.value
  }

  handleTimeStopChange = event => {
    this.props.showtime.timeStop = event.target.value
  }

  handleDateStartChange = event => {
    this.props.showtime.dateStart = event.target.value
  }

  handleDateStopChange = event => {
    this.props.showtime.dateStop = event.target.value
  }

  handleDateBlur = (e: React.FocusEvent<any>) => {
    const value = e.target.value
    e.target.setCustomValidity(
      DATE_RE.test(value)
        ? ''
        : 'Invalid Date, use YYYY-MM-DD format'
    )
  }

  handleTimeBlur = (e: React.FocusEvent<any>) => {
    const value = e.target.value
    e.target.setCustomValidity(
      TIME_RE.test(value)
        ? ''
        : 'Invalid time, use 24-hour hh:mm:ss format'
    )
  }

  componentWillUnmount() {
    this.props.showtime.update()
  }

  render() {
    return (
      <div className="asset-scheduler">
        <h5>Asset Scheduler</h5>
        <h6>Display Time Range</h6>
        <FormGroup className="time-inputs">
          <FormControl
            type="text"
            placeholder="00:00:00"
            pattern={TIME_RE.source}
            value={this.props.showtime.timeStart}
            onChange={this.handleTimeStartChange}
            onBlur={this.handleTimeBlur}
          />
          <span className="to">to</span>
          <FormControl
            type="text"
            placeholder="23:59:59"
            pattern={TIME_RE.source}
            value={this.props.showtime.timeStop}
            onChange={this.handleTimeStopChange}
            onBlur={this.handleTimeBlur}
          />
        </FormGroup>

        <h6>Display Days</h6>

        <div className="weekdays">
          {this.props.showtime.daysOfWeek.map((day, i) => (
            <div
              key={i}
              className={'day-tile' + (day.active ? ' selected' : '')}
              onClick={() => this.toggleDay(day)}
            >
              {day.day}
            </div>
          ))}
        </div>

        <h6>Display Date Range</h6>

        <FormGroup className="date-inputs">
          <FormControl
            type="text"
            placeholder="2000-01-01"
            pattern={DATE_RE.source}
            value={this.props.showtime.dateStart}
            onChange={this.handleDateStartChange}
            onBlur={this.handleDateBlur}

          />
          <span className="to">to</span>
          <FormControl
            type="text"
            placeholder="2075-01-01"
            pattern={DATE_RE.source}
            value={this.props.showtime.dateStop}
            onChange={this.handleDateStopChange}
            onBlur={this.handleDateBlur}
          />
        </FormGroup>
      </div>
    )
  }
}

export default AssetScheduler
