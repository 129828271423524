import * as React from 'react'

interface Props {
  className: string
  title: string
}

interface State {
  innerStyle: object
}

const PIXELS_PER_SEC = 30

class Marquee extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      innerStyle: {}
    }
  }

  componentDidMount() {
    if (this.refs.inner) {
      const duration = (this.refs.inner as any).offsetWidth / PIXELS_PER_SEC
      this.setState({
        innerStyle: {
          animationDuration: `${duration}s`
        }
      })
    }
  }

  render() {
    return (
      <span
        title={this.props.title}
        className={'marquee-text ' + this.props.className}
      >
        {this.props.children && (
          <span
            ref="inner"
            className="marquee-inner"
            style={this.state.innerStyle}
          >
            {this.props.children}
          </span>
        )}
      </span>
    )
  }
}

export default Marquee
