import * as React from 'react'
import {Button, Modal, OverlayTrigger, Tooltip} from 'react-bootstrap'

/* Coordinates are a percentages based on image.
   Use Chrome devtools to find the percentages.  */

interface Coordinates {
  left: number
  top: number
  width: number
  height: number
}

export interface HelpContent {
  title: string
  body: JSX.Element
  coordinates: Coordinates
}

interface Props {
  image: string
  content: HelpContent[]
}

interface State {
  showModal: boolean
  modalTitle: string
  modalBody: JSX.Element
}

class HelpImageMap extends React.Component<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      showModal: false,
      modalTitle: null,
      modalBody: null
    }
  }

  closeModal = () => {
    this.setState({showModal: false})
  }

  openModal = (title: string, body: JSX.Element) => {
    this.setState({
      modalTitle: title,
      modalBody: body,
      showModal: true
    })
  }

  createTooltip = (title: string) => {
    return (
      <Tooltip id="tooltip">
        {title}
        <br />
        <em>Click to Learn More</em>
      </Tooltip>
    )
  }

  computeStyle = (coords: Coordinates) => {
    return {
      left: coords.left + '%',
      top: coords.top + '%',
      width: coords.width + '%',
      height: coords.height + '%'
    }
  }

  render() {
    return (
      <div>
        <div className="help-image">
          <img src={this.props.image} />
          {this.props.content.map((area, index) => (
            <OverlayTrigger
              key={index}
              placement="right"
              overlay={this.createTooltip(area.title)}
            >
              <div
                className="mapArea"
                style={this.computeStyle(area.coordinates)}
                onClick={() => this.openModal(area.title, area.body)}
              />
            </OverlayTrigger>
          ))}
        </div>

        <Modal
          bsSize="large"
          show={this.state.showModal}
          onHide={this.closeModal}
          className="help-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>{this.state.modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.state.modalBody}</Modal.Body>
          <Modal.Footer>
            <Button onClick={this.closeModal}>Close</Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

export default HelpImageMap
