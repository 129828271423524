import {computed, observable, ObservableMap} from 'mobx'

import API from 'api'
import {UserResource, UserResource_Partial} from 'api/resources'
import User from './User'

export class UserStore {
  @observable usersMap: ObservableMap<User> = observable.map()
  @observable currentUserId: string = null

  fetchInProgress: Map<string, Promise<User>> = new Map()

  @computed
  get users(): User[] {
    return this.usersMap.values()
  }

  @computed
  get currentUser(): User | null {
    return this.currentUserId ? this.findById(this.currentUserId) : null
  }

  /* Add/update user to the store */
  add = (user: User): void => {
    this.usersMap.set(user.id, user)
  }

  /* Add IFF user not already in the store. Returns true if new. */
  addIfNew = (user: User): boolean => {
    if (this.usersMap.has(user.id)) return false
    else {
      this.add(user)
      return true
    }
  }

  /* Remove user from the store */
  remove = (user: User): void => {
    this.usersMap.delete(user.id)
  }

  /* Return the corresponding user from the store, or request from the
      backend and return null */
  findById = (id: string): User | null => {
    if (!id) throw new Error(`[UserStore.findById] Invalid id: ${id}`)

    const item = this.usersMap.get(id)
    if (item) return item
    else {
      if (!this.fetchInProgress.has(id)) {
        this.fetchInProgress.set(id, this.fetchByID(id))
      }
      return null
    }
  }

  userReducer = (map: {}, user: User) => {
    map[user.id] = user
    return map
  }

  /* Return the corresponding User through a promise, either from the store
      or from the backend. */
  fetch = (id: string): Promise<User> => {
    if (!id) throw new Error(`[UserStore.fetch] Invalid id: ${id}`)

    return new Promise((resolve, reject) => {
      const user = this.usersMap.get(id)

      if (user) return resolve(user)

      if (this.fetchInProgress.has(id)) {
        // return already-promised promise
        return this.fetchInProgress.get(id)
      }

      const prom = API.users
        .get(id)
        .then(resource => {
          const apiUser = User.fromResource(resource)
          this.add(apiUser)
          this.fetchInProgress.delete(id)
          return apiUser
        })
        .then(resolve)
        .catch(arg => {
          this.fetchInProgress.delete(id)
          reject(arg)
          return null
        })

      this.fetchInProgress.set(id, prom)
      return prom
    })
  }

  /* Fetches without asking questions */
  fetchByID = (userID: string | number): Promise<User> => {
    if (!userID) throw new Error(`[UserStore.fetchByID] Invalid id: ${userID}`)

    return API.users
      .get(userID)
      .then(data => {
        const usr = User.fromResource(data)
        this.add(usr)
        this.fetchInProgress.delete(usr.id)
        return usr
      })
      .catch(data => {
        this.fetchInProgress.delete(userID.toString())
        console.error(`UserStore.fetchByID( ${userID} ) failed:`, data)
        return null
      })
  }

  fetchAll = (): Promise<User[]> => {
    return API.users
      .index()
      .then(data => data.map(User.fromResource))
      .then(users => {
        this.usersMap.replace(users.reduce(this.userReducer, {}))
        return this.users
      })
  }

  get fakeUser(): User {
    const qqq = '???'
    const U = new User({
      id: '0',
      alert_priv: 1,
      fname: qqq,
      lname: qqq,
      username: qqq,
      email: qqq,
      active: false,
      imageURL: null,
      isAdmin: false,
      institutionID: '0',
      department: qqq,
      sysAdmin: qqq,
      touch_dirs: null
    })
    Object.defineProperty(U, 'institution', {value: {name: qqq}})
    return U
  }

  update = (partial: UserResource_Partial): Promise<User> => {
    return API.users.update(partial).then(res => {
      if (res.data && res.data.status === 'success') {
        const userR = res.data.data as UserResource
        const user = User.fromResource(userR)
        const localUser = this.usersMap.get(user.id)
        localUser.update(userR)
        return user
      } else {
        throw res
      }
    })
  }

  search = (query: string): Promise<User[]> => {
    return API.users
      .search(query)
      .then(res => {
        return res.data.map(userR => {
          const user = User.fromResource(userR)
          this.addIfNew(user)
          return user
        })
      })
      .catch(err => {
        console.error(err)
        throw new Error(`Failed to search user: ${query}`)
      })
  }
}

export default new UserStore()
