import * as React from 'react'
import {IndexLinkContainer, LinkContainer} from 'react-router-bootstrap'
import {Grid, Nav, Navbar, NavItem} from 'react-bootstrap'

const HelpNav: React.StatelessComponent = props => (
  <div className="help-page">
    <Grid className="help-grid">
      <h1 className="help-title">Help</h1>
    </Grid>
    <Navbar>
      <Nav bsStyle="pills" className="help-nav">
        <IndexLinkContainer to="/help">
          <NavItem>Home</NavItem>
        </IndexLinkContainer>
        <LinkContainer to="/help/screens">
          <NavItem>Screens</NavItem>
        </LinkContainer>
        <LinkContainer to="/help/playlists">
          <NavItem>Playlists</NavItem>
        </LinkContainer>
        <LinkContainer to="/help/assets">
          <NavItem>Content</NavItem>
        </LinkContainer>
        <LinkContainer to="/help/wifi">
          <NavItem>WifiConnect</NavItem>
        </LinkContainer>
      </Nav>
    </Navbar>
    <div id="help-container">{props.children}</div>
  </div>
)

export default HelpNav
