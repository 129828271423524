import * as React from 'react'
import {computed} from 'mobx'
import {inject, observer} from 'mobx-react'
import * as Dotdotdot from 'react-dotdotdot'
import LazyLoad from 'react-lazyload'

import {PlaylistOption} from 'globals'
import Asset from 'stores/Asset'
import {UserStore} from 'stores/userStore'
import {PlaylistStore} from 'stores/playlistStore'
import {AppStateStore} from 'stores/appStateStore'
import AssetPreviewModal from './modals/AssetPreview'
import EditAssetModal from './modals/EditAsset'
import LazyPlaceholder from 'modules/common/LazyPlaceholder'

const FavoriteIcon = require('images/elements/favorites-icon.svg')
const VerticalIcon = require('images/elements/vertical-icon.svg')
const HorizontalIcon = require('images/elements/horizontal-icon.svg')
const Close = require('images/elements/close-x.svg')
const ImageIcon = require('images/elements/image-icon.svg')
const VideoIcon = require('images/elements/video-icon.svg')
const WebIcon = require('images/elements/web-icon.svg')
const CheckMark = require('images/elements/checkmark.svg')
const NoImageIcon = require('images/urls/no-image-icon.svg')
const VideoUploadingIcon = require('images/urls/video-uploading-icon.svg')

export interface Props {
  asset: Asset
  playlists: PlaylistOption[]
  userStore?: UserStore
  playlistStore?: PlaylistStore
  appStateStore?: AppStateStore
}

interface State {
  viewingAddTo?: boolean
  viewingDetails?: boolean
  editingAsset: boolean
  previewIndex: number
  showingPreview: boolean
  imageCSS: React.CSSProperties
  ownerName: string
}

@inject('userStore', 'playlistStore', 'appStateStore')
@observer
class ContentCard extends React.Component<Props, State> {
  @computed
  get thumbnail() {
    const asset = this.props.asset
    if (asset.tpath) {
      return asset.tpath
    }

    if (asset.assetType === 'IMAGE') {
      if (asset.verticalURL) {
        return asset.verticalURL
      } else if (asset.horizontalURL) {
        return asset.horizontalURL
      }
    }

    return null
  }

  @computed
  get verticalURL() {
    const {verticalURL} = this.props.asset
    return this.webURL || verticalURL || null
  }

  @computed
  get horizontalURL() {
    return this.props.asset.horizontalURL || null
  }

  @computed
  get webURL() {
    const {webURLData, webFileURL} = this.props.asset
    return webFileURL || webURLData || null
  }

  @computed
  get noticeOverlay() {
    if (!this.thumbnail && this.props.asset.youtubeID)
      return (
        <div className="notice-overlay">
          <span className="notice-overlay-top">Rendering video&hellip;</span>
          <span className="notice-overlay-bottom">
            Please check back later.
          </span>
        </div>
      )
  }

  constructor(props: Props) {
    super(props)

    this.state = {
      viewingAddTo: null,
      viewingDetails: null,
      previewIndex: this.defaultPreviewIndex(),
      showingPreview: false,
      editingAsset: false,
      imageCSS: {},
      ownerName: null
    }
  }

  loadImage = () => {
    if (this.props.asset && this.thumbnail === null) {
      const icon = this.props.asset.youtubeID ? VideoUploadingIcon : NoImageIcon
      this.setState({
        imageCSS: {
          backgroundImage: `url(${icon})`,
          backgroundSize: this.props.asset.youtubeID ? '60%' : '70%',
          backgroundPositionY: this.props.asset.youtubeID ? '54px' : 'center'
        }
      })
    } else {
      this.setState({
        imageCSS: {
          backgroundImage: 'url(' + this.thumbnail + ')'
        }
      })
    }
  }

  componentDidMount() {
    this.loadImage()
  }

  showEdit = () => {
    this.setState({
      editingAsset: true
    })
  }

  stopEditing = () => {
    this.setState({
      editingAsset: false
    })
  }

  showAddTo = () => {
    let newShowAddTo = true
    if (this.state.viewingAddTo != null && this.state.viewingAddTo) {
      newShowAddTo = false
    }

    this.setState({
      viewingAddTo: newShowAddTo,
      viewingDetails: null,
      previewIndex: this.defaultPreviewIndex()
    })
  }

  showDetails = () => {
    let newShowDetails = true
    if (this.state.viewingDetails != null && this.state.viewingDetails) {
      newShowDetails = false
    }

    this.setState({
      viewingAddTo: null,
      viewingDetails: newShowDetails,
      previewIndex: this.defaultPreviewIndex()
    })
  }

  showPreview = () => {
    this.setState({
      showingPreview: true
    })
  }

  closePreview = () => {
    this.setState({
      showingPreview: false
    })
  }

  handlePreviewSelect = selectedIndex => {
    this.setState({
      previewIndex: selectedIndex
    })
  }

  getAddToOptions = () => {
    const addToList = this.props.playlistStore.editablePlaylists.map(
      (playlist, i) => (
        <li
          className="playlist-option"
          key={i}
          onClick={() => playlist.toggleAsset(this.props.asset)}
        >
          {playlist.hasAsset(this.props.asset) && (
            <CheckMark className="checkmark" />
          )}
          {playlist.title}
        </li>
      )
    )

    addToList.unshift(
      <li
        className="playlist-option"
        key={addToList.length}
        onClick={() => this.props.asset.toggleFavorite()}
      >
        {this.props.asset.isFavorite && <CheckMark className="checkmark" />}
        Favorites
      </li>
    )

    return addToList
  }

  isMultiSelected = () => {
    return this.props.appStateStore.isMultiSelected(this.props.asset)
  }

  defaultPreviewIndex = () => {
    return this.verticalURL ? 0 : 1
  }

  render() {
    let orientationIcon = null
    if (this.props.asset.orientation === 'vertical') {
      orientationIcon = <VerticalIcon className="orientation-icon vertical" />
    } else if (this.props.asset.orientation === 'horizontal') {
      orientationIcon = (
        <HorizontalIcon className="orientation-icon horizontal" />
      )
    }

    const mainIcons = <div className="asset-icons">{orientationIcon}</div>

    let assetTypeIndicator = null
    switch (this.props.asset.assetType) {
      case 'VIDEO':
        assetTypeIndicator = (
          <div className="value-group asset-type">
            <VideoIcon className="type-icon type-icon-video" />
            <p className="value type-text">video</p>
          </div>
        )
        break
      case 'WEB':
        assetTypeIndicator = (
          <div className="value-group asset-type">
            <WebIcon className="type-icon type-icon-web" />
            <p className="value type-text">web</p>
          </div>
        )
        break
      case 'IMAGE':
        assetTypeIndicator = (
          <div className="value-group asset-type">
            <ImageIcon className="type-icon type-icon-image" />
            <p className="value type-text">image</p>
          </div>
        )
        break
    }

    let orientationDescription = null
    if (this.props.asset.orientation === 'vertical') {
      orientationDescription = (
        <div className="value-group orientation-limiter">
          <VerticalIcon className="orientation-symbol vertical" />
          <p className="orientation-text">vertical displays only</p>
        </div>
      )
    } else if (this.props.asset.orientation === 'horizontal') {
      orientationDescription = (
        <div className="value-group orientation-limiter">
          <HorizontalIcon className="orientation-symbol horizontal" />
          <p className="orientation-text">horizontal displays only</p>
        </div>
      )
    }

    const canEdit =
      this.props.asset.ownerID === this.props.userStore.currentUser.id

    let addToActive = ''
    if (this.state.viewingAddTo != null) {
      addToActive = this.state.viewingAddTo ? ' active' : ' inactive'
    }

    let detailsActive = ''
    if (this.state.viewingDetails != null) {
      detailsActive = this.state.viewingDetails ? ' active' : ' inactive'
    }

    let multiSelectorDiv = null
    if (this.props.appStateStore.multiSelecting) {
      multiSelectorDiv = (
        <div
          className={
            'multi-selector' + (this.isMultiSelected() ? ' selected' : '')
          }
          onClick={() =>
            this.props.appStateStore.multiSelectAsset(this.props.asset)
          }
        >
          <CheckMark className="multi-selector-checkmark" />
        </div>
      )
    }

    return (
      <div className="content-card-container">
        <div className="content-card" data-id={this.props.asset.id}>
          {multiSelectorDiv}
          <LazyLoad
            height={200}
            offset={50}
            placeholder={<LazyPlaceholder />}
            resize
            once
          >
            <div className="asset-thumbnail" style={this.state.imageCSS} />
          </LazyLoad>
          {this.noticeOverlay}
          {this.props.asset.isFavorite && (
            <FavoriteIcon className="favorite-icon" />
          )}
          {mainIcons}

          <div className={'add-to-panel' + addToActive}>
            <h4 className="title">select playlists</h4>
            <Close className="close-x" onClick={this.showAddTo} />
            <hr />
            <ul className="options-list">{this.getAddToOptions()}</ul>
          </div>

          <div className={'details-panel' + detailsActive}>
            <Dotdotdot clamp={2} className="title">
              {this.props.asset.title}
            </Dotdotdot>
            <Close className="close-x" onClick={this.showDetails} />
            <div className="value-group owner">
              <p className="owner label">Owner</p>
              <p className="owner value">
                {this.props.asset.owner ? this.props.asset.owner.name : ''}
              </p>
            </div>
            {this.props.asset.description && (
              <div className="value-group description">
                <p className="description label">Description</p>
                <p className="description value">
                  {this.props.asset.description}
                </p>
              </div>
            )}
            {assetTypeIndicator}
            {orientationDescription}
            <div className="value-group expires">
              <p className="expires label">Expires</p>
              <p className="expires value">{this.props.asset.lifeSpanEnd}</p>
            </div>
            <button className="show-preview-btn" onClick={this.showPreview}>
              Show Preview
            </button>
          </div>

          <div className="button-grouping">
            {canEdit && (
              <button className={'edit-toggle-btn'} onClick={this.showEdit}>
                Edit
              </button>
            )}
            <button
              className={`add-to-toggle-btn${addToActive}${
                canEdit ? ' middle' : ''
              }`}
              onClick={this.showAddTo}
            >
              Add to...
            </button>
            <button
              className={'details-toggle-btn' + detailsActive}
              onClick={this.showDetails}
            >
              Details
            </button>
          </div>

          <AssetPreviewModal
            show={this.state.showingPreview}
            close={this.closePreview}
            verticalURL={this.verticalURL}
            horizontalURL={this.horizontalURL}
            youtubeID={this.props.asset.youtubeID}
            title={this.props.asset.title}
            assetType={this.props.asset.assetType}
            previewIndex={this.state.previewIndex}
            handlePreviewSelect={index => this.handlePreviewSelect(index)}
          />

          <EditAssetModal
            show={this.state.editingAsset}
            close={this.stopEditing}
            asset={this.props.asset}
          />
        </div>
      </div>
    )
  }
}

export default ContentCard
