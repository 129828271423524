import * as React from 'react'
import {Link} from 'react-router'
import Headroom from 'react-headroom'
import {Motion, spring} from 'react-motion'

import {GOOGLE_OAUTH2_URL} from 'globals'
import HashAlert from './HashAlert'

interface State {
  navIndex: number
  startNavIndex: number
  width: number
  positionConstants: number[]
  widthConstants: number[]
}

class HomeNav extends React.Component<any, State> {
  constructor(props: any) {
    super(props)

    this.state = {
      navIndex: this.switchNavIndex(props.location.pathname),
      startNavIndex: this.switchNavIndex(props.location.pathname),
      width: 0,
      positionConstants: [15, 61, 157],
      widthConstants: [45, 97, 98]
    }
  }

  componentDidMount() {
    window.addEventListener('resize', () => this.updateWindowWidth())
    this.updateWindowWidth()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => this.updateWindowWidth())
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      navIndex: this.switchNavIndex(nextProps.location.pathname)
    })
    this.updateWindowWidth()
  }

  switchNavIndex = (pathname: string) => {
    switch (pathname.substring(1)) {
      case 'guides':
        return 1
      case 'get':
        return 2
      default:
        return 0
    }
  }

  updateWindowWidth = () => {
    this.setState({width: window.innerWidth})

    switch (true) {
      case window.innerWidth >= 2560:
        this.setState({
          positionConstants: [15, 160, 475],
          widthConstants: [141, 305, 305]
        })
        break
      case window.innerWidth > 1200:
        this.setState({
          positionConstants: [15, 94, 268],
          widthConstants: [78, 173, 177]
        })
        break
      case window.innerWidth > 400:
        this.setState({
          positionConstants: [15, 71, 187],
          widthConstants: [56, 116, 118]
        })
        break
      case window.innerWidth <= 400:
        this.setState({
          positionConstants: [15, 61, 157],
          widthConstants: [45, 97, 98]
        })
        break
    }
  }

  render() {
    return (
      <div>
        <Headroom
          disableInlineStyles={true}
          id={this.props.location.pathname.substring(1)}
        >
          <nav className="home-navigation">
            <ul className="list-unstyled">
              <li>
                <Link to="/">about</Link>
              </li>
              <li>
                <Link to="/guides">content guides</Link>
              </li>
              <li>
                <Link to="/get">
                  get <b>miner</b>bytes
                </Link>
              </li>
              <li>
                <a href={GOOGLE_OAUTH2_URL}>log in</a>
              </li>
              <Motion
                defaultStyle={{
                  left: this.state.positionConstants[this.state.startNavIndex],
                  width: this.state.widthConstants[this.state.startNavIndex]
                }}
                style={{
                  left: spring(
                    this.state.positionConstants[this.state.navIndex]
                  ),
                  width: spring(this.state.widthConstants[this.state.navIndex])
                }}
              >
                {move => <div className="active-indicator" style={move} />}
              </Motion>
            </ul>
          </nav>
          <HashAlert
            paramName="signinerror"
            title="Sign In Error"
            location={this.props.location}
            type="danger"
          />
        </Headroom>
        {this.props.children}
      </div>
    )
  }
}

export default HomeNav
