import * as React from 'react'

import {RealScreenStore} from 'modules/admin/adminstores'
import {ScreenDeleteTabClass} from '../BaseDeleteTab'

const ScreenDeleteTab: React.StatelessComponent = () => (
  <ScreenDeleteTabClass typeName="screen" adminStore={RealScreenStore} />
)

export default ScreenDeleteTab
