import {computed, observable, ObservableMap} from 'mobx'

import API from 'api'
import AssetShowtime from './AssetShowtime'

export class AssetShowtimeStore {
  @observable
  assetShowtimesMap: ObservableMap<AssetShowtime[]> = observable.map()

  @computed
  get assetShowtimes() {
    return this.assetShowtimesMap.values()
  }

  get = (assetID: string, playlistID: string): AssetShowtime => {
    const asts = this.getByPlaylistID(playlistID)
    if (asts) return asts.find(showtime => showtime.assetID === assetID)
    return null
  }

  getByPlaylistID = (id: string): AssetShowtime[] => {
    return this.assetShowtimesMap.get(id)
  }

  /* Add/update assetShowtime to the store */
  add = (playlistID: string, assetShowtimes: AssetShowtime[]): void => {
    if (!playlistID)
      throw new Error(`[AssetShowtimeStore.add] Invalid id: ${playlistID}`)

    this.assetShowtimesMap.set(playlistID, assetShowtimes)
  }

  /* Remove assetShowtimes for a playlist from the store */
  remove = (id: string): void => {
    this.assetShowtimesMap.delete(id)
  }

  /* Return the corresponding assetShowtime from the store */
  findById = (id: string): AssetShowtime[] => {
    if (!id) throw new Error(`[AssetShowtimeStore.findById] Invalid id: ${id}`)

    return this.assetShowtimesMap.get(id)
  }

  assetShowtimeReducer = (map: {}, assetShowtime: AssetShowtime) => {
    map[assetShowtime.playlistID] = assetShowtime
    return map
  }

  /* Request the asset from the backend and add it to the store */
  fetchForPlaylist = (id: string): Promise<AssetShowtime[]> => {
    if (!id)
      throw new Error(`[AssetShowtimeStore.fetchForPlaylist] Invalid id: ${id}`)

    return new Promise((resolve, reject) => {
      const showtimes = this.getByPlaylistID(id)
      if (showtimes) return resolve(showtimes)

      API.assetShowtimes
        .get(id)
        .then(data =>
          data.map(showtime => AssetShowtime.fromResource(showtime))
        )
        .then(showtimesResponse => {
          this.add(id, showtimesResponse)
          resolve(showtimesResponse)
        })
        .catch(reject)
    })
  }

  /* Register a newly created asset with the backend */
  create = (assetShowtime: AssetShowtime) => {
    // return API.assets.create(asset.asResource())
    //   .then(resource => {
    //     asset = Asset.fromResource(resource)
    //     this.add(asset)
    //     return asset
    //   })
  }

  /* Delete an asset from the backend */
  delete = (assetShowtime: AssetShowtime): void => {
    // API.assets.delete(asset.asResource())
    //   .then(() => {
    //     this.remove(asset)
    //   })
  }
}

export default new AssetShowtimeStore()
