import * as React from 'react'
import {Modal} from 'react-bootstrap'
const Carousel = require('react-bootstrap').Carousel // prevent typing bug

import AssetPreview from '../AssetPreview'

const PrevArrow = require('images/elements/left-indicator.svg')
const NextArrow = require('images/elements/right-indicator.svg')

interface Props {
  show: boolean
  close: () => void
  verticalURL: string
  horizontalURL: string
  youtubeID: string
  title: string
  assetType: string
  previewIndex: number
  handlePreviewSelect: (index: number) => void
}

const AssetPreviewModal: React.StatelessComponent<Props> = props => {
  const showPreviewControls =
    (props.verticalURL !== null && props.horizontalURL !== null) ||
    props.youtubeID !== null

  return (
    <Modal show={props.show} onHide={() => props.close()}>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="content-preview">
        <Carousel
          activeIndex={props.previewIndex}
          indicators={showPreviewControls}
          controls={showPreviewControls}
          slide={true}
          wrap={false}
          interval={0}
          onSelect={index => props.handlePreviewSelect(index)}
          prevIcon={<PrevArrow className="prev-arrow" />}
          nextIcon={<NextArrow className="next-arrow" />}
        >
          <Carousel.Item>
            <AssetPreview
              url={props.verticalURL}
              youtubeID={props.youtubeID}
              orientation="vertical"
              type={props.assetType}
            />
            <Carousel.Caption>
              <p className="caption">Vertical</p>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <AssetPreview
              url={props.horizontalURL}
              youtubeID={props.youtubeID}
              orientation="horizontal"
              type={props.assetType}
            />
            <Carousel.Caption>
              <p className="caption">Horizontal</p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-default" onClick={props.close}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default AssetPreviewModal
