// tslint:disable:max-classes-per-file
import {computed, observable, ObservableMap} from 'mobx'

import API, {AdminTable, DataResponse} from 'api'
import {
  RealAsset,
  RealInstitution,
  RealPlaylist,
  RealScreen,
  RealUser
} from 'api/realsources'

interface Identifier {
  id?: number
}

export class RealStore<T extends Identifier> {
  @observable storeMap: ObservableMap<T> = observable.map()

  readonly Tname: string
  readonly endpoint: AdminTable<T>

  constructor(clsName: string) {
    this.Tname = clsName
    if (!API.admin[`${clsName}s`])
      throw new Error(
        `Failure constructing admin RealStore<${clsName}>; ` +
          'no such API endpoint.'
      )
    this.endpoint = API.admin[`${clsName}s`]
  }

  /* ATTRIBUTES */
  @computed
  get ids() {
    const ids: number[] = []
    for (const key in this.storeMap.keys()) {
      if (this.storeMap.hasOwnProperty(key)) {
        ids.push(parseInt(key))
      }
    }

    return ids
  }

  @computed
  get values() {
    return this.storeMap.values()
  }

  @computed
  get size() {
    return this.storeMap.size
  }

  /* CORE METHODS */
  get = (id: number) => {
    if (id === null) {
      return null
    }

    return this.storeMap.get(String(id))
  }

  has = (thing: T | number) => {
    const id = typeof thing === 'number' ? thing : thing.id

    return this.storeMap.has(String(id))
  }

  create = (thing: T) => {
    return this.endpoint.create(thing).then(resp => {
      if (resp.status === 'success') {
        console.info('Created:', thing, resp)
        this.add(resp.data)
      } else {
        throw resp
      }
      return resp.data
    })
  }

  delete = (thing: T | number) => {
    const id = typeof thing === 'number' ? thing : thing.id
    return this.endpoint.delete(id).then(resp => {
      this.remove(id)
      return resp
    })
  }

  add = (t: T, id: number = null) => {
    const thing = observable.object(t)
    this.storeMap.set(String(this.getID(t, id)), thing)
    return thing
  }

  remove = (thing: T | number) => {
    const id = typeof thing === 'number' ? thing : thing.id

    this.storeMap.delete(String(id))
  }

  update = (thing: T, force: boolean = false) => {
    return this.endpoint.update(thing, force).then(resp => {
      if (resp.status === 'success') {
        return this.merge(resp.data, null, false)
      } else {
        throw resp
      }
    })
  }

  showRelated = (thing: T | number, relatedType: string) => {
    const id = typeof thing === 'number' ? thing : thing.id
    return this.endpoint.showRelated(id, relatedType)
  }

  createRelation = (
    thing: T | number,
    relatedType: string,
    relatedThing: Identifier | number,
    data: object
  ) => {
    const id = typeof thing === 'number' ? thing : thing.id
    const relatedID =
      typeof relatedThing === 'number' ? relatedThing : relatedThing.id

    return this.endpoint.createRelation(id, relatedType, relatedID, data)
  }

  updateRelation = (
    thing: T | number,
    relatedType: string,
    relatedThing: Identifier | number,
    data: object
  ) => {
    const id = typeof thing === 'number' ? thing : thing.id
    const relatedID =
      typeof relatedThing === 'number' ? relatedThing : relatedThing.id
    return this.endpoint.updateRelation(id, relatedType, relatedID, data)
  }

  deleteRelation = (
    thing: T | number,
    relatedType: string,
    relatedThing: Identifier | number
  ) => {
    const id = typeof thing === 'number' ? thing : thing.id
    const relatedID =
      typeof relatedThing === 'number' ? relatedThing : relatedThing.id
    return this.endpoint.deleteRelation(id, relatedType, relatedID)
  }

  /* UTILITIES */
  merge = (t: T, id: number = null, destructive: boolean = true) => {
    id = this.getID(t, id)
    if (!this.has(id)) {
      return this.add(t, id)
    }

    const oldT = this.get(id)
    Object.getOwnPropertyNames(oldT).forEach(propName => {
      // remove property from `oldT` if it doesn't exist on `t`
      if (destructive && !t.hasOwnProperty(propName)) {
        delete oldT[propName]
      }
    })
    // all props not on `t` are now removed from `oldT`
    return Object.assign(oldT, t)
    // all props from `t` are now on `oldT`
  }

  getAll = (cb?: (succ: boolean, name: string, err?: any) => any) => {
    return this.endpoint
      .index()
      .then((res: DataResponse<T[]>) => {
        res.data.forEach((val: T) => {
          this.merge(val, null, false)
        })

        if (cb) {
          cb(true, this.Tname)
        }

        return true
      })
      .catch(reason => {
        if (cb) {
          cb(false, this.Tname, reason)
        }

        console.error(`RealStore<${this.Tname}>.getAll failed: ${reason}`)

        return false
      })
  }

  getID = (t: T, id: number) => {
    if (!t) {
      throw new Error(`Bad argument to function ${t}`)
    } else if (id === null) {
      if (!t.hasOwnProperty('id')) {
        throw new Error('Must provide an ID by argument or through object')
      }

      return t.id
    }

    return id
  }
}

class RealUserStoreClass extends RealStore<RealUser> {
  constructor() {
    super('user')
  }
  search = (input: string) => {
    return API.users.search(input)
  }
}

export const RealUserStore = new RealUserStoreClass()
export const RealAssetStore = new RealStore<RealAsset>('asset')
export const RealScreenStore = new RealStore<RealScreen>('screen')
export const RealPlaylistStore = new RealStore<RealPlaylist>('playlist')
export const RealInstitutionStore = new RealStore<RealInstitution>(
  'institution'
)

export default {
  RealUserStore,
  RealAssetStore,
  RealScreenStore,
  RealPlaylistStore
}
