import * as React from 'react'

import {RealPlaylistStore} from 'modules/admin/adminstores'
import {PlaylistDeleteTabClass} from '../BaseDeleteTab'

const PlaylistDeleteTab: React.StatelessComponent = () => (
  <PlaylistDeleteTabClass typeName="playlist" adminStore={RealPlaylistStore} />
)

export default PlaylistDeleteTab
