import * as moment from 'moment'

import {BACKEND_DOMAIN, DOMAIN_PROTO} from 'globals'

function tpathToUrl(tpath: string) {
  if (tpath) {
    return `${DOMAIN_PROTO}://${BACKEND_DOMAIN}/files/${tpath}`
  }
}

interface Model {
  [key: string]: any
}

function createConverter(propertyMap) {
  // TODO: rewrite this to be an arrow function
  // tslint:disable-next-line:only-arrow-functions
  return function<Base extends Model, Result extends Model>(obj: Base): Result {
    const result: Result = {} as Result

    for (const key in obj) {
      if (key in propertyMap) {
        if (obj[key] === undefined) continue
        const newProperty = propertyMap[key].property
        const typeConverter = propertyMap[key].type

        if (obj[key] === null) result[newProperty] = null
        else result[newProperty] = typeConverter(obj[key])
      }
    }

    return result
  }
}

const resourceToAsset = {
  id: {property: 'id', type: String},
  vert_url: {property: 'verticalURL', type: String},
  vert_file_id: {property: 'verticalFileID', type: String},
  vert_file: {property: 'verticalFile', type: Object},
  hori_url: {property: 'horizontalURL', type: String},
  hori_file_id: {property: 'horizontalFileID', type: String},
  hori_file: {property: 'horizontalFile', type: Object},
  playlistIDs: {property: 'playlistIDs', type: ids => ids.map(String)},
  name: {property: 'title', type: String},
  ownerID: {property: 'ownerID', type: String},
  description: {property: 'description', type: String},
  file_type: {property: 'assetType', type: String},
  orientation: {property: 'orientation', type: String},
  web_file_id: {property: 'webFileID', type: String},
  web_url: {property: 'webURL', type: String},
  youtube_id: {property: 'youtubeID', type: String},
  youtube_file_id: {property: 'youtubeFileID', type: String},
  tpath: {
    property: 'tpath',
    type: value => tpathToUrl(value)
  },
  avail_date_time: {
    property: 'lifeSpanBegin',
    type: value => {
      return moment(value).format()
    }
  },
  expr_date_time: {
    property: 'lifeSpanEnd',
    type: value => {
      return moment(value).format()
    }
  },
  is_fav: {property: 'isFavorite', type: Boolean},
  is_private: {property: 'isPrivate', type: Boolean}
}

const assetToResource = {
  id: {property: 'id', type: Number},
  verticalURL: {property: 'vert_url', type: String},
  verticalFileID: {property: 'vert_file_id', type: String},
  horizontalURL: {property: 'hori_url', type: String},
  horizontalFileID: {property: 'hori_file_id', type: String},
  playlistIDs: {property: 'playlistIDs', type: ids => ids.map(Number)},
  title: {property: 'name', type: String},
  ownerID: {property: 'ownerID', type: Number},
  description: {property: 'description', type: String},
  assetType: {property: 'file_type', type: String},
  orientation: {property: 'orientation', type: String},
  webFileID: {property: 'web_file_id', type: Number},
  webURL: {property: 'web_url', type: String},
  youtubeID: {property: 'youtube_id', type: String},
  youtubeFileID: {property: 'youtube_file_id', type: String},
  tpath: {property: 'tpath', type: String},
  lifeSpanBegin: {
    property: 'avail_date_time',
    type: value => {
      return moment(value).format()
    }
  },
  lifeSpanEnd: {
    property: 'expr_date_time',
    type: value => {
      return moment(value).format()
    }
  },
  isFavorite: {property: 'is_fav', type: Number},
  isPrivate: {property: 'is_private', type: Number}
}

export const assetConverter = {
  toModel: createConverter(resourceToAsset),
  toResource: createConverter(assetToResource)
}

const resourceToAssetShowtime = {
  asset_id: {property: 'assetID', type: String},
  playlist_id: {property: 'playlistID', type: String},
  index: {
    property: 'index',
    // convert from 1 based to 0 based
    type: value => {
      return value - 1
    }
  },
  time_start: {property: 'timeStart', type: String},
  time_stop: {property: 'timeStop', type: String},
  date_start: {property: 'dateStart', type: String},
  date_stop: {property: 'dateStop', type: String},
  duration: {property: 'duration', type: Number},
  enabled: {property: 'enabled', type: Boolean},
  dow: {
    property: 'daysOfWeek',
    type: value => {
      const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']
      return days.map((name, i) => ({
        day: name,
        active: value.includes(i)
      }))
    }
  }
}

const assetShowtimeToResource = {
  assetID: {property: 'asset_id', type: Number},
  playlistID: {property: 'playlist_id', type: Number},
  index: {
    property: 'index',
    type: value => {
      // convert from 0 based to 1 based index for backend
      return value + 1
    }
  },
  timeStart: {property: 'time_start', type: String},
  timeStop: {property: 'time_stop', type: String},
  dateStart: {property: 'date_start', type: String},
  dateStop: {property: 'date_stop', type: String},
  duration: {property: 'duration', type: Number},
  enabled: {property: 'enabled', type: Number},
  daysOfWeek: {
    property: 'dow',
    type: value => {
      const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']
      let result = ''
      value.forEach(day => {
        if (day.active) result += days.indexOf(day.day)
      })

      return result
    }
  }
}

export const assetShowtimeConverter = {
  toModel: createConverter(resourceToAssetShowtime),
  toResource: createConverter(assetShowtimeToResource)
}

function identity(val: any) {
  return val
}

const resourceToScreen = {
  id: {property: 'id', type: String},
  name: {property: 'title', type: String},
  orientation: {property: 'orientation', type: String},
  location: {property: 'location', type: String},
  playlistIDs: {property: 'playlistIDs', type: ids => ids.map(String)},
  sharedIDs: {property: 'sharedIDs', type: ids => ids.map(String)},
  ownerID: {property: 'ownerID', type: String},
  lat: {property: 'lat', type: Number},
  lon: {property: 'lon', type: Number},
  last_req: {property: 'last_req', type: moment},
  marquee: {property: 'marquee', type: String},
  mq_created: {property: 'mq_created', type: moment},
  mq_duration: {property: 'mq_duration', type: Number},
  activePlaylistID: {property: 'activePlaylistID', type: String},
  activePlaylistIDs: {
    property: 'activePlaylistIDs',
    type: ids => ids.map(String)
  },
  department: {property: 'department', type: identity},
  institution: {property: 'institution', type: institution => institution.name}
}

const screenToResource = {
  id: {property: 'id', type: Number},
  title: {property: 'name', type: String},
  orientation: {property: 'orientation', type: String},
  location: {property: 'location', type: String},
  playlistIDs: {property: 'playlistIDs', type: ids => ids.map(Number)},
  sharedIDs: {property: 'sharedIDs', type: ids => ids.map(Number)},
  ownerID: {property: 'ownerID', type: String},
  lat: {property: 'lat', type: Number},
  lon: {property: 'lon', type: Number},
  last_req: {property: 'last_req', type: moment}, // TODO: tostring?
  marquee: {property: 'marquee', type: String},
  mq_created: {property: 'mq_created', type: moment}, // TODO: tostring?
  mq_duration: {property: 'mq_duration', type: Number},
  activePlaylistID: {property: 'activePlaylistID', type: Number},
  activePlaylistIDs: {
    property: 'activePlaylistIDs',
    type: ids => ids.map(Number)
  },
  department: {property: 'department', type: identity}
  // institution: {property: 'institution', type: institution => institution.name} // TODO: how to handle this?
}

export const screenConverter = {
  toModel: createConverter(resourceToScreen),
  toResource: createConverter(screenToResource)
}
