import * as React from 'react'
import {Tab} from 'react-bootstrap'

export interface BaseAdminTabProps {
  title: string
  eventKey: number
}

class BaseAdminTab extends React.Component<BaseAdminTabProps> {
  getContents = (): JSX.Element | JSX.Element[] => {
    console.log('getContents():', this)
    throw new Error('getContents instance function should be overridden.')
  }

  render() {
    return <Tab {...this.props}>{this.getContents()}</Tab>
  }
}

export default BaseAdminTab
