import * as React from 'react'
import {AxiosResponse} from 'axios'
import {inject, observer} from 'mobx-react'
import {Button, Checkbox, ControlLabel, FormGroup, Table} from 'react-bootstrap'

import {BroadcastStore} from 'stores/broadcastStore'
import {AlertStore} from 'stores/alertStore'
import {AppStateStore} from 'stores/appStateStore'
import Broadcast from 'stores/Broadcast'

interface Props {
  broadcasts: Broadcast[]
  disabled: boolean
  broadcastStore?: BroadcastStore
  alertStore?: AlertStore
  appStateStore?: AppStateStore
}

interface State {
  disabled: boolean
  checks: boolean[]
}

@inject('broadcastStore', 'alertStore')
@observer
class SideNavStopBroadcast extends React.Component<Props, State> {
  constructor(props: any) {
    super(props)

    this.state = {
      disabled: props.disabled,
      checks: new Array(props.broadcasts.length)
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.broadcasts !== this.props.broadcasts) {
      this.setState({
        checks: new Array(nextProps.broadcasts.length)
      })
    }

    if (nextProps.disabled !== this.props.disabled) {
      this.setState({disabled: nextProps.disabled})
    }
  }

  selectedBroadcasts = () => {
    const ret: Broadcast[] = []
    this.state.checks.forEach((chkbx, i) => {
      if (chkbx) {
        ret.push(this.props.broadcasts[i])
      }
    })

    return ret
  }

  toggleCheck = (checkIndex: number) => {
    const newChecks = this.state.checks

    newChecks[checkIndex] = !newChecks[checkIndex]

    this.setState({
      checks: newChecks
    })
  }

  get canSubmit() {
    return this.state.checks.some(Boolean)
  }

  handleSubmit = event => {
    event.preventDefault()

    const broadcasts = this.selectedBroadcasts()
    this.setState({disabled: true})

    const res = broadcasts.map(bcast => {
      return this.props.broadcastStore.delete(bcast)
    })
    Promise.all(res).then(resolved => {
      const trm = resolved.length > 1 ? 'all broadcasts' : 'broadcast'

      // TODO: can we remove AxiosResponse from here?
      // Anything dealing with axios should exist in our mobx layer
      if (resolved.every((r: AxiosResponse) => r.status === 204)) {
        this.props.alertStore.addAlert(
          `Successfully stopped ${trm}.`,
          'success'
        )
        this.props.appStateStore.closeSideNav()
      } else {
        this.props.alertStore.addAlert(`Failed to stop ${trm}.`, 'danger')
      }
    })
  }

  rowGenerator = (broadcasts: Broadcast[]): JSX.Element[] => {
    return broadcasts.map((bcst, i) => {
      return (
        <BroadcastRow
          key={bcst.id}
          broadcast={bcst}
          disabled={this.state.disabled}
          toggleCheck={() => this.toggleCheck(i)}
        />
      )
    })
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit} data-disabled={this.state.disabled}>
        <FormGroup>
          <ControlLabel>select broadcasts to stop</ControlLabel>
          <Table
            className="broadcasts-table"
            data-disabled={this.state.disabled}
          >
            <thead>
              <tr>
                <th />
                <th>Broadcast</th>
                <th>Created By</th>
                <th>Active Screens</th>
              </tr>
            </thead>
            <tbody>{this.rowGenerator(this.props.broadcasts)}</tbody>
          </Table>
        </FormGroup>
        <hr />
        <Button type="submit" disabled={!this.canSubmit || this.state.disabled}>
          Stop Broadcast
        </Button>
      </form>
    )
  }
}

interface BroadcastRowProps {
  broadcast: Broadcast
  disabled: boolean
  toggleCheck: () => void
}

const BroadcastRow = observer((props: BroadcastRowProps) => {
  const idsTitle = props.broadcast.screens.map(scrn => scrn.title).join(', ')

  return (
    <tr className="broadcast-row">
      <td>
        <Checkbox onChange={props.toggleCheck} disabled={props.disabled} />
      </td>
      <td title={props.broadcast.message_text}>
        {props.broadcast.message_text}
      </td>
      <td>{props.broadcast.user && props.broadcast.user.name}</td>
      <td title={idsTitle}>{props.broadcast.screenIDs.length}</td>
    </tr>
  )
})

export default SideNavStopBroadcast
