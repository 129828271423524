import * as React from 'react'
import {Overlay, OverlayTrigger, Popover} from 'react-bootstrap'
import {Link} from 'react-router'

import Playlist from 'stores/Playlist'

const ShareIcon = require('images/elements/share-icon.svg')

interface Props {
  playlist: Playlist
  tooltip: JSX.Element
  numAssetsText: string
  canEdit: boolean
  toggleShare: () => void
  isOwner: boolean
  showDelete: boolean
  toggleDelete: () => void
  deleteConfirmedClicked: () => void
}

const PlaylistRow: React.StatelessComponent<Props> = props => {
  let shareButtonRef: HTMLButtonElement
  let deleteButtonRef: HTMLButtonElement

  return (
    <tr className="playlist-row">
      <td>{props.playlist.title}</td>
      <td>{props.playlist.owner.name}</td>
      <td>{props.numAssetsText}</td>
      <td>{props.playlist.orientation || 'n/a'}</td>
      <td className="share-td">
        <OverlayTrigger placement="bottom" overlay={props.tooltip}>
          <div className="share-info">
            <ShareIcon className="share-icon" />
            {props.playlist.sharedWith.length !== 0 && (
              <p className="shared-number">
                {props.playlist.sharedWith.length}
              </p>
            )}
          </div>
        </OverlayTrigger>
      </td>
      <td className="in-use-td">
        {props.playlist.inUse && <p className="in-use">IN USE</p>}
      </td>
      <td className="button-td">
        <Link to={`/playlists/${props.playlist.id}/edit`}>
          <button
            disabled={!props.canEdit}
            className={`edit-btn${props.canEdit ? '' : ' disabled'}`}
          >
            Edit
          </button>
        </Link>
        <button
          className="share-btn"
          ref={ref => (shareButtonRef = ref)}
          onClick={props.toggleShare}
        >
          Share
        </button>
        <button
          disabled={!props.isOwner}
          className={`delete-btn${props.isOwner ? '' : ' disabled'}`}
          ref={ref => (deleteButtonRef = ref)}
          onClick={props.toggleDelete}
        >
          Delete
        </button>
        <Overlay
          rootClose
          show={props.showDelete}
          onHide={() => (props.showDelete ? props.toggleDelete() : null)}
          placement="bottom"
          target={() => deleteButtonRef}
        >
          <Popover
            id="popover-trigger-click-root-close"
            className="delete"
            title="ARE YOU SURE?"
          >
            <div className="popover-container">
              <button className="cancel" onClick={props.toggleDelete}>
                CANCEL
              </button>
              <button
                className="deleteConfirm"
                onClick={props.deleteConfirmedClicked}
              >
                YES
              </button>
            </div>
          </Popover>
        </Overlay>
      </td>
    </tr>
  )
}

export default PlaylistRow
