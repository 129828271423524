import * as React from 'react'
import {Modal} from 'react-bootstrap'

import AssetForm from '../AssetForm'
import Asset from 'stores/Asset'

interface Props {
  show: boolean
  close: () => void
  asset: Asset
}

const EditAssetModal: React.StatelessComponent<Props> = props => (
  <Modal show={props.show} onHide={props.close}>
    <Modal.Header closeButton>
      <h4 className="modal-title">Edit Asset</h4>
    </Modal.Header>
    <Modal.Body>
      <AssetForm
        asset={props.asset}
        onDelete={props.close}
        closeModal={props.close}
      />
    </Modal.Body>
    <Modal.Footer>
      <button type="button" className="btn btn-default" onClick={props.close}>
        Cancel
      </button>
    </Modal.Footer>
  </Modal>
)

export default EditAssetModal
