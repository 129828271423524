/* tslint:disable:interface-name */
import axios, {AxiosPromise, AxiosResponse} from 'axios'

import {BACKEND_DOMAIN, DOMAIN_PROTO, PermissionLevel} from 'globals'

import {
  AssetResource,
  AssetShowtimeResource,
  BroadcastResource,
  BroadcastResource_Partial,
  FileResource,
  InstitutionResource,
  NotificationResource,
  PlaylistResource,
  ScreenResource,
  ScreenResource_Partial,
  ShowtimeResource,
  ShowtimeResource_Partial,
  UserResource,
  UserResource_Partial
} from './resources'
import * as realsources from './realsources'

const http = axios.create({
  baseURL: `${DOMAIN_PROTO}://${BACKEND_DOMAIN}`
})

function flatten(obj, property: string) {
  for (const key in obj[property]) {
    if (obj.hasOwnProperty(property)) obj[key] = obj[property][key]
  }

  delete obj[property]

  return obj
}

function handleAsset(asset: any) {
  flatten(asset, 'pivot')
  asset.hori_file_url = asset.hori_file ? asset.hori_file.url : null
  asset.vert_file_url = asset.vert_file ? asset.vert_file.url : null
  return asset
}

export interface DataResponse<$DataType> {
  status: 'success' | 'error'
  data?: $DataType
  message?: string
}

export type PromiseDataResponse<$DataType> = Promise<DataResponse<$DataType>>

type GenericID = string | number

interface IDdType {
  id?: number
}

export class AdminTable<$ResourceType extends IDdType> {
  readonly tableName: string
  readonly related: Set<string>

  constructor(tableName: string, related: string[] | Set<string>) {
    this.tableName = tableName
    this.related = new Set(related)
  }

  verifyRelated = (relatedType: string) => {
    if (!this.related.has(relatedType))
      throw new Error(
        `Table ${this.tableName} has no relation to ${relatedType}.`
      )
  }

  index = (): PromiseDataResponse<$ResourceType[]> => {
    return http
      .get(`/a/${this.tableName}`)
      .then((ares: AxiosResponse) => ares.data)
  }

  show = (id: GenericID): PromiseDataResponse<$ResourceType> => {
    return http
      .get(`/a/${this.tableName}/${id}`)
      .then((ares: AxiosResponse) => ares.data)
  }

  showRelated = (
    id: GenericID,
    relatedType: string
  ): Promise<$ResourceType[]> => {
    this.verifyRelated(relatedType)
    return http
      .get(`/a/${this.tableName}/${id}/${relatedType}`)
      .then((ares: AxiosResponse) => ares.data)
  }

  createRelation = (
    id: GenericID,
    relatedType: string,
    relatedID: GenericID,
    data: object
  ): PromiseDataResponse<$ResourceType> => {
    this.verifyRelated(relatedType)
    return http
      .post(`/a/${this.tableName}/${id}/${relatedType}/${relatedID}`, data)
      .then((ares: AxiosResponse) => ares.data)
  }

  updateRelation = (
    id: GenericID,
    relatedType: string,
    relatedID: GenericID,
    data: object
  ): PromiseDataResponse<$ResourceType> => {
    this.verifyRelated(relatedType)
    return http
      .put(`/a/${this.tableName}/${id}/${relatedType}/${relatedID}`, data)
      .then((ares: AxiosResponse) => ares.data)
  }

  deleteRelation = (
    id: GenericID,
    relatedType: string,
    relatedID: GenericID
  ): AxiosPromise => {
    this.verifyRelated(relatedType)
    return http.delete(`/a/${this.tableName}/${id}/${relatedType}/${relatedID}`)
  }

  create = (resource: $ResourceType): PromiseDataResponse<$ResourceType> => {
    return http
      .post(`/a/${this.tableName}`, resource)
      .then((ares: AxiosResponse) => ares.data)
  }

  update = (
    partial: $ResourceType,
    force: boolean = false
  ): PromiseDataResponse<$ResourceType> => {
    const forcetxt = force ? '?force' : ''
    return http
      .put(`/a/${this.tableName}/${partial.id}${forcetxt}`, partial)
      .then((ares: AxiosResponse) => ares.data)
  }

  delete(id: GenericID): AxiosPromise {
    return http.delete(`/a/${this.tableName}/${id}`)
  }
}

export default {
  all: axios.all,
  spread: axios.spread,

  setToken(token: string) {
    http.interceptors.request.use(config => {
      config.headers.Authorization = `Bearer ${token}`
      return config
    })
  },

  getToken(email) {
    const query = {email}
    return http.post('/login', query).then(res => this.setToken(res.data.token))
  },

  loadToken() {
    if (window.localStorage.token) {
      this.setToken(window.localStorage.token)
    } else {
      console.warn('localStorage.token not set')
    }
  },

  /*** ADMIN ***/
  admin: {
    screens: new AdminTable<realsources.RealScreen>('screens', [
      'users',
      'playlists',
      'alerts',
      'showtimes'
    ]),
    users: new AdminTable<realsources.RealUser>('users', [
      'screens',
      'alerts',
      'assets',
      'files',
      'notifications',
      'playlists'
    ]),
    // alerts
    assets: new AdminTable<realsources.RealAsset>('assets', [
      'users',
      'playlists'
    ]),
    // files
    institutions: new AdminTable<realsources.RealInstitution>('institutions', [
      'users'
    ]),
    // notifications
    playlists: new AdminTable<realsources.RealPlaylist>('playlists', [
      'users',
      'assets',
      'showtimes'
    ]),
    // misc
    misc: {
      cleanFiles: (dryRun: boolean): AxiosPromise => {
        const dryRunTxt = dryRun ? '?dry-run=true' : ''
        return http.get(`/a/files/clean${dryRunTxt}`).then(res => res.data)
      }
    }
  },

  /*** USER ***/
  users: {
    get(userID: string | number): Promise<UserResource> {
      return http.get(`/users/${userID}`).then(res => res.data)
    },

    index() {
      return http.get('/users').then(res => res.data)
    },

    search(query: string): Promise<AxiosResponse> {
      return http.post('/users/search', {terms: query})
    },

    update(usr: UserResource_Partial): Promise<AxiosResponse> {
      return http.put('/users', usr)
    }
  },

  current: {
    user() {
      return http.get('/users/current/info').then(res => res.data)
    },

    favorites() {
      return http
        .get('/users/current/assets/favorites')
        .then(res => res.data as number[])
    },

    assets() {
      return http
        .get('/users/current/assets')
        .then(res => res.data.map(handleAsset))
    },

    assetsByInstitution(): Promise<AssetResource[]> {
      return http
        .get('/users/current/institution/assets')
        .then(res => res.data.map(handleAsset))
    },

    assetsByDepartment(): Promise<AssetResource[]> {
      return http
        .get('/users/current/department/assets')
        .then(res => res.data.map(handleAsset))
    },

    screens() {
      return http.get('/users/current/screens').then(res => res.data)
    },

    playlists() {
      return http.get('/users/current/playlists').then(res => res.data)
    }
  },

  /*** INSTITUTION */
  institutions: {
    get(id: string | number): Promise<InstitutionResource> {
      return http.get(`/institutions/${id}`).then(res => res.data)
    }
  },

  /*** BROADCASTS/ALERTS ***/
  broadcasts: {
    getAll(): Promise<BroadcastResource[]> {
      return http.get(`/alerts`).then(res => res.data)
    },

    get(broadcastID: string | number): Promise<BroadcastResource> {
      return http.get(`/alerts/${broadcastID}`).then(res => res.data)
    },

    create(broadcast: BroadcastResource): Promise<BroadcastResource> {
      return http.post(`/alerts`, broadcast).then(res => res.data.data)
    },

    delete(broadcastID: string | number): Promise<AxiosResponse> {
      return http.delete(`/alerts/${broadcastID}`)
    },

    update(broadcastP: BroadcastResource_Partial): Promise<AxiosResponse> {
      return http.put(`/alerts/${broadcastP.id}`, broadcastP)
    },

    addScreen(
      broadcastID: string | number,
      screenID: string | number
    ): Promise<AxiosResponse> {
      return http.post(`/alerts/${broadcastID}/screens/${screenID}`)
    },

    removeScreen(
      broadcastID: string | number,
      screenID: string | number
    ): Promise<AxiosResponse> {
      return http.delete(`/alerts/${broadcastID}/screens/${screenID}`)
    }
  },

  /*** ASSET ***/
  assets: {
    get(assetID: string | number): Promise<AssetResource> {
      return http
        .get(`/assets/${assetID}`)
        .then(res => res.data)
        .then(handleAsset)
    },

    recent(): Promise<AssetResource[]> {
      return http
        .get(`/assets/recent`)
        .then(res => res.data)
        .then(handleAsset)
    },

    create(asset: AssetResource) {
      return http
        .post('/assets', asset)
        .then(res => res.data.data)
        .then(handleAsset)
    },

    edit(asset: AssetResource) {
      return http
        .put(`/assets/${asset.id}`, asset)
        .then(res => res.data)
        .then(handleAsset)
    },

    delete(asset: AssetResource) {
      return http.delete(`/assets/${asset.id}`)
    },

    toggleFavorite(asset: AssetResource) {
      if (asset.is_fav) {
        return http.delete(`/assets/${asset.id}/favorite`)
      } else {
        return http.put(`/assets/${asset.id}/favorite`)
      }
    }
  },

  assetShowtimes: {
    get(playlistID: number | string): Promise<AssetShowtimeResource[]> {
      return http
        .get(`/playlists/${playlistID}/assets/showtimes`)
        .then(res => res.data)
    },

    update(showtime: AssetShowtimeResource) {
      const playlistID = showtime.playlist_id
      const assetID = showtime.asset_id
      return http
        .put(`/playlists/${playlistID}/assets/${assetID}`, showtime)
        .then(res => res.data)
    }
  },

  /*** PLAYLIST ***/
  playlists: {
    get(playlistID: string | number): Promise<PlaylistResource> {
      return http.get(`/playlists/${playlistID}`).then(res => res.data)
    },

    assets(playlistID: string | number): Promise<AssetResource[]> {
      return http.get(`playlists/${playlistID}/assets`).then(res => res.data)
    },

    create(playlist: PlaylistResource): Promise<PlaylistResource> {
      return http.post('/playlists', playlist).then(res => res.data.data)
    },

    delete(playlist: PlaylistResource): Promise<AxiosResponse> {
      return http.delete(`/playlists/${playlist.id}`)
    },

    update(playlist: PlaylistResource): Promise<AxiosResponse> {
      if (!playlist.id)
        throw new Error('PlaylistResource_Partial is missing its ID')
      return http.put(`/playlists/${playlist.id}`, playlist)
    },

    shareTo(
      playlistID: number | string,
      userID: number | string,
      permission: PermissionLevel
    ) {
      return http.post(`/playlists/${playlistID}/users/${userID}`, {
        permission
      })
    },

    unshareTo(playlistID: number | string, userID: number | string) {
      return http.delete(`/playlists/${playlistID}/users/${userID}`)
    },

    addAsset(asset: AssetResource, playlist: PlaylistResource) {
      return http.post(`/playlists/${playlist.id}/assets/${asset.id}`)
    },

    removeAsset(asset: AssetResource, playlist: PlaylistResource) {
      return http.delete(`/playlists/${playlist.id}/assets/${asset.id}`)
    },

    reorderAssets(playlist: PlaylistResource, assetIDs: number[] | string[]) {
      return http.put(`/playlists/${playlist.id}/asset_order`, assetIDs)
    }
  },

  /*** FILES ***/
  files: {
    decodeCanvasElement(canvas: HTMLCanvasElement) {
      return new Promise((resolve, reject) => {
        if (canvas.toBlob) canvas.toBlob(resolve)
        else if (canvas.msToBlob) resolve(canvas.msToBlob())
        else reject('Unsupported browser: (ms)ToBlob unsupported')
      })
    },

    get(id: number) {
      return http.get(`/files/${id}`).then(res => res.data)
    },

    upload(file: File, orientation: string) {
      if (file instanceof HTMLCanvasElement) {
        return this.decodeCanvasElement(file).then(img => {
          const data = new FormData()
          data.append('file', img)
          data.append('orientation', orientation)
          return http.post(`/files`, data)
        })
      } else {
        const data = new FormData()
        data.append('file', file)
        data.append('orientation', orientation)
        return http.post(`/files`, data)
      }
    },

    uploadAll(file: FileResource) {
      const requests = []
      if (file.hori_file)
        requests.push(this.upload(file.hori_file, 'horizontal'))
      if (file.vert_file) requests.push(this.upload(file.vert_file, 'vertical'))

      return axios
        .all(requests)
        .then(responses => responses.map(res => res.data))
    },

    previewWebURL(url: string) {
      return http.get(`/files/web_preview?url=${url}`).then(res => res.data)
    },

    createWebURL(url: string) {
      return http.post(`/files/web_preview?url=${url}`).then(res => res.data)
    }
  },

  /*** SCREEN ***/
  screens: {
    get(screenID: string | number): Promise<ScreenResource> {
      return http.get(`/screens/${screenID}`).then(res => res.data)
    },

    shareTo(
      screenID: number | string,
      userID: number | string,
      permission: PermissionLevel
    ) {
      return http.post(`/screens/${screenID}/users/${userID}`, {
        permission
      })
    },

    unshareTo(screenID: number | string, userID: number | string) {
      return http.delete(`/screens/${screenID}/users/${userID}`)
    },

    update(resourcePart: ScreenResource_Partial): Promise<AxiosResponse> {
      if (!resourcePart.id)
        throw new Error('ScreenResource_Partial is missing its ID')
      return http.put(`/screens/${resourcePart.id}`, resourcePart)
    },

    getThumbnails(screenID: number | string): Promise<string[]> {
      return http
        .get(`/screens/${screenID}/thumbnails`)
        .then(res =>
          res.data.map(
            thumb => `${DOMAIN_PROTO}://${BACKEND_DOMAIN}/files/${thumb}`
          )
        )
        .catch(err => {
          console.error(err)
          throw new Error(
            `Failed to get screen #${screenID} thumbnails: ${err}`
          )
        })
    }
  },

  /*** SHOWTIME ***/
  showtimes: {
    get(showtimeID: string | number): Promise<ShowtimeResource> {
      return http.get(`/showtimes/${showtimeID}`).then(res => res.data)
    },

    create(st: ShowtimeResource): Promise<ShowtimeResource> {
      return http.post(`/showtimes`, st).then(res => res.data.data)
    },

    update(st: ShowtimeResource_Partial): Promise<AxiosResponse> {
      return http.put(`/showtimes/${st.id}`, st)
    },

    delete(showtimeID: number | string): Promise<AxiosResponse> {
      return http.delete(`/showtimes/${showtimeID}`)
    }
  },

  /*** NOTIFICATIONS ***/
  notifications: {
    load(): Promise<NotificationResource[]> {
      return http.put(`/notifications`).then(res => {
        if (!res.data || res.data.status !== 'success')
          console.warn(
            "Received unexpected response from '/notifications':",
            res
          )

        return http
          .get(`/users/current/notifications`)
          .then(res2 => res2.data) as Promise<NotificationResource[]>
      })
    },

    clear(id: number | string) {
      return http.delete(`/notifications/${id}`)
    },

    get(id: number | string): Promise<NotificationResource> {
      // Prefer loadNotifications() as this endpoint doesn't add 'link' attribute
      return http.get(`/notifications/${id}`).then(res => res.data)
    }
  }
}
