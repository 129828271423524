import * as React from 'react'
import {object as PTObject} from 'prop-types'
import {inject, observer} from 'mobx-react'
import {
  Alert,
  Collapse,
  Modal,
  Nav,
  Navbar as BSNavbar,
  NavItem
} from 'react-bootstrap'
import {Link} from 'react-router'
import {IndexLinkContainer, LinkContainer} from 'react-router-bootstrap'
import {Motion, spring} from 'react-motion'

import {TOKEN_TIMEOUT_GRACE} from 'globals'
import API from 'api'
import initializeStores from 'stores'
import {UserStore} from 'stores/userStore'
import {AlertStore} from 'stores/alertStore'
import {AppStateStore} from 'stores/appStateStore'
import Auth from 'modules/auth'
import AdminPanelOpener from 'modules/admin/Opener'
import AlertComponent from './AlertComponent'
import ProfileIcon from './ProfileIcon'
import ProfileEditModal from './ProfileEditModal'
import NotificationIcon from './NotificationIcon'

const LogoHex = require('images/elements/mb-bar-hex.svg')
const LogoText = require('images/elements/mb-bar-text.svg')
const ExternalLink = require('images/elements/external-link.svg')

const Navbar = BSNavbar as any

interface ContextRouter {
  // temp fix: ideally shouldn't exist
  push: (context: string) => void
}

interface Context {
  router: ContextRouter // temporary solution
}

interface Props {
  userStore?: UserStore
  alertStore?: AlertStore
  appStateStore?: AppStateStore
}

// TODO: let's put some better types on this
interface State {
  navIndex: number
  startNavIndex: number
  loggedIn?: any
  timeoutTimer?: any
  timeoutModalShowing?: any
  timeoutModalAlertShowing?: any
  notificationPanel?: any
  closeNotificationPanel?: any
  showProfileEditModal: boolean
}

const NAV_IDX_RE = /^(\/[^\/]*).*$/

const positionConstants = [352, 267, 186, 127]
const touchPositionConstants = [435, 350, 269, 210]
const widthConstants = [81, 85.5, 81, 59]
const NAV_INDICES = {
  '/screens': 0,
  '/playlists': 1,
  '/content': 2,
  '/help': 3
}

@inject('userStore', 'alertStore', 'appStateStore')
@observer
class App extends React.Component<Props, State> {
  static contextTypes = {
    router: PTObject.isRequired
  }

  context: Context

  constructor(props: any, context: Context) {
    super(props)

    this.state = {
      navIndex: NAV_INDICES[props.location.pathname.replace(NAV_IDX_RE, '$1')],
      startNavIndex:
        NAV_INDICES[props.location.pathname.replace(NAV_IDX_RE, '$1')],
      loggedIn: Auth.loggedIn(),
      timeoutTimer: Auth.makeTokenTimer(() => this.timeoutExpiration()),
      timeoutModalShowing: false,
      timeoutModalAlertShowing: false,
      notificationPanel: null,
      closeNotificationPanel: null,
      showProfileEditModal: false
    }

    Auth.onChange = this.updateAuth = this.updateAuth.bind(this)
  }

  componentWillMount() {
    API.loadToken()
    initializeStores()
    this.props.appStateStore.navbarExpanded = false
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      navIndex:
        NAV_INDICES[nextProps.location.pathname.replace(NAV_IDX_RE, '$1')],
      loggedIn: Auth.loggedIn(),
      notificationPanel: null,
      closeNotificationPanel: null
    })
  }

  timeoutExpiration = () => {
    // show LogInTimeoutModal window, and set the timer to log them out
    console.log('Close to logout expiration, alerting!')
    this.setState({
      timeoutTimer: setTimeout(
        () => this.signOut(),
        Auth.getTokenTTL(window.localStorage.token)
      ),
      timeoutModalShowing: true
    })
  }

  refreshTokenModal = () => {
    // hide LogInTimeoutModal window, refresh the token, and reset the timer
    Auth.refreshToken(success =>
      success
        ? this.setState({
            timeoutTimer: Auth.makeTokenTimer(() => this.timeoutExpiration()),
            timeoutModalShowing: false
          })
        : this.setState({
            timeoutModalAlertShowing: true
          })
    )
  }

  updateAuth = (loggedIn: any) =>
    this.setState({
      loggedIn
    })

  notificationIconClicked = (panel: any, closer: any) =>
    this.setState({
      notificationPanel: panel,
      closeNotificationPanel: closer
    })

  openProfileEdit = () => this.setState({showProfileEditModal: true})

  closeTimeoutModal = () =>
    this.setState({
      timeoutModalShowing: false
    })

  signOut = () => {
    this.closeTimeoutModal()
    console.log('Signed out')
    if (Auth.logout(null)) {
      this.context.router.push('/')
    }
  }

  get shouldRenderTouch(): boolean {
    return (
      this.props.userStore.currentUser &&
      (this.props.userStore.currentUser.isAdmin ||
        Boolean(this.props.userStore.currentUser.touch_dirs.length))
    )
  }

  onNavbarToggle = (opened: boolean) => {
    this.props.appStateStore.navbarExpanded = opened

    if (opened) {
      $('.side-nav-col').addClass('navbar-is-expanded')
    } else {
      $('.side-nav-col').removeClass('navbar-is-expanded')
    }
  }

  render() {
    return (
      <div>
        <Navbar
          inverse
          collapseOnSelect
          onToggle={this.onNavbarToggle}
          expanded={this.props.appStateStore.navbarExpanded}
        >
          <Navbar.Header>
            <Navbar.Brand>
              <Link to="/">
                <div id="minerbytes-logo">
                  <LogoHex id="logo-hex" />
                  <LogoText id="logo-text" />
                </div>
              </Link>
            </Navbar.Brand>
            <NotificationIcon
              sendNotificationPanel={(panel, closer) =>
                this.notificationIconClicked(panel, closer)
              }
            />
            {this.state.notificationPanel}
            <ProfileIcon
              openProfileEdit={this.openProfileEdit}
              signOut={this.signOut}
            />
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse>
            <Motion
              defaultStyle={{
                right: this.shouldRenderTouch
                  ? touchPositionConstants[this.state.startNavIndex]
                  : positionConstants[this.state.startNavIndex],
                width: widthConstants[this.state.startNavIndex]
              }}
              style={{
                right: spring(
                  this.shouldRenderTouch
                    ? touchPositionConstants[this.state.navIndex]
                    : positionConstants[this.state.navIndex]
                ),
                width: spring(widthConstants[this.state.navIndex])
              }}
            >
              {move => <div className="active-indicator" style={move} />}
            </Motion>

            <Nav className="mb-nav" pullRight>
              <IndexLinkContainer to="/screens">
                <NavItem>Screens</NavItem>
              </IndexLinkContainer>
              <LinkContainer to="/playlists">
                <NavItem>Playlists</NavItem>
              </LinkContainer>
              <LinkContainer to="/content">
                <NavItem>Content</NavItem>
              </LinkContainer>
              <LinkContainer to="/help">
                <NavItem>Help</NavItem>
              </LinkContainer>
              {this.shouldRenderTouch && <TouchButton />}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <div id="aalertcontainer-container">
          <div id="aalertcontainer">
            {this.props.alertStore.alerts.map(alert => (
              <AlertComponent alert={alert} key={alert.uid} />
            ))}
          </div>
        </div>
        <div className="row" id="wrapper">
          {this.props.children}
        </div>
        <AdminPanelOpener />
        <Modal
          show={this.state.timeoutModalShowing}
          onHide={this.closeTimeoutModal}
        >
          <Modal.Header>
            <h4 className="modal-title">Log In Timeout</h4>
          </Modal.Header>
          <Modal.Body>
            Due to inactivity, you will be logged out in{' '}
            {TOKEN_TIMEOUT_GRACE / 60000} minutes.
          </Modal.Body>
          <Modal.Footer>
            <button type="button" onClick={this.closeTimeoutModal}>
              Close
            </button>
            <button
              type="button"
              className="btn btn-default btn-primary"
              onClick={this.refreshTokenModal}
            >
              Refresh Token
            </button>
          </Modal.Footer>
          <Collapse in={this.state.timeoutModalAlertShowing}>
            <Alert bsStyle="warning">Token refresh failed.</Alert>
          </Collapse>
        </Modal>
        <ProfileEditModal
          closeModal={() =>
            this.setState({
              showProfileEditModal: !this.state.showProfileEditModal
            })
          }
          user={this.props.userStore.currentUser}
          show={this.state.showProfileEditModal}
        />
      </div>
    )
  }
}

const TouchButton = () => {
  return (
    <li>
      <a
        href={
          '//' + process.env.MB_ENV !== 'production'
            ? window.location.hostname
            : 'minerbytes-d2.managed.mst.edu' + '/touch-config'
        }
        target="_blank"
      >
        touch
        <ExternalLink className="external-link" width="16" height="16" />
      </a>
    </li>
  )
}

export default App
