import * as React from 'react'
import {
  Button,
  Col,
  ControlLabel,
  FormControl,
  FormGroup
} from 'react-bootstrap'
import * as Dotdotdot from 'react-dotdotdot'
import {inject} from 'mobx-react'

import {ValidationState} from 'globals'
import {ScreenStore} from 'stores/screenStore'
import {AlertStore} from 'stores/alertStore'
import {AppStateStore} from 'stores/appStateStore'
import MarqueeElement from './MarqueeElement'

interface Props {
  screenStore?: ScreenStore
  alertStore?: AlertStore
  appStateStore?: AppStateStore
}

interface State {
  display: string
  hours: string
  mins: string
  selectedValid: ValidationState
  displayValid: ValidationState
  hoursValid: ValidationState
  minutesValid: ValidationState
  selectedPlaylists: string[]
  disabled: boolean
}

const MARQUEE_CHARMAX = 140
const MARQUEE_REGEX = new RegExp(`^[^\\r\\n]{1,${MARQUEE_CHARMAX}}$`, 'i')
const MARQUEE_NL_REGEX = /(\r\n?|\n)/g
const HOUR_REGEX = /^([01]?[0-9]|2[0-3])$/
const MINUTE_REGEX = /^([0-5]?[0-9])$/

@inject('screenStore', 'alertStore', 'appStateStore')
class SideNavMarquee extends React.Component<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      display: '',
      hours: '',
      mins: '',
      selectedValid: null,
      displayValid: null,
      hoursValid: null,
      minutesValid: null,
      selectedPlaylists: [],
      disabled: false
    }
  }

  handleDisplayChange = event => {
    const stripped = event.target.value.replace(MARQUEE_NL_REGEX, ' ')
    const valid = MARQUEE_REGEX.test(stripped)

    this.setState({
      display: stripped,
      displayValid: valid ? 'success' : 'error'
    })
  }

  handleHoursChange = event => {
    let hoursValue = event.target.value
    if (hoursValue.length > 2) {
      hoursValue = hoursValue.substring(0, 2)
    }

    const hoursMatch = HOUR_REGEX.test(hoursValue)

    let hoursValid = null

    if (hoursMatch) {
      hoursValid = 'success'

      if (this.state.mins.trim()) {
        this.setState({
          minutesValid: 'success'
        })
      }
    } else if (!hoursValue.trim() && !this.state.mins.trim()) {
      hoursValid = 'warning'
    } else {
      hoursValue = ''
      hoursValid = 'error'

      if (this.state.mins.trim()) {
        hoursValid = 'warning'
      }
    }

    this.setState({
      hours: hoursValue,
      hoursValid
    })
  }

  handleMinutesChange = event => {
    let minutesValue = event.target.value
    if (minutesValue.length > 2) {
      minutesValue = minutesValue.substring(0, 2)
    }

    const minutesMatch = MINUTE_REGEX.test(minutesValue)

    let minutesValid = null

    if (minutesMatch) {
      minutesValid = 'success'

      if (this.state.hours.trim()) {
        this.setState({
          hoursValid: 'success'
        })
      }
    } else if (!minutesValue.trim() && !this.state.hours.trim()) {
      minutesValid = 'warning'
    } else {
      minutesValue = ''
      minutesValid = 'error'

      if (this.state.hours.trim()) {
        minutesValid = 'warning'
      }
    }

    this.setState({
      mins: minutesValue,
      minutesValid
    })
  }

  onChildToggle = (id: string) => {
    const updatedPlaylist = this.state.selectedPlaylists
    const screenIndex = updatedPlaylist.indexOf(id)
    if (screenIndex === -1) updatedPlaylist.push(id)
    else updatedPlaylist.splice(screenIndex, 1)

    this.setState({
      selectedValid: updatedPlaylist.length ? 'success' : 'error',
      selectedPlaylists: updatedPlaylist
    })
  }

  get isValid() {
    return (
      this.state.selectedValid === 'success' &&
      this.state.displayValid === 'success' &&
      (this.state.hoursValid === 'success' ||
        this.state.minutesValid === 'success')
    )
  }

  verifySubmission = () => {
    return (
      this.state.selectedPlaylists.length > 0 &&
      (this.state.hours !== '' || this.state.mins !== '') &&
      this.state.display !== '' &&
      (this.state.minutesValid === 'success' ||
        this.state.hoursValid === 'success')
    )
  }

  reset() {
    this.setState({
      display: '',
      hours: '',
      mins: '',
      selectedValid: null,
      displayValid: null,
      hoursValid: null,
      minutesValid: null,
      selectedPlaylists: [],
      disabled: false
    })
  }

  handleSubmit = event => {
    event.preventDefault()

    this.setState({
      disabled: true
    })

    const verified = this.verifySubmission()
    if (verified) {
      const marquee = this.state.display
      const mq_duration = // tslint:disable-line
        Number(this.state.mins || 0) + 60 * Number(this.state.hours || 0)

      this.props.screenStore
        .updateMarquees(
          this.state.selectedPlaylists,
          marquee,
          mq_duration,
          new Date().toISOString()
        )
        .then(resolved => {
          this.props.alertStore.addAlert(
            'Successfully added marquees.',
            'success'
          )
          this.reset()
          this.props.appStateStore.closeSideNav()
        })
        .catch(err => {
          this.props.alertStore.addAlert(
            err,
            'danger',
            'Error adding marquee(s)'
          )
          this.setState({disabled: false})
          throw err
        })
    } else {
      console.error('Form validation failed')
      this.props.alertStore.addAlert('Marquee form validation failed', 'danger')
      this.setState({disabled: false})
    }
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <FormGroup validationState={this.state.selectedValid}>
          <ControlLabel>select signs to display marquee</ControlLabel>
          <div className="screenList">
            {this.props.screenStore.editableScreens.map((screen, i) => (
              <MarqueeElement
                key={i}
                screenId={screen.id}
                screenTitle={screen.title}
                selected={
                  this.state.selectedPlaylists.indexOf(screen.id) !== -1
                }
                marqueeToggle={id => this.onChildToggle(id)}
              />
            ))}
          </div>
          <hr />
        </FormGroup>
        <FormGroup validationState={this.state.displayValid}>
          <ControlLabel>enter text to display in marquee</ControlLabel>
          <FormControl
            componentClass="textarea"
            rows={4}
            placeholder="type text here..."
            value={this.state.display}
            onChange={this.handleDisplayChange}
          />
          <ControlLabel className="charCount">
            {(this.state.display && this.state.display.length) || 0}/
            {MARQUEE_CHARMAX}
          </ControlLabel>
          <hr />
        </FormGroup>
        <ControlLabel>
          enter how long you want the marquee to display
        </ControlLabel>
        <FormGroup className="time">
          <FormGroup className="inline" validationState={this.state.hoursValid}>
            <FormControl
              type="number"
              value={this.state.hours}
              onChange={this.handleHoursChange}
              placeholder="0-23"
              min="0"
              max="23"
            />
            <ControlLabel>Hours</ControlLabel>
          </FormGroup>
          <FormGroup
            className="inline"
            validationState={this.state.minutesValid}
          >
            <FormControl
              type="number"
              inputMode="numeric"
              value={this.state.mins}
              onChange={this.handleMinutesChange}
              placeholder="0-59"
              min="0"
              max="59"
            />
            <ControlLabel>Minutes</ControlLabel>
            <hr />
          </FormGroup>
        </FormGroup>
        <FormGroup>
          <Button type="submit" disabled={!this.isValid || this.state.disabled}>
            Create Marquee
          </Button>
        </FormGroup>
      </form>
    )
  }
}

export default SideNavMarquee
