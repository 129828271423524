import * as React from 'react'
import {Col, Grid, Row} from 'react-bootstrap'

import HelpImageMap, {HelpContent} from './HelpImageMap'

const contentImage = require('images/urls/content.png')
const createAssetImage = require('images/urls/new-asset.png')
const favoritesImage = require('images/urls/content-favorites.png')
const editImage = require('images/urls/edit-content.png')
const addToFavortiesImage = require('images/urls/add-to-favorites.png')
const multiSelectImage = require('images/urls/multi-select.png')
const detailsImage = require('images/urls/asset-details.png')

const newAsset: HelpContent = {
  title: 'New Asset - Create a New Asset',
  body: (
    <Grid>
      <Row>
        <Col md={6}>
          <img className="newasset-image" src={createAssetImage} />
        </Col>
        <Col md={6}>
          <ul>
            <li>
              <b>Asset Title</b> - Name of the asset you are creating.
            </li>
            <li>
              <b>Custom Web URL</b> - URL of a web page to be displayed.
            </li>
            <li>
              <b>Vertical File</b> - The file to be displayed for this asset in
              vertical orientation. This can be either an image or a video file.
            </li>
            <li>
              <b>Horizontal File</b> - The file to be displayed for this asset
              in horizontal orientation. This can be either an image or a video
              file.
            </li>
            <li>
              <b>Vertical File URL</b> - URL to be displayed for this asset in
              vertical orientation. Can be an image or video.
            </li>
            <li>
              <b>Horizontal File URL</b> - URL to be displayed for this asset in
              horizontal orientation. Can be an image or video.
            </li>
            <li>
              <b>Asset Life Span</b> - The start and end date to display this
              asset. The asset will not be displayed if the current date is
              outside this time span.
            </li>
            <li>
              <b>Private</b> - Make this asset only visible to you. If an asset
              is not private it will be shown in the content store.
            </li>
            <li>
              <b>Create Asset</b> - The create asset button. Click this button
              once all the required fields have been filled out.
            </li>
          </ul>
        </Col>
      </Row>
    </Grid>
  ),
  coordinates: {
    left: 1,
    top: 1,
    width: 12,
    height: 16
  }
}

const favorites: HelpContent = {
  title: 'Favorites',
  body: (
    <Grid>
      <Row>
        <Col md={4}>
          <img className="favorites-image" src={favoritesImage} />
        </Col>
        <Col md={5}>
          <p>Select one or more assets and add them to a playlist.</p>
          <p>
            To add an asset to your favorites, select "Add To" under the asset,
            then click "Favorites".
          </p>
          <img className="addtofavorites-image" src={addToFavortiesImage} />
        </Col>
      </Row>
    </Grid>
  ),
  coordinates: {
    left: 1,
    top: 18,
    width: 12,
    height: 17
  }
}

const allMe: HelpContent = {
  title: 'Assets View Mode',
  body: <span>Switch between viewing all assets or assets owned by you.</span>,
  coordinates: {
    left: 14,
    top: 1,
    width: 16,
    height: 11
  }
}

const edit: HelpContent = {
  title: 'Edit Playlist',
  body: (
    <Grid>
      <Row>
        <Col md={6}>
          <img className="edit-image" src={editImage} />
        </Col>
        <Col md={6}>
          <ul>
            <li>
              <b>Asset Title</b> - Edit the name of the asset.
            </li>
            <li>
              <b>Custom Web URL</b> - Modify URL of the web page to be
              displayed.
            </li>
            <li>
              <b>Vertical File</b> - Change the file to be displayed for this
              asset in vertical orientation. This can be either an image or a
              video file.
            </li>
            <li>
              <b>Horizontal File</b> - Change the file to be displayed for this
              asset in horizontal orientation. This can be either an image or a
              video file.
            </li>
            <li>
              <b>Vertical File URL</b> - Modify URL to be displayed for this
              asset in vertical orientation. May be an image or video.
            </li>
            <li>
              <b>Horizontal File URL</b> - Modify URL to be displayed for this
              asset in horizontal orientation. May be an image or video.
            </li>
            <li>
              <b>Asset Life Span</b> - Change the start and end date to display
              this asset. The asset will not be displayed if the current date is
              outside this time span.
            </li>
            <li>
              <b>Private</b> - Make this asset only visible to you. If an asset
              is not private it will be shown in the content store.
            </li>
            <li>
              <b>Delete</b> - Delete the asset permanently.
            </li>
            <li>
              <b>Save</b> - Close edit menu and save changes.
            </li>
            <li>
              <b>Cancel</b> - Close edit menu without saving changes.
            </li>
          </ul>
        </Col>
      </Row>
    </Grid>
  ),
  coordinates: {
    left: 15.5,
    top: 80,
    width: 13.5,
    height: 9
  }
}

const edit2: HelpContent = {
  title: edit.title,
  body: edit.body,
  coordinates: {
    left: 59,
    top: 80,
    width: 13,
    height: 9
  }
}

const addTo: HelpContent = {
  title: 'Add Asset to Playlist',
  body: (
    <div>
      <img className="addtofavorites-image" src={addToFavortiesImage} />
      <br />
      <br />
      <span>Add the asset to a playlist or your favorites list.</span>
    </div>
  ),
  coordinates: {
    left: 28.5,
    top: 80,
    width: 13.5,
    height: 9
  }
}

const addTo2: HelpContent = {
  title: addTo.title,
  body: addTo.body,
  coordinates: {
    left: 72,
    top: 80,
    width: 13.5,
    height: 9
  }
}

const details: HelpContent = {
  title: 'Asset Details',
  body: (
    <Grid>
      <Col md={4}>
        <img className="details-image" src={detailsImage} />
      </Col>
      <Col md={6}>
        <ul>
          <li>
            <strong>Owner</strong> - Name of asset owner.
          </li>
          <li>
            <strong>Description</strong>
            <ul>
              <li>
                <strong>Type</strong> - Asset type (Image, Web, Video)
              </li>
              <li>
                <strong>Orientation</strong> - Orientation type of content
                (Horizontal, Vertical, None)
              </li>
            </ul>
          </li>
          <li>
            <strong>Expires</strong> - Expiration date of asset.
          </li>
          <li>
            <strong>Show Preview</strong> - Click to show a preview of the
            asset.
          </li>
        </ul>
      </Col>
    </Grid>
  ),
  coordinates: {
    left: 42,
    top: 80,
    width: 12.5,
    height: 9
  }
}

const details2: HelpContent = {
  title: details.title,
  body: details.body,
  coordinates: {
    left: 85.5,
    top: 80,
    width: 12.5,
    height: 9
  }
}

const multiSelect: HelpContent = {
  title: 'Add Multiple Assets to Playlist',
  body: (
    <Grid>
      <Row>
        <Col md={6}>
          <img className="multiselect-image" src={multiSelectImage} />
        </Col>
        <Col md={6}>
          <ul>
            <li>Select multiple assets by clicking on their thumbnail.</li>
            <li>
              Select Add To Playlist to add selected assets to a playlist or
              your favorites list.
            </li>
            <li>Click Deselect to clear your selections.</li>
            <li>
              Click the Multi-Select button again to leave multiple selection
              mode.
            </li>
          </ul>
        </Col>
      </Row>
    </Grid>
  ),
  coordinates: {
    left: 77,
    top: 1,
    width: 21.5,
    height: 10.5
  }
}

const viewMore: HelpContent = {
  title: 'View More',
  body: (
    <span>Select 'View More' to view all assets in the current section.</span>
  ),
  coordinates: {
    left: 81,
    top: 92,
    width: 17,
    height: 6.5
  }
}

const imageMap: HelpContent[] = [
  newAsset,
  favorites,
  allMe,
  edit,
  edit2,
  addTo,
  addTo2,
  details,
  details2,
  multiSelect,
  viewMore
]

const HelpAssets: React.StatelessComponent = () => (
  <div className="help-assets">
    <div className="help-content">
      <Grid className="help-grid">
        <Row>
          <h1>Assets</h1>
          <p>
            An asset is simply an item that is shown on a MinerBytes screen.
            Assets are grouped together in playlists to be displayed on a
            screen. A playlist can consist of either a single asset or any
            number of other assets for display.
            <br />
            A playlist can consist of a file uploaded (image or video), a link
            to a web page or YouTube video, or a URL to an image.
          </p>
        </Row>
        <Row>
          <HelpImageMap image={contentImage} content={imageMap} />
        </Row>
      </Grid>
    </div>
  </div>
)

export default HelpAssets
