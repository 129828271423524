import * as React from 'react'
import {observer} from 'mobx-react'
import {Panel, Tabs} from 'react-bootstrap'

import {ContentTab, PlaylistsTab, ScreensTab, UsersTab} from './tabs'

interface Props {
  show: boolean
}

@observer
class AdminPanel extends React.Component<Props> {
  render() {
    return (
      <Panel
        id="admin-panel"
        className={'admin-panel'}
        collapsible
        expanded={this.props.show}
      >
        <Tabs defaultActiveKey={1} id="admin-panel-tabs">
          <ScreensTab eventKey={1} title="Screens" />
          <PlaylistsTab eventKey={2} title="Playlists" />
          <ContentTab eventKey={3} title="Content" />
          <UsersTab eventKey={4} title="Users" />
        </Tabs>
        <div className="please-see-me" />
      </Panel>
    )
  }
}

export default AdminPanel
