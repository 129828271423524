import * as React from 'react'
import {observer} from 'mobx-react'
import {Button, FormGroup, Glyphicon} from 'react-bootstrap'

import Screen from 'stores/Screen'

interface Props {
  screen: Screen
  onHide: () => void
}

interface State {
  disabled: boolean
}

@observer
class StopMarqueeControl extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {disabled: false}
  }

  onSubmit = event => {
    event.preventDefault()

    this.setState({disabled: true})

    const partial = {
      id: Number(this.props.screen.id),
      marquee: null,
      mq_duration: 0,
      mq_created: '1000-01-01 00:00:00'
    }

    this.props.screen
      .update(partial)
      .then((screen: Screen) => {
        this.setState({disabled: false})
        this.props.onHide()
      })
      .catch(err => {
        console.error('Error caught removing screen marquee:', err)
        this.setState({disabled: false})
        this.props.onHide()
      })
  }

  render() {
    return (
      <div className="screen-expanded-control stop-marquee-control">
        <h5 className="sec-title">Stop Marquee</h5>
        <form onSubmit={this.onSubmit}>
          <FormGroup
            controlId={`screen-stop-marquee-form-${this.props.screen.id}`}
          >
            <Button
              id={`screen-stop-marquee-form-${this.props.screen.id}`}
              type="submit"
              bsStyle="danger"
              disabled={!this.props.screen.marquee || this.state.disabled}
            >
              <Glyphicon glyph="remove-circle" />Stop Marquee
            </Button>
          </FormGroup>
        </form>
      </div>
    )
  }
}

export default StopMarqueeControl
