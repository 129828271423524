import * as React from 'react'

import {RealAssetStore} from 'modules/admin/adminstores'
import {AssetDeleteTabClass} from '../BaseDeleteTab'

const AssetDeleteTab: React.StatelessComponent = () => (
  <AssetDeleteTabClass typeName="asset" adminStore={RealAssetStore} />
)

export default AssetDeleteTab
