import * as React from 'react'
import {inject, observer} from 'mobx-react'
import {computed} from 'mobx'
import {Button, ControlLabel, FormGroup, Modal, Panel} from 'react-bootstrap'
import Select from 'react-select'

import {RealScreen} from 'api/realsources'
import {AlertStore} from 'stores/alertStore'
import {RealScreenStore} from 'modules/admin/adminstores'

interface Props {
  alertStore?: AlertStore
}

interface State {
  screen: RealScreen
  disabled: boolean
  showLogModal: boolean
}

interface ScreenDiff {
  id: number
  reboot?: 0 | 1
  send_log?: 0 | 1
}

@inject('alertStore')
@observer
class ScreensMaintenanceTab extends React.Component<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      screen: null,
      disabled: true,
      showLogModal: false
    }
  }

  @computed
  get canReboot() {
    return this.state.screen && !this.state.screen.reboot
  }

  @computed
  get hasLog() {
    return (
      this.state.screen &&
      this.state.screen.log &&
      this.state.screen.log.length > 0
    )
  }

  @computed
  get canSendlog() {
    return this.state.screen && !this.state.screen.send_log
  }

  onSelect = (newValue: RealScreen) => {
    if (newValue instanceof Array)
      this.setState({
        screen: null,
        disabled: true
      })
    else
      this.setState({
        screen: newValue,
        disabled: false
      })
  }

  onClickReboot = () => {
    this.state.screen.reboot = 1
    this.submitter({
      id: this.state.screen.id,
      reboot: 1
    })
  }

  onClickSendlog = () => {
    this.state.screen.send_log = 1
    this.submitter({
      id: this.state.screen.id,
      send_log: 1
    })
  }

  onClickViewLog = () => {
    if (this.hasLog) {
      this.setState({showLogModal: true})
    }
  }

  submitter = (screenUpdate: ScreenDiff): Promise<boolean> => {
    return RealScreenStore.update(screenUpdate, true)
      .then(screen => {
        this.props.alertStore.addAlert(
          'Successfully submitted maintenance request.',
          'success'
        )
        return true
      })
      .catch(err => {
        console.error('Error caught in maintenance request:', err)
        this.props.alertStore.addAlert(
          err,
          'danger',
          'Maintenance request failed'
        )
        return false
      })
  }

  render() {
    return (
      <div id="screen-maintenance" className="admin-inner-tab">
        <span className="description">
          Perform maintenance operations on a screen.
        </span>
        <Select
          value={this.state.screen}
          options={RealScreenStore.values}
          className="screen-maintenance-select"
          getOptionLabel={opt => opt.name}
          getOptionValue={opt => opt.id}
          isMulti={false}
          blurInputOnSelect={true}
          onChange={this.onSelect}
        />
        <Panel collapsible expanded={!this.state.disabled}>
          <FormGroup controlId="screen-maintenance-form-reboot">
            <ControlLabel>Power</ControlLabel>
            <Button
              id="screen-maintenance-form-reboot"
              bsStyle="danger"
              disabled={!this.canReboot}
              title={!this.canReboot ? 'Waiting for reboot...' : null}
              onClick={this.onClickReboot}
            >
              Reboot
            </Button>
          </FormGroup>
          <FormGroup controlId="screen-maintenance-form-sendlog">
            <ControlLabel>Logging</ControlLabel>
            <Button
              id="screen-maintenance-form-sendlog"
              bsStyle="success"
              disabled={!this.canSendlog}
              title={!this.canSendlog ? 'Waiting for log...' : null}
              onClick={this.onClickSendlog}
            >
              Request Log
            </Button>
            <Button
              bsStyle={this.hasLog ? 'info' : 'default'}
              disabled={!this.hasLog}
              onClick={this.onClickViewLog}
            >
              {this.hasLog ? 'View Log' : 'No log...'}
            </Button>
          </FormGroup>
          <Modal
            id="screen-maintenance-form-logmodal"
            show={this.state.showLogModal}
            onHide={() => this.setState({showLogModal: false})}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Screen #{this.state.screen && this.state.screen.id}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <pre>{this.hasLog && this.state.screen.log}</pre>
            </Modal.Body>
          </Modal>
        </Panel>
      </div>
    )
  }
}

export default ScreensMaintenanceTab
