import {computed, observable, ObservableMap} from 'mobx'

import API from 'api'
import userStore from './userStore'
import Notification from './Notification'

/* Should ONLY store notifications directed to you */
export class NotificationStore {
  @observable notificationsMap: ObservableMap<Notification> = observable.map()

  fetchInProgress: Set<string> = new Set()

  @computed
  get notifications(): Notification[] {
    return this.notificationsMap.values()
  }

  @computed
  get uncleared(): Notification[] {
    return this.notificationsMap.values().filter(note => !note.cleared)
  }

  /* Add/update notification to the store */
  add = (notification: Notification) => {
    this.notificationsMap.set(notification.id, notification)
  }

  /* Remove notification from the store */
  remove = (notification: Notification) => {
    this.notificationsMap.delete(notification.id)
  }

  /* Dismiss all notifications */
  dismissAll = () => {
    this.uncleared.forEach(notification => notification.clear())
  }

  /* Fetch notifications from `/api/users/current/notifications` after hitting
      the update endpoint */
  fetchAll = () => {
    API.notifications.load().then(notesR => {
      const myid = userStore.currentUser.id
      notesR.forEach(noteR => this.add(Notification.fromResource(noteR)))
    })
  }

  /* Return the corresponding notification from the store, or request from
      the backend and return null. */
  findById = (id: string): Notification | null => {
    if (!id) throw new Error(`[NotificationStore.findById] Invalid id: ${id}`)

    const note = this.notificationsMap.get(id)
    if (note) return note

    if (!this.fetchInProgress.has(id)) {
      this.fetchInProgress.add(id)
      this.fetchByID(id)
    }
    return null
  }

  /* Request the notification from the backend and add it to the store.
      NOT prefered, as this method does not add 'link' attributes. */
  fetchByID = (id: string | number): Promise<Notification> => {
    if (!id) throw new Error(`[NotificationStore.fetchByID] Invalid id: ${id}`)

    return API.notifications
      .get(id)
      .then(data => {
        const note = Notification.fromResource(data)
        this.add(note)
        this.fetchInProgress.delete(note.id)
        return note
      })
      .catch(res => {
        this.fetchInProgress.delete(id.toString())
        console.error(`NotificationStore.fetchByID( ${id} ) failed:`, res)
        return null
      })
  }
}

export default new NotificationStore()
