import {computed, observable, ObservableMap} from 'mobx'

import {PermissionLevel} from 'globals'
import userStore from './userStore'

const DEFAULT_PERM_LEVEL: PermissionLevel = 'viewer'

class UserPermissions {
  isCreatorType: boolean
  @observable userPermissionMap: ObservableMap<PermissionLevel>
  @observable ownerID: string

  constructor(ownerID: string, isCreatorType?: boolean) {
    this.userPermissionMap = new ObservableMap()
    this.userPermissionMap.set(
      String(ownerID),
      isCreatorType ? 'creator' : 'owner'
    )
    this.isCreatorType = isCreatorType || false
    this.ownerID = ownerID
  }

  @computed
  get myPermission() {
    return this.getPermission(userStore.currentUser.id)
  }

  getPermission = (userID: string) => this.userPermissionMap.get(userID)

  setPermission = (userID: string, permLevel: PermissionLevel) =>
    this.userPermissionMap.set(String(userID), permLevel)

  unshareTo = (userID: string) => this.userPermissionMap.delete(userID)

  changeOwner = (newOwnerID: string) => {
    this.userPermissionMap.set(this.ownerID, 'editor')
    this.userPermissionMap.set(
      newOwnerID,
      this.isCreatorType ? 'creator' : 'owner'
    )

    this.ownerID = newOwnerID
  }

  @computed
  get users() {
    return observable.array(
      this.userPermissionMap
        .keys()
        .map(id => userStore.findById(id))
        .filter(Boolean)
    )
  }

  @computed
  get usersSortedForDisplay() {
    return this.users.sort((a, b) => {
      // Sorts by current user, then owner, then alphabetically by first name

      if (a.id === userStore.currentUser.id) {
        return -1
      } else if (b.id === userStore.currentUser.id) {
        return 1
      } else if (a.id === this.ownerID) {
        return -1
      } else if (b.id === this.ownerID) {
        return 1
      } else if (a.fname < b.fname) {
        return -1
      } else if (a.fname > b.fname) {
        return 1
      }
      return 0
    })
  }
}

export default UserPermissions
