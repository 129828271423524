import * as React from 'react'
import * as moment from 'moment'

const Footer: React.StatelessComponent = () => (
  <footer id="landing-footer" className="text-centered container-fluid">
    <div className="row">
      <div className="col-md-6">
        <p>
          Developed by Missouri S&T{' '}
          <a href="https://itrss.mst.edu" className="green" rel="noopener">
            IT Research Support Services
          </a>
        </p>
        <p>© {moment().format('MMM YYYY')}. All rights reserved.</p>
      </div>
      <div className="col-md-6">
        <p>
          <a href="https://docs.google.com/document/d/16Csy2myRLBs1ohu6gN68TsdKos3AK9q7OwcxTaODYUE/export?format=html&attachment=false">
            Terms of Service
          </a>
          {' - '}
          <a href="https://docs.google.com/document/d/16bs9fGdKSkxRAs7pNfeTNOjEgVbQ_p90b52U_NcyR38/export?format=html&attachment=false">
            DMCA/Copyright
          </a>
        </p>
        <p>
          <a href="https://docs.google.com/document/d/1o3wJ3vvSRKJhsut6pT8PL3uXqQsXoeYO01OqRJfw5JM/export?format=html&attachment=false">
            Privacy Policy
          </a>
        </p>
      </div>
    </div>
  </footer>
)

export default Footer
