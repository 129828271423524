import * as React from 'react'
import {Tab, Tabs} from 'react-bootstrap'

import BaseAdminTab from './BaseAdminTab'
import IndexTab from './content/IndexTab'
import DeleteTab from './content/DeleteTab'
import MaintenanceTab from './content/MaintenanceTab'

class ContentTab extends BaseAdminTab {
  getContents = () => {
    return (
      <Tabs id="admin-panel-content-tabs">
        <Tab eventKey={1} title="Info">
          <IndexTab />
        </Tab>
        <Tab eventKey={4} title="Delete">
          <DeleteTab />
        </Tab>
        <Tab eventKey={5} title="Maint.">
          <MaintenanceTab />
        </Tab>
      </Tabs>
    )
  }
}

export default ContentTab
