import {localStorageCheck, NOBACKEND} from 'globals'
import HomeNav from './navs/HomeNav'
import App from './navs/App'
import auth from './auth'

// Makes TypeScript happy with webpack's System.import
declare global {
  interface System {
    import(request: string): Promise<any>
  }
  var System: System
}

function requireAuth(nextState, replace) {
  if (!localStorageCheck()) {
    replace('/#signinerror=Browser%20cannot%20set%20token')
    return
  } else if (!auth.loggedIn()) {
    replace('/#signinerror=Not%20Signed%20In')
  }
}

function returnUser(nextState, replace) {
  if (auth.loggedIn()) {
    replace('/screens')
    return
  }
}

function oauth2callback(nextState, replace, cb) {
  if (NOBACKEND) {
    replace('/')
    cb()

    return
  }

  if (!localStorageCheck()) {
    window.location.replace('/401#error=Browser%20cannot%20set%20token')
    cb()

    return
  }

  const {pathname, query, hash} = nextState.location
  if (hash) {
    const hashQueries = auth.parseHash(hash.substring(1))

    if (hashQueries.access_token) {
      // received access_token, try to log in with it
      auth.login(hashQueries.access_token, (isLoggedIn, err) => {
        replace(
          isLoggedIn ? '/screens' : '/#signinerror=' + encodeURIComponent(err)
        )
        cb()
      })

      return
    } else if (hashQueries.error) {
      // received error from oauth2 provider, show it to user
      replace('/#signinerror=' + hashQueries.error)
      cb()

      return
    }
  }
  // received unknown hash or no hash at all
  replace('/#signinerror=OAuth2%20Callback%20expected%20a%20token')
  cb()
}

function errorLoading(error) {
  throw new Error(`Dynamic page loading failed: ${error}`)
}

function loadRoute(cb) {
  return module => cb(null, module.default)
}

export default [
  {
    path: '/',
    childRoutes: [
      // Authenticated Routes
      {
        component: App,
        onEnter: requireAuth,
        childRoutes: [
          {
            path: 'screens',
            getComponent(location, cb) {
              import('./screens/Screens')
                .then(loadRoute(cb))
                .catch(errorLoading)
            }
          },
          {
            path: 'playlists',
            indexRoute: {
              getComponent(location, cb) {
                import('./playlists/Playlists')
                  .then(loadRoute(cb))
                  .catch(errorLoading)
              }
            },
            childRoutes: [
              {
                path: ':id/edit',
                getComponent(location, cb) {
                  import('./playlists/EditPlaylist')
                    .then(loadRoute(cb))
                    .catch(errorLoading)
                }
              }
            ]
          },
          {
            path: 'content',
            getComponent(location, cb) {
              import('./content/Content')
                .then(loadRoute(cb))
                .catch(errorLoading)
            }
          },
          {
            path: 'help',
            getComponent(location, cb) {
              import('./help/HelpNav')
                .then(loadRoute(cb))
                .catch(errorLoading)
            },
            indexRoute: {
              getComponent(location, cb) {
                import('./help/Help')
                  .then(loadRoute(cb))
                  .catch(errorLoading)
              }
            },
            childRoutes: [
              {
                path: 'assets',
                getComponent(location, cb) {
                  import('./help/HelpAssets')
                    .then(loadRoute(cb))
                    .catch(errorLoading)
                }
              },
              {
                path: 'screens',
                getComponent(location, cb) {
                  import('./help/HelpScreens')
                    .then(loadRoute(cb))
                    .catch(errorLoading)
                }
              },
              {
                path: 'playlists',
                getComponent(location, cb) {
                  import('./help/HelpPlaylists')
                    .then(loadRoute(cb))
                    .catch(errorLoading)
                }
              },
              {
                path: 'wifi',
                getComponent(location, cb) {
                  import('./help/HelpWifi')
                    .then(loadRoute(cb))
                    .catch(errorLoading)
                }
              }
            ]
          }
        ]
      },

      // Non-Authenticated Routes
      {
        component: HomeNav,
        onEnter: returnUser,
        indexRoute: {
          getComponent(location, cb) {
            import('./home/Home')
              .then(loadRoute(cb))
              .catch(errorLoading)
          }
        },

        childRoutes: [
          {
            path: 'get',
            getComponent(location, cb) {
              import('./home/Get')
                .then(loadRoute(cb))
                .catch(errorLoading)
            }
          },
          {
            path: 'guides',
            getComponent(location, cb) {
              import('./home/Guides')
                .then(loadRoute(cb))
                .catch(errorLoading)
            }
          },
          {
            path: 'oauth2callback',
            onEnter: oauth2callback
          }
        ]
      }
    ]
  },
  {
    path: '401',
    getComponent(location, cb) {
      import('./NotFound')
        .then(loadRoute(cb))
        .catch(errorLoading)
    }
  },
  {
    path: '*',
    getComponent(location, cb) {
      import('./NotFound')
        .then(loadRoute(cb))
        .catch(errorLoading)
    }
  }
]
