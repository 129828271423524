import * as React from 'react'
import {computed} from 'mobx'

export interface FileObject extends File {
  lastModified: number // standard attribute Typescript doesn't understand
  preview?: string // from react-dropzone
}

function isFileDifferent(a: FileObject, b: FileObject): boolean {
  return (
    a.name !== b.name || a.size !== b.size || a.lastModified !== b.lastModified
  )
}

interface Props {
  orientation: 'vertical' | 'horizontal'
  file: FileObject
}

interface State {
  objectURL: string
}

class AssetFormVideoPreview extends React.Component<Props, State> {
  @computed
  get type() {
    this.props.file // tslint:disable-line
    this.state.objectURL // tslint:disable-line

    if (this.props.file && this.props.file.type && this.state.objectURL) {
      return this.props.file.type
    }

    return null
  }

  @computed
  get dimensions() {
    switch (this.props.orientation) {
      case 'vertical':
        return [99, 176]
      case 'horizontal':
        return [176, 99]
      default:
        return [0, 0]
    }
  }

  constructor(props: Props) {
    super(props)

    this.state = {
      objectURL: this.getPreview()
    }
  }

  getPreview = () => {
    if (this.props.file && this.props.file.size) {
      if (this.props.file.preview) {
        return this.props.file.preview
      }

      return URL.createObjectURL(this.props.file)
    }

    return null
  }

  componentWillReceiveProps(nextProps: Props) {
    if (this.props.file !== nextProps.file) {
      if (this.state.objectURL) {
        URL.revokeObjectURL(this.state.objectURL)
      }

      this.setState({
        objectURL: this.getPreview()
      })
    }
  }

  render() {
    return (
      <video width={this.dimensions[0]} height={this.dimensions[1]} controls>
        <source src={this.state.objectURL} type={this.type} />
      </video>
    )
  }
}

export default AssetFormVideoPreview
