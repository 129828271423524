import {computed, IObservableArray, observable, ObservableMap} from 'mobx'
import * as Fuse from 'fuse.js'

import API from 'api'
import userStore from './userStore'
import playlistStore from './playlistStore'
import Asset from './Asset'

export class ContentStore {
  @observable assetsMap: ObservableMap<Asset> = observable.map()
  @observable myAssetIDs: IObservableArray<string> = observable.array()
  @observable sharedAssetIDs: IObservableArray<string> = observable.array()
  @observable institutionAssetIDs: IObservableArray<string> = observable.array()
  @observable departmentAssetIDs: IObservableArray<string> = observable.array()
  @observable recentAssetIDs: IObservableArray<string> = observable.array()
  @observable favoriteIDs: IObservableArray<string> = observable.array()
  @observable searchResults: IObservableArray<Asset> = observable.array()
  @observable searching: boolean = false

  fetchInProgress: Set<string> = new Set()

  options: Fuse.FuseOptions<Asset> = {
    caseSensitive: false,
    shouldSort: true,
    threshold: 0.4,
    keys: ['title', 'description', 'ownerString', 'ownerUsername', 'ownerEmail']
  }

  @computed
  get assets(): Asset[] {
    return this.assetsMap.values()
  }

  @computed
  get recentAssets(): Asset[] {
    return this.recentAssetIDs.map(id => this.assetsMap.get(id))
  }

  @computed
  get myAssets(): Asset[] {
    return this.myAssetIDs.map(asset => this.assetsMap.get(asset)).reverse()
  }

  @computed
  get sharedAssets(): Asset[] {
    return this.sharedAssetIDs.map(this.assetsMap.get).reverse()
  }

  @computed
  get institutionAssets(): Asset[] {
    return this.institutionAssetIDs.map(id => this.assetsMap.get(id)).reverse()
  }

  @computed
  get departmentAssets(): Asset[] {
    return this.departmentAssetIDs.map(id => this.assetsMap.get(id)).reverse()
  }

  @computed
  get favorites(): Asset[] {
    // return this.favoriteIDs.map(id => this.assetsMap.get(id)).filter(Boolean)
    return this.assetsMap
      .values()
      .map(asset => {
        if (asset.isFavorite || this.favoriteIDs.indexOf(asset.id) !== -1)
          return asset
      })
      .filter(Boolean)
  }

  @computed
  get fuse(): Fuse<Asset> {
    return new Fuse(this.assets, this.options)
  }

  @computed
  get mySearchResults(): Asset[] {
    return this.searchResults.filter(
      asset => asset.owner === userStore.currentUser
    )
  }

  /* Add/update asset to the store */
  add = (asset: Asset): void => {
    const oldAsset = this.get(asset.id)
    if (oldAsset) {
      oldAsset.update(asset)
    } else {
      asset.loadFiles()
      this.assetsMap.set(asset.id, asset)
    }
  }

  /* Add a new asset that was just created */
  addNewAsset = (asset: Asset): void => {
    this.add(asset)
    this.myAssetIDs.push(asset.id)
    this.recentAssetIDs.unshift(asset.id)
    this.departmentAssetIDs.push(asset.id)
    this.institutionAssetIDs.push(asset.id)
  }

  get = (id: string): Asset => {
    return this.assetsMap.get(id)
  }

  /* Remove asset from the store */
  remove = (asset: Asset): void => {
    const id = asset.id
    this.assetsMap.delete(id)
    this.myAssetIDs.remove(id)
    this.sharedAssetIDs.remove(id)
    this.institutionAssetIDs.remove(id)
    this.departmentAssetIDs.remove(id)
    this.recentAssetIDs.remove(id)
  }

  fetch = (id: string): Promise<Asset> => {
    if (!id) throw new Error(`[ContentStore.fetch] Invalid id: ${id}`)

    return new Promise((resolve, reject) => {
      const asset = this.get(id)

      if (asset) return resolve(asset)

      API.assets
        .get(id)
        .then(resource => {
          const assetFromResource = Asset.fromResource(resource)
          this.add(assetFromResource)
          return assetFromResource
        })
        .then(resolve)
        .catch(reject)
    })
  }

  fetchForPlaylist = (playlistID: string): Promise<Asset[]> => {
    return new Promise((resolve, reject) => {
      playlistStore.fetch(playlistID).then(playlist => {
        const assets: Asset[] = playlist.assetIDs
          .map(id => this.get(id))
          .filter(Boolean)
        const haveAllAssets: boolean =
          assets.length === playlist.assetIDs.length
        if (haveAllAssets) return resolve(assets)

        API.playlists
          .assets(playlist.id)
          .then(data => data.map(resource => Asset.fromResource(resource)))
          .then(apiAssets => {
            apiAssets.forEach(asset => this.add(asset))
            resolve(apiAssets)
          })
      })
    })
  }

  /* Return the corresponding asset from the store, or request from
      the backend and return null */
  findById = (id: string): Asset | null => {
    if (!id) throw new Error(`[ContentStore.findById] Invalid id: ${id}`)

    const item = this.assetsMap.get(id)
    if (item) return item
    else {
      if (!this.fetchInProgress.has(id)) {
        this.fetchInProgress.add(id)
        this.fetchByID(id)
      }
      return null
    }
  }

  assetReducer = (map: {}, asset: Asset) => {
    map[asset.id] = asset
    return map
  }

  /* Request the asset from the backend and add it to the store */
  fetchByID = (id: string | number): Promise<Asset> => {
    if (!id) throw new Error(`[ContentStore.fetchByID] Invalid id: ${id}`)

    return API.assets
      .get(id)
      .then(data => {
        const asset = Asset.fromResource(data)
        this.add(asset)
        this.fetchInProgress.delete(asset.id)
        return asset
      })
      .catch(data => {
        this.fetchInProgress.delete(id.toString())
        console.error(`ContentStore.fetchByID( ${id} ) failed:`, data)
        return null
      })
  }

  /* Fetch assets from `/api/users/current/assets` and updates
      this.myAssetIDs and this.sharedAssetIDs */
  fetchShared = (): void => {
    API.current.favorites().then(data => {
      this.favoriteIDs.replace(data.map(String))
    })

    API.current.assets().then(data => {
      const mine = []
      const shared = []
      const myid = userStore.currentUser.id
      data.forEach(assetR => {
        const asset = Asset.fromResource(assetR)
        this.add(asset)

        shared.push(asset.id)
        if (asset.ownerID === myid) {
          mine.push(asset.id)
        }
      })
      this.myAssetIDs.replace(mine)
      this.sharedAssetIDs.replace(shared)
    })

    API.current.assetsByInstitution().then(data => {
      data.forEach(resource => {
        const asset = Asset.fromResource(resource)
        this.add(asset)
        this.institutionAssetIDs.push(asset.id)
      })
    })

    API.current.assetsByDepartment().then(data => {
      data.forEach(resource => {
        const asset = Asset.fromResource(resource)
        this.add(asset)
        this.departmentAssetIDs.push(asset.id)
      })
    })

    API.assets.recent().then(data => {
      data.forEach(resource => {
        const asset = Asset.fromResource(resource)
        this.add(asset)
        this.recentAssetIDs.push(asset.id)
      })
    })
  }

  /* Register a newly created asset with the backend */
  create = (assetAttrs, files): Promise<Asset> => {
    const tempAsset = new Asset(assetAttrs)
    return tempAsset
      .create(files)
      .then(asset => {
        this.addNewAsset(asset)
        return asset
      })
      .catch(err => {
        console.error(err)
        let message = 'Error Ocurred: '
        let validations = null

        if (err.response && err.response.data) {
          if (typeof err.response.data.data === 'string') {
            message += err.response.data.data
          }

          if (err.response.data.data)
            validations = err.response.data.data.form_validations
        } else if (typeof err === 'string') {
          message += err
        }

        throw {
          message,
          validations
        }
      })
  }

  /* Delete an asset from the backend */
  delete = (asset: Asset): void => {
    API.assets.delete(asset.asResource()).then(() => {
      this.remove(asset)
    })
  }

  previewWebURL = (url: string): Promise<string> => {
    return API.files.previewWebURL(url)
  }

  search = (term: string): void => {
    this.searchResults.replace(this.fuse.search(term))
    this.searching = term !== '' ? true : false
  }
}

export default new ContentStore()
