import * as React from 'react'
import {computed} from 'mobx'
import {inject, observer} from 'mobx-react'
import {Button, Glyphicon} from 'react-bootstrap'

import {UserStore} from 'stores/userStore'
import AdminPanel from './Panel'

interface Props {
  userStore?: UserStore
}

interface State {
  showPanel: boolean
  panelFloat: 'left' | 'right'
}

@inject('userStore')
@observer
class AdminPanelOpener extends React.Component<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      showPanel: false,
      panelFloat: localStorage.adminPanelFloat || 'right'
    }
  }

  @computed
  get isAdmin() {
    return this.props.userStore.currentUser
      ? this.props.userStore.currentUser.isAdmin
      : null
  }

  changeFloatSide = () => {
    const panelFloat = this.state.panelFloat === 'right' ? 'left' : 'right'

    localStorage.adminPanelFloat = panelFloat

    this.setState({panelFloat})
  }

  render() {
    return this.isAdmin ? (
      <div id="admin-panel-opener" data-float={this.state.panelFloat}>
        <Glyphicon
          id="admin-panel-floatleft"
          className="admin-panel-float-button"
          glyph="chevron-left"
          onClick={this.changeFloatSide}
        />
        <Button
          id="admin-panel-button"
          onClick={() => this.setState({showPanel: !this.state.showPanel})}
          active={this.state.showPanel}
        >
          <Glyphicon glyph="dashboard" /> Admin
        </Button>
        <Glyphicon
          id="admin-panel-floatright"
          className="admin-panel-float-button"
          glyph="chevron-right"
          onClick={this.changeFloatSide}
        />

        <AdminPanel show={this.state.showPanel} />
      </div>
    ) : null
  }
}

export default AdminPanelOpener
