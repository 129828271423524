import * as React from 'react'
import {inject, observer} from 'mobx-react'
import {Alert as BSAlert} from 'react-bootstrap'

import Alert from 'stores/Alert'
import {AlertStore} from 'stores/alertStore'

interface Props {
  alert: Alert
}

const AlertComponent: React.StatelessComponent<Props> = inject('alertStore')(
  observer(props => (
    <BSAlert
      className="aalert"
      bsStyle={props.alert.bsStyle}
      onDismiss={() => props.alertStore.clearAlert(props.alert)}
      onClick={() => props.alertStore.clearAlert(props.alert)}
    >
      {props.alert.title && (
        <h4 className="alert-heading">{props.alert.title}</h4>
      )}
      <p>{props.alert.message}</p>
    </BSAlert>
  ))
)

export default AlertComponent
