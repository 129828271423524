import * as React from 'react'
import {Overlay, OverlayTrigger, Popover} from 'react-bootstrap'
import {Link} from 'react-router'

import Playlist from 'stores/Playlist'
import PlaylistElement from 'modules/playlists/PlaylistElement'

const ShareIcon = require('images/elements/share-icon.svg')

interface Props {
  playlist: Playlist
  previewAssets: JSX.Element[]
  tooltip: JSX.Element
  tooltipContainer: PlaylistElement
  numAssetsText: string
  canEdit: boolean
  showingShare: boolean
  toggleShare: () => void
  isOwner: boolean
  showDelete: boolean
  toggleDelete: () => void
  deleteConfirmedClicked: () => void
}

const PlaylistCard: React.StatelessComponent<Props> = props => {
  let shareButtonRef: HTMLButtonElement
  let deleteButtonRef: HTMLButtonElement

  return (
    <div className="playlist-grid-container">
      <div className="playlist-grid">
        <div className="image-preview">{props.previewAssets}</div>
        {props.playlist.inUse && <p className="in-use">IN USE</p>}
        <h2
          className="playlist-title"
          title={props.playlist.title.length > 13 ? props.playlist.title : null}
        >
          {props.playlist.title}
        </h2>
        <h3 className="playlist-author">
          <span className="full-name-split">
            <span className="first-name">{props.playlist.owner.fname}</span>
            <span className="last-name">{props.playlist.owner.lname}</span>
          </span>
        </h3>
        <p className="playlist-info">
          {props.numAssetsText}
          {props.playlist.orientation && ` - ${props.playlist.orientation}`}
        </p>
        <OverlayTrigger
          placement="bottom"
          overlay={props.tooltip}
          container={props.tooltipContainer}
        >
          <div className="share-info">
            <ShareIcon className="share-icon" />
            {props.playlist.sharedWith.length !== 0 && (
              <p className="shared-number">
                {props.playlist.sharedWith.length}
              </p>
            )}
          </div>
        </OverlayTrigger>
        <div className="playlist-controls">
          <Link to={`/playlists/${props.playlist.id}/edit`}>
            <button disabled={!props.canEdit} className="playlist-edit">
              Edit
            </button>
          </Link>
          <button
            className={
              props.showingShare ? 'playlist-share active' : 'playlist-share'
            }
            ref={ref => (shareButtonRef = ref)}
            onClick={props.toggleShare}
          >
            Share
          </button>
          <button
            disabled={!props.isOwner}
            className={`playlist-delete${props.showDelete ? ' active' : ''}`}
            ref={ref => (deleteButtonRef = ref)}
            onClick={props.toggleDelete}
          >
            Delete
          </button>
          <Overlay
            rootClose
            show={props.showDelete}
            onHide={() => (props.showDelete ? props.toggleDelete() : null)}
            placement="bottom"
            target={() => deleteButtonRef}
          >
            <Popover
              id="popover-trigger-click-root-close"
              className="delete"
              title="ARE YOU SURE?"
            >
              <div className="popover-container">
                <button className="cancel" onClick={props.toggleDelete}>
                  CANCEL
                </button>
                <button
                  className="deleteConfirm"
                  onClick={props.deleteConfirmedClicked}
                >
                  YES
                </button>
              </div>
            </Popover>
          </Overlay>
        </div>
      </div>
    </div>
  )
}

export default PlaylistCard
