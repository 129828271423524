import {computed, observable, ObservableMap} from 'mobx'
import {AxiosError, AxiosResponse} from 'axios'

import API from 'api'
import {ShowtimeResource, ShowtimeResource_Partial} from 'api/resources'
import Showtime from './Showtime'

export class ShowtimeStore {
  @observable showtimesMap: ObservableMap<Showtime> = observable.map()

  fetchInProgress: Set<string> = new Set()

  readonly Showtime: typeof Showtime = Showtime

  @computed
  get showtimes(): Showtime[] {
    return this.showtimesMap.values()
  }

  /* Add/update showtime to the store */
  add = (st: Showtime): void => {
    this.showtimesMap.set(st.id, st)
  }

  /* Remove showtime from the store */
  remove = (st: Showtime): void => {
    this.showtimesMap.delete(st.id)
  }

  removePlaylist = (plID: string): void => {
    this.getPlaylistShowtimes(plID).forEach(st => this.remove(st))
  }

  /* Return the corresponding screen from the store, or request it from
      the backend and return null */
  findById = (id: string): Showtime | null => {
    if (!id) throw new Error(`[showtimeStore.findById] Invalid id: ${id}`)

    const item = this.showtimesMap.get(id)
    if (item) return item
    else {
      if (!this.fetchInProgress.has(id)) {
        this.fetchInProgress.add(id)
        this.fetchByID(id)
      }
      return null
    }
  }

  fetchByID = (id: string | number): Promise<Showtime> => {
    if (!id) throw new Error(`[showtimeStore.fetchByID] Invalid id: ${id}`)

    return API.showtimes
      .get(id)
      .then(data => {
        const st = Showtime.fromResource(data)
        this.add(st)
        this.fetchInProgress.delete(String(id))
        return st
      })
      .catch(data => {
        this.fetchInProgress.delete(String(id))
        return null
      })
  }

  getScreenShowtimes = (screenID: string): Showtime[] => {
    return this.showtimesMap.values().filter(st => st.screenID === screenID)
  }

  getPlaylistShowtimes = (playlistID: string): Showtime[] => {
    return this.showtimesMap.values().filter(st => st.playlistID === playlistID)
  }

  getComboShowtimes = (screenID: string, playlistID: string): Showtime[] => {
    return this.getScreenShowtimes(screenID).filter(
      st => st.playlistID === playlistID
    )
  }

  diff = (showtime: Showtime): ShowtimeResource_Partial => {
    const orig = this.showtimesMap.get(showtime.id)
    const diff: ShowtimeResource_Partial = {id: Number(showtime.id)}
    if (orig) {
      if (showtime.day_start !== orig.day_start)
        diff.day_start = showtime.day_start
      if (showtime.time_start !== orig.time_start)
        diff.time_start = showtime.time_start
      if (showtime.duration !== orig.duration) diff.duration = showtime.duration
    }

    return diff
  }

  create = (showtime: Showtime): Promise<Showtime | void> => {
    return API.showtimes
      .create(showtime.asResource())
      .then((resource: ShowtimeResource) => {
        const newShowtime = Showtime.fromResource(resource)
        this.add(newShowtime)
        return newShowtime
      })
      .catch((err: AxiosError) => {
        console.error(`Error caught on create-showtime`, err)
        throw new Error('Failed to create showtime')
      })
  }

  delete = (showtime: Showtime) => {
    return API.showtimes
      .delete(showtime.id)
      .then(res => {
        if (res.status === 204) {
          this.remove(showtime)
        } else {
          throw res
        }
      })
      .catch(err => {
        console.error(`Error caught on delete-showtime #${showtime.id}`, [err])
        throw new Error('Failed to delete showtime')
      })
  }

  update = (partial: ShowtimeResource_Partial, showtime: Showtime) => {
    return API.showtimes
      .update(partial)
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          this.add(showtime)
        } else {
          throw res
        }
      })
      .catch((err: AxiosError) => {
        console.error(`Error caught on update-showtime #${showtime.id}`, [err])
        throw new Error('Failed to update showtime')
      })
  }
}

export default new ShowtimeStore()
