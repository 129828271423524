import * as React from 'react'
import {Col} from 'react-bootstrap'
import * as Dotdotdot from 'react-dotdotdot'

interface Props {
  screenId: string
  screenTitle: string
  selected: boolean
  marqueeToggle: (id: string) => void
}

const MarqueeElement: React.StatelessComponent<Props> = props => (
  <div onClick={() => props.marqueeToggle(props.screenId)}>
    <Col xs={6}>
      <div className={`screenSelector col-6${props.selected ? ' active' : ''}`}>
        <Dotdotdot clamp={2} className="vertical-child">
          {props.screenTitle}
        </Dotdotdot>
      </div>
    </Col>
  </div>
)

export default MarqueeElement
