import * as React from 'react'
import {computed} from 'mobx'
import {inject, observer} from 'mobx-react'

import {AppStateStore} from 'stores/appStateStore'
import SVGButton from 'modules/common/SVGButton'
import SideNavSelector from './SideNavSelector'
import DisplayBox from './SideNavDisplayBox'

interface Props {
  customClass: string
  svgIcon: any
  title: string
  appStateStore?: AppStateStore
}

interface State {
  closerDiv?: any
}

@inject('appStateStore')
@observer
class SideNavElement extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
  }

  componentWillMount() {
    this.props.appStateStore.closeSideNav()
  }

  @computed
  get closerDiv() {
    return !this.isMinimized ? (
      <div
        className="menu-closer"
        onClick={() => {
          this.props.appStateStore.closeSideNav()
        }}
      />
    ) : null
  }

  handleClick = () => {
    this.props.appStateStore.openSideNav = this.isMinimized
      ? this.props.title
      : ''
  }

  @computed
  get isMinimized() {
    return this.props.appStateStore.openSideNav !== this.props.title
  }

  render() {
    return (
      <div className={'side-nav-element ' + this.props.customClass}>
        <SideNavSelector
          minimized={this.isMinimized}
          customClass={this.props.customClass}
        />
        <SVGButton
          svgIcon={this.props.svgIcon}
          onClick={this.handleClick}
          text={this.props.title}
          active={!this.isMinimized}
        />
        <div className={'side-nav-display-wrapper ' + this.props.customClass}>
          <DisplayBox
            type={this.props.customClass}
            title={this.props.title}
            customClass={this.props.customClass}
            minimized={this.isMinimized}
            closeClicked={this.handleClick}
          >
            {this.props.children}
          </DisplayBox>
        </div>
        {this.closerDiv}
      </div>
    )
  }
}

export default SideNavElement
