import * as React from 'react'
import {observer} from 'mobx-react'
import {computed} from 'mobx'
import {GoogleMap, Marker, withGoogleMap, withScriptjs} from 'react-google-maps'
import {Button, ControlLabel, FormControl, FormGroup} from 'react-bootstrap'

import {GOOGLE_MAPS_KEY, LatLngType, SandTLATLNG} from 'globals'
import Screen from 'stores/Screen'
import {ScreenResource_Partial} from 'api/resources'

const activePin = require('images/urls/active-pin.svg')

const MAP_URL =
  'https://maps.googleapis.com/maps/api/js?key=' +
  GOOGLE_MAPS_KEY +
  '&libraries=drawing,geometry'
const MIN_DELTA = 0.00001

interface Props {
  screen: Screen
  disabled: boolean // if disabled, user can only view the location
}

interface State {
  status: string
  location: string
  lat: number
  lon: number
  marker: object
}

interface MapClickEvent extends Event {
  latLng: LatLngType
}

@observer
class LocationControl extends React.Component<Props, State> {
  locationMap = withScriptjs(
    withGoogleMap(props => (
      <GoogleMap
        ref={props.onMapLoad}
        zoom={14}
        defaultCenter={
          this.coords && this.coords.lat ? this.coords : SandTLATLNG
        }
        options={{
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
          draggable: props.draggable || false
        }}
        onClick={props.onMapClick}
      >
        {props.markers && (
          <Marker
            {...props.markers}
            icon={{
              url: activePin,
              scaledSize: new (window as any).google.maps.Size(26, 35)
            }}
            title={this.props.screen.title}
          />
        )}
      </GoogleMap>
    ))
  )

  constructor(props: Props) {
    super(props)

    if (this.props.screen.lat && this.props.screen.lon) {
      const lat = this.props.screen.lat
      const lon = this.props.screen.lon

      this.state = {
        status: null,
        location: this.props.screen.location,
        lat,
        lon,
        marker: {
          position: {lat, lng: lon},
          key: this.props.screen.title
        }
      }
    } else {
      this.state = {
        status: null,
        location: this.props.screen.location,
        lat: null,
        lon: null,
        marker: null
      }
    }
  }

  @computed
  get coords() {
    if (this.state.lat && this.state.lon) {
      return {
        lat: this.state.lat,
        lng: this.state.lon
      } as LatLngType
    }

    return null
  }

  @computed
  get locationChanged() {
    const value = this.state.location ? this.state.location.trim() : ''

    if (!value || value === this.props.screen.location) {
      return false
    }

    return true
  }

  @computed
  get coordsChanged() {
    const scrLat = this.props.screen.lat
    const scrLon = this.props.screen.lon
    const lat = this.state.lat
    const lon = this.state.lon

    if ((!scrLat && lat) || (!scrLon && lon)) {
      // newly added position
      return true
    } else if (
      Math.abs(scrLat - lat) > MIN_DELTA ||
      Math.abs(scrLon - lon) > MIN_DELTA
    ) {
      // position change is greater than minimum delta
      return true
    }

    return false
  }

  @computed
  get canSubmit() {
    return this.coordsChanged || this.locationChanged
  }

  onLocationChange = event => {
    const value = event.target.value.trim()

    if (value !== this.state.location) {
      this.setState({location: value})
    }
  }

  onMapClick = event => {
    const lat = event.latLng.lat()
    const lon = event.latLng.lng()

    if (!this.props.disabled) {
      this.setState({
        lat,
        lon,
        marker: {
          position: event.latLng,
          key: this.props.screen.title
        }
      })
    }
  }

  onReset = () => {
    const lat = this.props.screen.lat
    const lon = this.props.screen.lon

    this.setState({
      status: null,
      location: this.props.screen.location,
      lat,
      lon,
      marker: {
        position: {lat, lng: lon},
        key: this.props.screen.title
      }
    })
  }

  onSubmit = event => {
    event.preventDefault()

    if (this.canSubmit) {
      const screenPart = {
        id: Number(this.props.screen.id)
      } as ScreenResource_Partial
      if (this.coordsChanged) {
        screenPart.lat = this.state.lat
        screenPart.lon = this.state.lon
      }
      if (this.locationChanged) {
        screenPart.location = this.state.location
      }

      this.props.screen
        .update(screenPart)
        .then(() => {
          this.setState({status: 'Success!'})
        })
        .catch(err => {
          console.error('Error caught updating screen location:', err)
          this.setState({status: 'Failed'})
        })
    }
  }

  render() {
    return (
      <div
        className={
          'screen-expanded-control location-control' +
          (!this.props.disabled ? ' editor' : '')
        }
      >
        <h5 className="sec-title">
          {!this.props.disabled && 'CHANGE '}LOCATION
        </h5>
        <form onSubmit={this.onSubmit}>
          <this.locationMap
            googleMapURL={MAP_URL}
            loadingElement={<div className="location-control-map-loading" />}
            containerElement={
              <div
                className="location-control-map-container"
                data-changed={this.coordsChanged}
              />
            }
            mapElement={<div className="location-control-map" />}
            markers={this.state.marker}
            onMapClick={this.onMapClick}
            draggable={!this.props.disabled}
          />
          {!this.props.disabled && (
            <FormGroup
              validationState={
                !this.state.location
                  ? 'warning'
                  : this.locationChanged ? 'success' : null
              }
            >
              <ControlLabel className="sec-title">
                Custom Location Name
              </ControlLabel>
              <FormControl
                type="text"
                defaultValue={this.props.screen.location}
                onChange={this.onLocationChange}
              />
              <FormControl.Feedback />
            </FormGroup>
          )}
          {!this.props.disabled && (
            <FormGroup bsClass="form-group submit-group">
              <div className="status-box">{this.state.status}</div>
              <Button
                type="submit"
                bsStyle="primary"
                disabled={!this.canSubmit}
              >
                SAVE
              </Button>
              &nbsp;
              <Button
                type="reset"
                bsStyle="danger"
                disabled={!this.canSubmit}
                onClick={this.onReset}
              >
                RESET
              </Button>
            </FormGroup>
          )}
        </form>
      </div>
    )
  }
}

export default LocationControl
