import * as React from 'react'
import {Tab, Tabs} from 'react-bootstrap'

import BaseAdminTab from './BaseAdminTab'
import IndexTab from './playlists/IndexTab'
import DeleteTab from './playlists/DeleteTab'

class PlaylistsTab extends BaseAdminTab {
  getContents = () => {
    return (
      <Tabs id="admin-panel-playlists-tabs">
        <Tab eventKey={1} title="Info">
          <IndexTab />
        </Tab>
        <Tab eventKey={4} title="Delete">
          <DeleteTab />
        </Tab>
      </Tabs>
    )
  }
}

export default PlaylistsTab
