import * as React from 'react'
import {computed} from 'mobx'
import {inject, observer} from 'mobx-react'

import {PlaylistOption} from 'globals'
import Playlist from 'stores/Playlist'
import Asset from 'stores/Asset'
import {AppStateStore} from 'stores/appStateStore'
import ContentCard from './ContentCard'

interface Props {
  title: string
  customClass: string
  alwaysExpand?: boolean
  playlists: Playlist[]
  assets: Asset[]
  appStateStore?: AppStateStore
}

interface State {
  numLoadedCards: number
}

@inject('appStateStore')
@observer
class ContentCardSection extends React.Component<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      numLoadedCards: this.calculateMinCards()
    }
  }

  componentWillMount() {
    this.props.appStateStore.closeSections()
  }

  componentDidMount() {
    window.addEventListener('resize', this.windowResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowResize)
    window.removeEventListener('scroll', this.onScroll, false)
  }

  @computed
  get isExpanded() {
    return (
      this.props.title === this.props.appStateStore.openSection ||
      this.props.alwaysExpand
    )
  }

  expandSection = () => {
    $(() => {
      $('html, body').animate({scrollTop: 0}, 'fast')
      return false
    })

    if (this.isExpanded) {
      window.removeEventListener('scroll', this.onScroll, false)
      this.props.appStateStore.openSection = null
    } else {
      window.addEventListener('scroll', this.onScroll, false)
      this.props.appStateStore.openSection = this.props.title
      const newMinCards = this.calculateMinCards()
      if (this.state.numLoadedCards < newMinCards) {
        this.setState({
          numLoadedCards: newMinCards
        })
      }
    }
  }

  calculateMinCards = () => {
    const width = window.innerWidth
    const height = window.innerHeight
    const rows = (height - 160) / 223

    if (this.isExpanded) {
      if (width > 767) {
        const columns = (width - 75) / 230
        return Math.ceil((rows + 1) * columns)
      } else {
        const columns = width / 230
        return Math.ceil((rows + 1) * columns)
      }
    }

    return width > 767
      ? Math.ceil((width - 75) / 220) + 1
      : Math.ceil(width / 220) + 1
  }

  windowResize = () => {
    const newCards: number = this.calculateMinCards()

    if (newCards > this.state.numLoadedCards) {
      this.setState({
        numLoadedCards: newCards
      })
    }
  }

  onScroll = () => {
    if (
      this.props.assets &&
      window.innerHeight + window.pageYOffset >=
        document.body.offsetHeight - 500
    ) {
      this.setState({
        numLoadedCards: this.state.numLoadedCards + 15
      })
    }
  }

  render() {
    let className = 'content-card-section' + ` ${this.props.customClass}`

    if (this.isExpanded) {
      className += ' expand'
    } else if (this.props.appStateStore.openSection !== null) {
      className += ' hide'
    }

    const btnText = this.isExpanded ? 'view less' : 'view more...'

    const emptyCards = []
    for (let i = 0; i < 10; i++) {
      emptyCards.push(<div className="content-card-container" key={i} />)
    }
    return (
      <div className={className}>
        <h2 className="page-section-title">{this.props.title}</h2>
        {this.isExpanded && !this.props.alwaysExpand && (
          <button
            className="section-resize-btn-top"
            onClick={this.expandSection}
          >
            {btnText}
          </button>
        )}
        <div className="content-card-display">
          {this.props.assets.slice(0, this.state.numLoadedCards).map(asset => {
            const playlistOptions: PlaylistOption[] = []
            this.props.playlists.map(playlist => {
              const playlistOption: PlaylistOption = {
                uniqueID: playlist.id,
                title: playlist.title,
                hasAsset:
                  playlist.assetIDs &&
                  playlist.assetIDs.indexOf(asset.id) !== -1
              }
              playlistOptions.push(playlistOption)
            })
            return (
              <ContentCard
                asset={asset}
                playlists={playlistOptions}
                key={asset.id}
              />
            )
          })}
          {emptyCards}
        </div>
        {!this.props.alwaysExpand && (
          <button
            className="section-resize-btn-bottom"
            onClick={this.expandSection}
          >
            {btnText}
          </button>
        )}
      </div>
    )
  }
}

export default ContentCardSection
