import * as React from 'react'
import {IObservableArray, observable, ObservableMap} from 'mobx'
import {observer} from 'mobx-react'
import Select from 'react-select'
import {Props as SelectProps} from 'react-select/lib/Select'

import Showtime from 'stores/Showtime'
import {PLAYLIST_NONE, PseudoPlaylist} from './index'

// define new Select
const PseudoPlaylistSelect = Select as new (
  props: SelectProps<PseudoPlaylist>
) => Select<PseudoPlaylist>

interface Props {
  selectKey: number
  playlists: IObservableArray<PseudoPlaylist>
  playlistOptions: PseudoPlaylist[]
  showtimes: ObservableMap<ObservableMap<Showtime>>
  screenID: string
  disabled: boolean
}

@observer
class SchedulePlaylistSelect extends React.Component<Props> {
  get isShown() {
    return this.props.selectKey < this.props.playlists.length
  }

  get value() {
    if (!this.isShown) {
      return PLAYLIST_NONE
    }

    return this.props.playlists[this.props.selectKey] || PLAYLIST_NONE
  }

  onChange = (newValue: PseudoPlaylist) => {
    if (!this.isShown) {
      return
    }

    const prevPL = this.props.playlists[this.props.selectKey]
    if (prevPL) {
      if (newValue && prevPL.id === newValue.id) {
        return // selection didn't change
      } else {
        this.props.showtimes.delete(prevPL.id)
      }
    }

    if (newValue === null) {
      // clear button pressed; remove this playlist
      this.props.playlists.splice(this.props.selectKey, 1)
    } else {
      if (newValue === PLAYLIST_NONE) {
        newValue = null
      } else {
        // create new showtime, spanning full width
        const newShowtime = Showtime.createDefaultFull(
          this.props.screenID,
          newValue.id
        )
        const newMap = {}
        newMap[newShowtime.id] = newShowtime

        this.props.showtimes.set(newValue.id, observable.map(newMap))
      }

      this.props.playlists[this.props.selectKey] = newValue
    }
  }

  render() {
    return (
      <div
        className="sc-pl-wrapper"
        style={{display: this.isShown ? 'inline-block' : 'none'}}
      >
        <PseudoPlaylistSelect
          isMulti={false}
          isSearchable={false}
          isDisabled={this.props.disabled}
          className={`sc-playlist-select sc-playlist-select-${
            this.props.selectKey
          }`}
          classNamePrefix="react-select"
          isClearable={true}
          getOptionValue={opt => opt.id}
          getOptionLabel={opt => opt.title}
          options={this.props.playlistOptions}
          value={this.value}
          onChange={this.onChange}
        />
      </div>
    )
  }
}

export default SchedulePlaylistSelect
