import * as React from 'react'
import {Button, Modal} from 'react-bootstrap'
import {inject, observer} from 'mobx-react'

import PlaylistSelectBox from '../PlaylistSelectBox'
import Playlist from 'stores/Playlist'
import Asset from 'stores/Asset'
import {AppStateStore} from 'stores/appStateStore'

const Close = require('images/elements/close-x.svg')
const FavoriteIcon = require('images/elements/favorites-icon.svg')

interface Props {
  showModal: boolean
  hidePlaylistPlacerModal: () => void
  appStateStore?: AppStateStore
}

const PlaylistPlacerModal: React.StatelessComponent<Props> = inject(
  'appStateStore'
)(
  observer(props => {
    const addMultiSelectedToPlaylist = (playlist: Playlist) => {
      props.appStateStore.multiSelectedAssets.forEach(playlist.addAsset)

      props.appStateStore.toggleMultiSelect()

      props.hidePlaylistPlacerModal()
    }

    return (
      <Modal show={props.showModal} onHide={props.hidePlaylistPlacerModal}>
        <Modal.Header>
          <Close
            type="button"
            className="close close-x"
            onClick={props.hidePlaylistPlacerModal}
          />
          <h4 className="modal-title content-multi-select">
            Add selected assets to
          </h4>
          <PlaylistSelectBox
            onSelect={playlist =>
              (props.appStateStore.selectedPlaylist = playlist)
            }
          />
        </Modal.Header>
        <Modal.Body className="content-multi-select">
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>preview</th>
                  <th>title</th>
                  <th>owner</th>
                  <th>type</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {props.appStateStore.multiSelectedAssets.map((asset, i) => {
                  const previewCSS = {
                    backgroundImage: 'url(' + asset.tpath + ')'
                  }

                  let favorite = null

                  if (asset.isFavorite) {
                    favorite = <FavoriteIcon className="favorite-icon" />
                  }

                  let dangerClass = ''

                  if (
                    props.appStateStore.selectedPlaylist != null &&
                    props.appStateStore.selectedPlaylist.assetIDs.indexOf(
                      asset.id
                    ) !== -1
                  ) {
                    dangerClass = ' danger'
                  }

                  return (
                    <tr className={'asset-row' + dangerClass} key={asset.id}>
                      <td>
                        <div
                          className="multi-select-preview"
                          style={previewCSS}
                        >
                          {favorite}
                        </div>
                      </td>
                      <td>{asset.title}</td>
                      <td>{asset.owner.name}</td>
                      <td>{asset.assetType}</td>
                      <td>
                        <Close
                          className="remove-multi-asset"
                          onClick={() =>
                            props.appStateStore.multiSelectAsset(asset)
                          }
                        />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>

            {props.duplicateAssetCount > 0 && (
              <p className="duplicate-message">
                {props.duplicateAssetNameString}
              </p>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="default" onClick={props.hidePlaylistPlacerModal}>
            Cancel
          </Button>
          <Button
            bsStyle="primary"
            disabled={!props.appStateStore.selectedPlaylist}
            className={
              props.duplicateAssetCount > 0 ||
              !props.appStateStore.selectedPlaylist
                ? 'disabled'
                : ''
            }
            onClick={
              props.duplicateAssetCount > 0
                ? null
                : () =>
                    addMultiSelectedToPlaylist(
                      props.appStateStore.selectedPlaylist
                    )
            }
          >
            Add Assets
          </Button>
        </Modal.Footer>
      </Modal>
    )
  })
)

export default PlaylistPlacerModal
