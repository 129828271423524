import * as React from 'react'
import {inject, observer} from 'mobx-react'
import {IObservableArray} from 'mobx'
import {Button, Modal, Table} from 'react-bootstrap'

import {PermissionLevel} from 'globals'
import {UserStore} from 'stores/userStore'
import User from 'stores/User'
import Screen from 'stores/Screen'
import Playlist from 'stores/Playlist'
import UserRow from './UserRow'
import UserSearchBox from './UserSearchBox'

const Close = require('images/elements/close-x.svg')

interface Props {
  showModal: boolean
  hideModal: (target: Playlist | Screen) => void
  target: Playlist | Screen
  sortedSharedWith: IObservableArray<User>
  canShareOwner: boolean
  disabled?: boolean
  userStore?: UserStore
}

export const EVENTKEY_OWNER = 3

const ShareModal = inject('userStore')(
  observer((props: Props) => {
    const ownerValue = props.target.userPermissions.isCreatorType
      ? 'creator'
      : 'owner'

    const permissionLevels = [
      'viewer',
      'editor',
      ownerValue
    ] as PermissionLevel[]

    const myPermission = props.target.userPermissions.getPermission(
      props.userStore.currentUser.id
    )

    // Creates a (likely smaller) array of permissions user can grant to others
    const availablePermissions = permissionLevels.slice(
      0,
      permissionLevels.indexOf(myPermission) + 1
    )

    // Removes the owner permission in case the entity isn't owner-transferrable
    if (!props.canShareOwner && myPermission === ownerValue) {
      availablePermissions.splice(availablePermissions.indexOf(myPermission), 1)
    }

    const onPermissionSelect = (permission: PermissionLevel, user: User) => {
      if (permission === ownerValue) {
        const message =
          `This will transfer ownership to ${user.name},` +
          ' and you will lose ownership and become an editor. Are you sure you' +
          ' want to do this?'

        if (!window.confirm(message)) {
          return // exit if user doesn't confirm
        }
      }

      props.target.shareTo(user.id, permission)
    }

    const removeUser = (user: User) => {
      if (!props.target.unshareTo) {
        throw new Error('Share Modal target is not a Screen or Playlist')
      }

      return props.target.unshareTo(user.id)
    }

    const addUsers = (users: User[], permLevel: PermissionLevel) =>
      Promise.all(users.map(user => props.target.shareTo(user.id, permLevel)))

    return (
      <Modal
        className="share-modal"
        show={props.showModal}
        onHide={() => props.hideModal(props.target)}
      >
        <Modal.Header>
          <Close
            type="button"
            className="close close-x"
            onClick={() => props.hideModal(props.target)}
          />
          <h4 className="modal-title share-modal">
            Share "{props.target.title}"
          </h4>
        </Modal.Header>
        <Modal.Body className="content-multi-select">
          <p className="body-title">shared with...</p>
          <Table striped>
            <tbody>
              {props.sortedSharedWith.map(user => {
                const permission = props.target.userPermissions.getPermission(
                  user.id
                )
                return (
                  <UserRow
                    key={user.id}
                    user={user}
                    permission={permission}
                    permissionLevels={availablePermissions}
                    onPermissionSelect={onPermissionSelect}
                    removeUser={removeUser}
                    disabled={
                      props.disabled ||
                      props.target.ownerID === user.id ||
                      props.userStore.currentUser.id === user.id ||
                      permissionLevels.indexOf(myPermission) <
                        permissionLevels.indexOf(permission)
                    }
                  />
                )
              })}
            </tbody>
          </Table>
          {!props.disabled && (
            <UserSearchBox
              target={props.target}
              sharedWith={props.sortedSharedWith}
              addUsers={addUsers}
              resultLimit={5}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            bsStyle="default"
            onClick={() => props.hideModal(props.target)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    )
  })
)

export default ShareModal
